/* eslint-disable import/no-extraneous-dependencies */
import React, { Component } from 'react';
import {
  Container, Image, Navbar, Carousel, Toast, Row, Col, Modal, Spinner, Nav,
} from 'react-bootstrap';
import { appurl, reacturl } from 'globals/utils/constants';
import UserInfo from 'models/UserInfo';

// import Aos from 'aos';
import Footer from 'globals/components/Footer';
import QueriesCall from 'globals/components/QueriesCall';
import {
  FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton,
} from 'react-share';
import CopyToClipboard from 'copy-to-clipboard';
import DocumentMeta from 'react-document-meta';

import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import GlobalService from 'services/GlobalService';
import { resturls } from 'globals/utils/apiurls';
import banner1 from '../../assets/images/mentor/banner-1.png';
import banner2 from '../../assets/images/mentor/banner-2.png';
import banner3 from '../../assets/images/mentor/banner-3.png';
import banner4 from '../../assets/images/mentor/banner-4.png';
import registerLeft from '../../assets/images/mentor/register_left.png';
import mentor1 from '../../assets/images/mentor/mentor-1.png';
import mentor2 from '../../assets/images/mentor/mentor-2.png';
import online1 from '../../assets/images/mentor/online-1.png';
import online2 from '../../assets/images/mentor/online-2.png';
import online3 from '../../assets/images/mentor/online-3.png';
import done from '../../assets/images/mentor/done.svg';
// import 'aos/dist/aos.css';
import './mentorHome.scss';

class MentorHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      toastShow: false,
      modalShow: false,
      isLoading: false,
      navBtns: '',
    };
    this.initialValues = {
      name: '',
      email: '',
      mobilenumber: '',
      qualification: '',
      workExperience: '',
      skill: '',
      customFile: '',
    };
  }

  // componentDidMount =() => {
  //   Aos.init({
  //     duration: 1100,
  //   });
  // }

  componentDidMount = () => {
    setTimeout(() => {
      const navBtns = (UserInfo.isAuth() === true) ? (
        <Nav.Link className="btn btn_primary" href={`${appurl}/`}>Dashboard</Nav.Link>
      )
        : (
          <>
            <Nav.Link className="btn" href={appurl}>Login</Nav.Link>
            {/* <Nav.Link className="btn btn_primary" href={`${appurl}/loginSSO/signUp/`}>
              Register
            </Nav.Link> */}
          </>
        );

      this.setState({ navBtns });
    }, 500);
  }

  handleLinkBtnClick = (id) => {
    document.getElementById(id).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  hanldeActiveTab = (activeTab) => {
    this.setState({
      activeTab,
    });
  }

  handleCopytext = () => {
    CopyToClipboard(window.location.href);
    this.setState({ toastShow: true });
  }

  toastClose = () => {
    this.setState({ toastShow: false });
  }

  handleModalShow = () => {
    this.setState({
      modalShow: false,
    });
  }

  renderNavBar = () => {
    const { navBtns } = this.state;
    return (
      <>
        <div className="main-header">
          <Container>
            <Navbar expand="lg">
              <Navbar.Brand href="/">
                <img src="/assets/images/eboxlogo.svg" alt="E-Box" />
              </Navbar.Brand>
              <Navbar.Toggle />
              <Navbar.Collapse>
                <div className="mobileHeader">
                  <Navbar.Brand href="/">
                    <img src="/assets/images/eboxlogo.svg" alt="E-Box" />
                  </Navbar.Brand>
                  <Navbar.Toggle className="closemenu">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <g transform="translate(0.214 0.214)">
                        <path
                          className="b"
                          d="M7.208,101.947l3.812-3.812.786-.786a.3.3,0,0,0,0-.42l-.841-.841a.3.3,0,0,0-.42,0l-4.6,4.6-4.6-4.6a.3.3,0,0,0-.42,0l-.841.841a.3.3,0,0,0,0,.42l4.6,4.6-4.6,4.6a.3.3,0,0,0,0,.42l.841.841a.3.3,0,0,0,.42,0l4.6-4.6,3.812,3.812.786.786a.3.3,0,0,0,.42,0l.841-.841a.3.3,0,0,0,0-.42Z"
                          transform="translate(5.84 -90.15)"
                        />
                      </g>
                    </svg>
                  </Navbar.Toggle>
                </div>
                <Nav className="ml-auto">
                  <Nav.Link href="/#program" onClick={this.sideMenuClose}>Why E-Box</Nav.Link>
                  <Nav.Link href="/#skill-course" onClick={this.sideMenuClose}>Courses</Nav.Link>
                  {/* <Nav.Link href="/assessments">Assessments</Nav.Link> */}

                  <Nav.Link href="/powered-campuses">Universities</Nav.Link>

                  <Nav.Link href="/contact">Contact Us</Nav.Link>
                </Nav>
                <Nav>
                  {navBtns}
                </Nav>

              </Navbar.Collapse>
            </Navbar>
          </Container>
        </div>
      </>
    );
  }

  renderCarouselSection = () => (
    <>
      <section className="carouselSection">
        <Container className="carouselContainer">
          <Carousel
            items={1}
            loop
            margin={10}
            autoPlay
            controls
          >
            <Carousel.Item>
              <div data-aos="fade-up" className="sectionquote">Do you want to be a part of Revolutionizing Indian Engineering Education?</div>
              <div data-aos="fade-up" className="section_centerquote">“Certify yourself and become an E-Box Mentor”</div>
              <div data-aos="slide-right" className="btnsection">
                <button onClick={() => this.handleLinkBtnClick('registration')} type="button" tag="registration" className="btn btn_primary">Register Now</button>
              </div>
              <Image className="carouselImage" data-aos="fade-right" src={banner1} alt="banner1" />
            </Carousel.Item>
            <Carousel.Item>
              <div data-aos="fade-up" className="sectionquote">
                Do you want to create an impact in the life of millions of Indian kids?
              </div>
              <div data-aos="fade-up" className="section_centerquote">
                “Certify yourself and become an E-Box Mentor”
              </div>
              <div data-aos="slide-right" className="btnsection">
                <button
                  onClick={() => this.handleLinkBtnClick('registration')}
                  type="button" tag="registration" className="btn btn_primary"
                >Register Now
                </button>
              </div>
              <Image className="carouselImage" data-aos="fade-right" src={banner2} alt="banner2" />
            </Carousel.Item>
            <Carousel.Item>
              <div data-aos="fade-up" className="sectionquote">
                Do you have a passion for teaching? Turn your passion into reality.
              </div>
              <div data-aos="fade-up" className="section_centerquote">
                “Certify yourself and become an E-Box Mentor”
              </div>
              <div data-aos="slide-right" className="btnsection">
                <button
                  onClick={() => this.handleLinkBtnClick('registration')}
                  type="button" tag="registration" className="btn btn_primary"
                >Register Now
                </button>
              </div>
              <Image className="carouselImage" src={banner3} alt="banner3" />
            </Carousel.Item>
            <Carousel.Item>
              <div data-aos="fade-up" className="sectionquote">
                Every great achiever is inspired by a great mentor. Do you want
                to inspire others to achieve great things in life?
              </div>
              <div data-aos="fade-up" className="section_centerquote">
                “Certify yourself and become an E-Box Mentor”
              </div>
              <div data-aos="slide-right" className="btnsection">
                <button
                  onClick={() => this.handleLinkBtnClick('registration')}
                  type="button" tag="registration" className="btn btn_primary"
                >Register Now
                </button>
              </div>
              <Image style={{ maxWidth: '45%' }} src={banner4} alt="banner3" />
            </Carousel.Item>
          </Carousel>
        </Container>
      </section>
    </>
  )

  fileChange = (e) => {
    const file = e.currentTarget.files[0];
    this.selectedFile = file;
    document.getElementById('fileLabel').innerHTML = file.name;
  }

  formSubmit = (fields, formkIns) => {
    const urlParams = new URLSearchParams(window.location.search);
    const formData = new FormData();
    console.table(fields);
    console.log(this.selectedFile, 'this.selectedFile');
    formData.append('name', fields.name);
    formData.append('email', fields.email);
    formData.append('mobilenumber', fields.mobilenumber);
    formData.append('registrationPurpose', 'Mentor Registration');
    formData.append('qualification', fields.qualification);
    formData.append('workExperience', fields.workExperience);
    formData.append('skill', fields.skill);
    formData.append('uploadfile', this.selectedFile);
    formData.append('utm_source', (urlParams.has('utm_source')) ? urlParams.get('utm_source') : '');
    formData.append('utm_campaign', (urlParams.has('utm_campaign')) ? urlParams.get('utm_campaign') : '');
    this.setState({
      isLoading: true,
    });
    GlobalService.generalSelect(
      (respdata) => {
        console.log(respdata);
        if (respdata) {
          formkIns.resetForm();
          this.initialValues = {
            name: '',
            email: '',
            mobilenumber: '',
            qualification: '',
            workExperience: '',
            skill: '',
            customFile: '',
          };
          this.setState({
            modalShow: true,
            isLoading: false,
          });
        }
      }, `${resturls.registerWebsiteUser}`, formData, 'POST', 'app',
    );
  };

  renderFormSection = () => {
    const { isLoading } = this.state;
    return (
      <>
        <section className="regeistersection commonsection">
          <div className="container">
            <div className="row">
              <div
                data-aos="fade-right" className="col-sm-12 col-md-6"
              >
                <div className="clr20" />
                <div className="clr20" />
                <Image className="registerImage" src={registerLeft} alt="registerLeft" />
              </div>
              <div
                id="registration" data-aos="fade-left" className="col-sm-12 col-md-5"
              >
                <div data-aos="fade-left" className="form-title">Registration</div>
                <Formik
                  initialValues={this.initialValues}
                  validationSchema={Yup.object().shape({
                    name: Yup.string()
                      .required('Name is required'),
                    email: Yup.string()
                      .email('Email is invalid')
                      .required('Email is required'),
                    mobilenumber: Yup.number()
                      .typeError('Invalid Mobile Number')
                      .required('Mobilenumber is required')
                      .test('mobileValidate', 'Invalid Mobile Number', (value) => {
                        if (value && value.toString().length === 10) {
                          return true;
                        }
                        return false;
                      }),
                    qualification: Yup.string()
                      .required('Qualification is required'),
                    workExperience: Yup.string()
                      .required('Work Experience is required'),
                    skill: Yup.string()
                      .required('Skill is required')
                      .test('skillValidate', 'Skill is required', (value) => {
                        if (value === 'null') {
                          return false;
                        }
                        return true;
                      }),
                    customFile: Yup.mixed().required('File is required'),

                  })}
                  onSubmit={this.formSubmit}
                  render={({ errors, touched, handleChange }) => (
                    <Form className="mentorForm">
                      <Row>
                        <Col sm={12}>
                          <div className="form-group">
                            <div className="label">Name *</div>
                            <Field name="name" type="text" className={`form-control${errors.name && touched.name ? ' is-invalid' : ''}`} placeholder="Name" autoComplete="off" />
                            <ErrorMessage name="name" component="div" className="invalid-feedback" />
                          </div>
                        </Col>
                        <Col sm={12}>
                          <div className="form-group">
                            <div className="label">Email Id *</div>
                            <Field
                              name="email"
                              type="text"
                              className={`form-control${(errors.email && touched.email) ? ' is-invalid' : ''}`} placeholder="Email" autoComplete="off"
                            />
                            <ErrorMessage name="email" component="div" className="invalid-feedback" />
                          </div>
                        </Col>
                        <Col sm={12}>
                          <div className="form-group">
                            <div className="label">Mobile Number *</div>
                            <Field name="mobilenumber" type="text" className={`form-control${errors.mobilenumber && touched.mobilenumber ? ' is-invalid' : ''}`} placeholder="Mobile" autoComplete="off" />
                            <ErrorMessage name="mobilenumber" component="div" className="invalid-feedback" />
                          </div>
                        </Col>
                        <Col sm={12}>
                          <div className="form-group">
                            <div className="label">Qualification *</div>
                            <Field name="qualification" type="text" className={`form-control${errors.qualification && touched.qualification ? ' is-invalid' : ''}`} placeholder="Qualification" autoComplete="off" />
                            <ErrorMessage name="qualification" component="div" className="invalid-feedback" />
                          </div>
                        </Col>
                        <Col sm={12}>
                          <div className="form-group">
                            <div className="label">Select a Skill *</div>
                            <Field name="skill" type="text" className={`form-control${errors.skill && touched.skill ? ' is-invalid' : ''}`} placeholder="Select a Skill" autoComplete="off" as="select">
                              <option value="null">Select a Skill</option>
                              <option value="c">C</option>
                              <option value="java">Java</option>
                              <option value="python">Python</option>
                            </Field>
                            <ErrorMessage name="skill" component="div" className="invalid-feedback" />
                          </div>
                        </Col>
                        <Col sm={12}>
                          <div className="form-group">
                            <div className="label">Work Experience *</div>
                            <Field name="workExperience" type="text" className={`form-control${errors.workExperience && touched.workExperience ? ' is-invalid' : ''}`} placeholder="Work Experience" autoComplete="off" />
                            <ErrorMessage name="workExperience" component="div" className="invalid-feedback" />
                          </div>
                        </Col>
                        <Col sm={12}>
                          <div className="form-group">
                            <div className="label">Resume *</div>
                            <div className="custom-file mb-3">
                              <Field
                                type="file"
                                onChange={(e) => { handleChange(e); this.fileChange(e); }}
                                className={`custom-file-input form-control${errors.customFile && touched.customFile ? ' is-invalid' : ''}`}
                                name="customFile"
                                autoComplete="off"
                                accept="application/pdf"
                              />
                              <ErrorMessage name="customFile" component="div" className="invalid-feedback" />
                              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                              <label id="fileLabel" className="custom-file-label" htmlFor="customFile">Upload Resume </label>
                            </div>
                          </div>
                        </Col>
                        <Col sm={12}>
                          <div className="mt20 text-center">
                            <button
                              type="submit" className="btn btn_primary"
                              disabled={isLoading}
                            >
                              {isLoading && (
                                <>
                                  <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                </>
                              )}

                              Submit
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  )}
                />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

  renderMentorSectionOneAdvanatages = () => (
    <>
      <section className="mentorsection_1 commonsection" id="advanatages">
        <div className="container">
          <div className="row">
            <div data-aos="fade-up" className="section_title col-sm-12">What do you gain by becoming a mentor?</div>
            <div className="clr20" />
            <div className="col-sm-12 col-md-7">
              <ul className="blinkcircle">
                <li data-aos="fade-up">Opportunities to Re-skill and Upskill yourself</li>
                <li data-aos="fade-up">Attractive remuneration on an hourly basis</li>
                <li data-aos="fade-up">Flexible Working hours</li>
                <li data-aos="fade-up">Learning and Working from a convenient place of your choice</li>
                <li data-aos="fade-up">Breakthrough technology for teaching</li>
                <li data-aos="fade-up">Ability to improve your Communication, Tutoring and Mentoring skills</li>
              </ul>
            </div>
            <div data-aos="fade-left" className="col-sm-12 col-md-5">
              <Image className="mentor-1" src={mentor1} alt="mentor1" />
            </div>
          </div>
        </div>
      </section>
    </>
  )

  renderMentorSectionOne = () => (
    <>
      <section className="mentorsection_1 commonsection">
        <div className="container">
          <div className="row">
            <div data-aos="fade-right" className="col-sm-12 col-md-5 col-md-pull-7">
              <Image className="mentor-2" src={mentor2} alt="mentor2" />
            </div>
            <div className="col-sm-12 col-md-7 col-md-push-5">
              <div data-aos="fade-up" className="section_title">Who is eligible to be a mentor?</div>
              <div className="clr20" />
              <ul className="blinkcircle">
                <li data-aos="fade-up">
                  Are you an Engineering Degree Holder
                  who has a passion for learning and teaching?
                </li>
                <li data-aos="fade-up">
                  Are you a faculty in an Engineering College
                  who wants to share your knowledge and expertise with a large number of learners?
                </li>
                <li data-aos="fade-up">
                  Are you a housewife with a professional background
                  having an interest towards teaching?
                </li>
                <li data-aos="fade-up">
                  Are you a freelance trainer who is looking at
                  skilling learners in the right way with the right use of technology?
                </li>
                <li data-aos="fade-up">
                  Are you a Corporate Professional
                  who wants to spend your spare time in a meaningful way by mentoring youngsters?
                </li>
                <li data-aos="fade-up">
                  Are you a Corporate Professional who wants to spend
                  your spare time in a meaningful way by mentoring youngsters?
                </li>
                <li data-aos="fade-up">
                  Are you a college alumni who is genuinely interested
                  in the development of your college and in mentoring your juniors?
                </li>
                <li data-aos="fade-up">
                  Are you a kind of person who judges your day by the
                  seeds that you sow rather than by the harvest you reap?
                  Do you want to volunteer to mentor rural kids free of cost?
                </li>
              </ul>
              <div className="clr10" />
              <div data-aos="fade-up" className="text-center">
                <button onClick={() => this.handleLinkBtnClick('registration')} type="button" tag="registration" className="btn btn_primary registerbtn">
                  Register Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )

  renderMentorSectionTwo = () => {
    const { activeTab } = this.state;
    return (
      <>
        <section className="mentorsection_2 commonsection">
          <div
            className="container"
          >
            <div className="row">
              <div className="col-sm-12">
                <div data-aos="fade-up" className="section_title text-center">What do you need to be a mentor?</div>
                <div className="clr20" />

                <p data-aos="fade-up" className="centerpara parastyle text-center">As far as hardware is concerned, a laptop/desktop with good processing power, minimum of 4 GB RAM, broadband Internet connectivity (atleast 4 mbps) is required along with a digital pen tablet or stylus. Power backup definitely is an add-on in case your area has electricity issues.</p>
                <div className="clr20" />

                <div data-aos="fade-up" className="section_title text-center">What are your responsibilities as a mentor?</div>
                <div className="clr20" />

                <p data-aos="fade-up" className="centerpara parastyle text-center">As a mentor, you can involve yourself in the following three activities with E-Box</p>
                <div className="clr20" />

                <ul className="text-center breadcrumb border0" id="breadcrumb_ul">
                  <li
                    data-aos="fade-up"
                    role="presentation" onKeyPress={() => this.hanldeActiveTab(1)}
                    onClick={() => this.hanldeActiveTab(1)} className={activeTab === 1 ? 'active subcrumb1' : 'subcrumb1'} tag="#link1"
                  >
                    Online Master Classes
                  </li>
                  <li
                    data-aos="fade-up"
                    role="presentation" onKeyPress={() => this.hanldeActiveTab(2)}
                    onClick={() => this.hanldeActiveTab(2)} className={activeTab === 2 ? 'active subcrumb2' : 'subcrumb2'} tag="#link2"
                  >
                    Online Tutoring Support
                  </li>
                  <li
                    data-aos="fade-up"
                    role="presentation" onKeyPress={() => this.hanldeActiveTab(3)}
                    onClick={() => this.hanldeActiveTab(3)} className={activeTab === 3 ? 'active subcrumb2' : 'subcrumb2'} tag="#link3"
                  >Online Mentor Support
                  </li>
                </ul>
                <div className="clr20" />
                <div className="clr20" />
                <div className="clr20" />
                {activeTab === 1 && (
                  <>
                    <article className="hide_responsibilities_article" id="link1">
                      <div className="row">
                        <div data-aos="fade-right" className="col-sm-12 col-md-6 col-md-pull-6 onlineimgdiv">
                          <Image className="onlineimg onlineimg1" src={online1} alt="online1" />
                        </div>
                        <div data-aos="fade-left" className="col-sm-12 col-md-6 col-md-push-6">
                          <div data-aos="fade-left" className="section_title">Online Master Class</div>
                          <div className="clr20" />

                          <p data-aos="fade-left" className="parastyle">
                            Online Master Classes are interactive sessions with a
                            group of around 100
                            students on a specific topic in a particular skill.
                            In these sessions, you will
                          </p>
                          <div className="clr20" />

                          <ul className="blinkcircle">
                            <li data-aos="fade-left">Brief the students on the topic.</li>
                            <li data-aos="fade-left">Provide a rich learning experience via multimedia content.</li>
                            <li data-aos="fade-left">Clear the doubts using chat with mentor.</li>
                            <li data-aos="fade-left">Give them live examples related to the topic.</li>
                            <li data-aos="fade-left">Provide them the practical exposure by solving
                              few problems related to the topic.
                            </li>
                          </ul>
                          <div className="clr" />
                        </div>
                      </div>
                    </article>
                  </>
                )}
                {activeTab === 2 && (
                  <>
                    <article className="hide_responsibilities_article" id="link2">
                      <div className="row">
                        <div data-aos="fade-right" className="col-sm-12 col-md-6">
                          <div className="section_title">Online Tutoring Support</div>
                          <div className="clr20" />
                          <ul className="blinkcircle">
                            <li data-aos="fade-right">
                              Online Tutoring Support Services are offered to
                              provide personalized support and attention to students
                              who are struggling to cope up in a particular course.
                              The batch size for Online Tutoring will be between 5 and 30.
                            </li>
                            <li data-aos="fade-right">
                              Tutoring Sessions will be complete interactive
                              sessions and the pace and content of the delivery will be
                              fine tuned based on the learners needs.
                            </li>
                            <li data-aos="fade-right">
                              Hands-On sessions where the learner will
                              be provided step by step guidance to solve the exercise
                              problems.
                            </li>
                          </ul>
                          <div className="clr" />
                        </div>
                        <div data-aos="fade-left" className="col-sm-12 col-md-6 onlineimgdiv">
                          <Image className="onlineimg onlineimg2" src={online2} alt="online2" />
                        </div>
                      </div>
                    </article>
                  </>
                )}
                {activeTab === 3 && (
                  <>
                    <article className="hide_responsibilities_article" id="link3">
                      <div className="row">
                        <div data-aos="fade-right" className="col-sm-12 col-md-6 col-md-pull-6 onlineimgdiv">
                          <Image className="onlineimg onlineimg3" src={online3} alt="online3" />
                        </div>
                        <div className="col-sm-12 col-md-6 col-md-push-6">
                          <div data-aos="fade-left" className="section_title">Online Mentor Support</div>
                          <div className="clr20" />

                          <ul className="blinkcircle">
                            <li data-aos="fade-left">
                              The students post their queries on the website,
                              and you have to provide answers to them after
                              analyzing their code submissions.
                            </li>
                            <li data-aos="fade-left">
                              You can choose which question you want to attempt,
                              and then you have to answer that in a limited time.
                            </li>
                            <li data-aos="fade-left">
                              You can solve as many questions as you want
                              in the scheduled time.
                            </li>
                          </ul>
                          <div className="clr" />
                        </div>

                      </div>
                    </article>
                  </>
                )}
                <div className="clr20" />

              </div>
            </div>
          </div>
        </section>
      </>
    );
  }

  renderCommonSection = () => (
    <>
      <section className="registerprocess commonsection rel">
        <div className="container rel" style={{ zIndex: 1 }}>
          <div className="row">
            <div className="col-sm-12">
              <div data-aos="fade-up" className="section_title text-center mb20">Registration and Enrolment Process</div>
              <div className="clr20 mb20" />
            </div>

            <div className="col-sm-12">
              <div data-aos="fade-right" className="leftprocess">
                <div className="numberslot">01</div>
                <div className="processdetail">
                  <h3>Apply as a mentor</h3>
                  <p>Fill in the Registration Form.
                    Choose Subjects / Courses to support.
                  </p>
                </div>
              </div>
              <div className="clr" />

              <div data-aos="fade-left" className="rightprocess">
                <div className="numberslot">02</div>
                <div className="processdetail">
                  <h3>Pre-Assessment</h3>
                  <p>Take up a Pre-Assessment.</p>
                </div>
              </div>
              <div className="clr" />

              <div data-aos="fade-right" className="leftprocess">
                <div className="numberslot">03</div>
                <div className="processdetail">
                  <h3>Training</h3>
                  <p>
                    You will be assigned to a set of learning courses.
                    You will be trained on the selected courses.
                  </p>
                </div>
              </div>
              <div className="clr" />

              <div data-aos="fade-left" className="rightprocess">
                <div className="numberslot">04</div>
                <div className="processdetail">
                  <h3>Initial Screening Process</h3>
                  <p>
                    Take up the certification exam to prove your expertise.
                    On successful completion of the certification exam,
                    you will be given a Certification code. Apply for mentorship
                    with the corresponding Certification code.
                  </p>
                </div>
              </div>
              <div className="clr" />

              <div data-aos="fade-right" className="leftprocess">
                <div className="numberslot">05</div>
                <div className="processdetail">
                  <h3>Interview</h3>
                  <p>Go through Interview process.</p>
                </div>
              </div>
              <div className="clr" />

              <div data-aos="fade-left" className="rightprocess">
                <div className="numberslot">06</div>
                <div className="processdetail">
                  <h3>Teaching Demo</h3>
                  <p>Pick up a topic of your choice and give a live demo session.</p>
                </div>
              </div>
              <div className="clr" />

              <div data-aos="fade-right" className="leftprocess">
                <div className="numberslot">07</div>
                <div className="processdetail">
                  <h3>Mentor On boarding</h3>
                  <p>
                    Once you are certified as a E-Box Mentor you can
                    opt for one of the 3 support services and you can
                    choose your preferred slots.
                    Based on our current needs and your preferences,
                    you will be assigned mentoring / tutoring slots.
                  </p>
                </div>
              </div>
            </div>
            <div className="clr" />
          </div>
        </div>
      </section>
    </>
  )

  renderLinkSection = () => (
    <>
      <section className="commonsection spreadsection">
        <div data-aos="fade-up" className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="spreadFlex">
                <div className="spreadText">
                  <div className="sectionTitle2">Spread the Word !</div>
                  <div className="sectionpara2">
                    Do you know someone who can become an E-Box Evangelist ?
                    Share the word with them
                    and help them earn and monetise their content.
                  </div>
                </div>
                <div className="shareIcons">
                  <div className="shareText">Share via</div>
                  <ul>
                    <li>
                      <WhatsappShareButton url="http://e-box.co.in/evangelist/">
                        <Image src="/assets/images/evangelist/icons/whatsapp-icon.svg" />
                      </WhatsappShareButton>
                    </li>
                    <li>
                      <FacebookShareButton url="https://www.facebook.com/sharer/sharer.php?u=http://e-box.co.in/evangelist/">
                        <Image src="/assets/images/evangelist/icons/fb.svg" />
                      </FacebookShareButton>
                    </li>
                    <li>
                      <TwitterShareButton url="http://e-box.co.in/evangelist">
                        <Image src="/assets/images/evangelist/icons/twitter.svg" />
                      </TwitterShareButton>
                    </li>
                    <li>
                      <LinkedinShareButton url="http://e-box.co.in/evangelist">
                        <Image src="/assets/images/evangelist/icons/linkedin.svg" />
                      </LinkedinShareButton>
                    </li>
                  </ul>
                </div>
                <div className="copyIcon">
                  <div className="orpart">Or</div>
                  <div className="sharepart">
                    <div className="shareText">Copy Link</div>
                    <ul>
                      <li aria-hidden title="Copy to clipboard" onClick={this.handleCopytext}>
                        <Image src="/assets/images/evangelist/icons/url.svg" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="clr20" />
          </div>
        </div>
      </section>
    </>
  )

  renderModal = () => {
    const { modalShow } = this.state;
    return (
      <>
        <Modal
          show={modalShow}
          onHide={() => this.handleModalShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="modalHeader" closeButton>
            <Modal.Title className="modalImageWrapper" id="contained-modal-title-vcenter">
              <Image src={done} />
            </Modal.Title>
          </Modal.Header>
          <div className="modal-body text-center">
            <h4>Great!</h4>
            <p>
              You have registered successfully.<br />
              Our business team will contact you soon.
            </p>
          </div>
        </Modal>
      </>
    );
  }

  render = () => {
    const { toastShow } = this.state;
    const metaData = {
      title: 'E-Box | Mentor',
      description: 'Mentor',
      canonical: `${reacturl}/mentor`,
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'Mentor, Partner',
        },
      },
    };
    return (
      <>
        <DocumentMeta
          title={metaData.title}
          description={metaData.description}
          canonical={metaData.canonical}
          meta={metaData.meta}
        >
          <main className="mentorPage">
            <div className="mentorScreen">
              {this.renderNavBar()}
              {this.renderCarouselSection()}
              {this.renderFormSection()}
              {this.renderMentorSectionOneAdvanatages()}
              {this.renderMentorSectionOne()}
              {this.renderMentorSectionTwo()}
              {this.renderCommonSection()}
              {this.renderLinkSection()}
              <div data-aos="fade-left" className="bgWhite">
                <QueriesCall />
              </div>
              <Footer data-aos="fade-down" />
            </div>
            <Toast onClose={this.toastClose} show={toastShow} delay={1000} autohide>
              <Toast.Header>
                <strong className="mr-auto">URL Copied to Clipboard</strong>
              </Toast.Header>
            </Toast>
            {this.renderModal()}
          </main>
        </DocumentMeta>
      </>
    );
  }
}

export default MentorHome;
