import React, { Component } from 'react';
import Slider from 'react-slick';
import { Card } from 'react-bootstrap';

class HighlightsProgram extends Component {
  render = () => {
    const settings = {
      center: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      adaptiveHeight: true,
      autoplay: true,
      autoplaySpeed: 10000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <>
        {window.innerWidth >= 768 ? (
          <section className="commonsection">
            <div className="container">
              <div className="row highlightneetrow">
                <div className="col-sm-12">
                  <div className="sectionTitleMedium text-center">
                    Why Technology Enabled Learning through E-Box ?
                  </div>
                </div>
              </div>
              <div className="row cardRow">
                {/* CARD-1 */}

                {/* CARD-2 */}
                <div className="col-sm-12 col-md-4 col-lg-4 border-card2">
                  <div className="highlightCard">
                    <div className="h_icon">
                      <div className="learnSprite highlight2" />
                    </div>
                    <div className="h_title">Live & Interactive Sessions</div>
                    <div className="h_para">
                      It’s not just videos that you would learn from but live
                      and interactive sessions from expert teachers
                    </div>
                  </div>
                </div>

                {/* CARD-4 */}
                <div className="col-sm-12 col-md-4 col-lg-4 border-card2">
                  <div className="highlightCard">
                    <div className="h_icon">
                      <div className="learnSprite highlight4" />
                    </div>
                    <div className="h_title">
                      100’s of Exercises to Practice
                    </div>
                    <div className="h_para">
                      You wouldn’t just learn through interactive sessions, but
                      practice what you’ve learnt through E-Box
                    </div>
                  </div>
                </div>

                {/* CARD-5 */}
                <div className="col-sm-12 col-md-4 col-lg-4 border-card2">
                  <div className="highlightCard">
                    <div className="h_icon">
                      <div className="learnSprite neet6" />
                    </div>
                    <div className="h_title">Assessment Patterns</div>
                    <div className="h_para">
                      AI blended Assessment Patterns which would enable the
                      students towards competitive exams.
                    </div>
                  </div>
                </div>

                {/* CARD-6 */}
                <div className="col-sm-12 col-md-4 col-lg-4 border-card2">
                  <div className="highlightCard">
                    <div className="h_icon">
                      <div className="learnSprite highlight6" />
                    </div>
                    <div className="h_title">
                      Instant Doubt Resolution by Experts
                    </div>
                    <div className="h_para">
                      Get your doubts resolved then and there by expert mentors
                      and teachers anytime, anywhere
                    </div>
                  </div>
                </div>

                {/* CARD-7 */}
                <div className="col-sm-12 col-md-4 col-lg-4 border-card2">
                  <div className="highlightCard">
                    <div className="h_icon">
                      <div className="learnSprite highlight7" />
                    </div>
                    <div className="h_title">
                      Access to Content Beyond the Course Duration
                    </div>
                    <div className="h_para">
                      Get unlimited access to your course content even after the
                      expiry of your course duration
                    </div>
                  </div>
                </div>

                {/* CARD-8 */}
                <div className="col-sm-12 col-md-4 col-lg-4 border-card2">
                  <div className="highlightCard">
                    <div className="h_icon">
                      <div className="learnSprite neet7" />
                    </div>
                    <div className="h_title">
                      24 x 7 Access <br /> to Content
                    </div>
                    <div className="h_para">
                      Get access to E-Box all day long for a hassle free and
                      flexible learning experience
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <>
            {/* <div className="container"> */}
            <div className="row highlightabcdsection">
              <div className="col-sm-12">
                <div className="sectionTitleMedium text-center">
                  Why Technology Enabled Learning through E-Box ?
                </div>
              </div>
            </div>
            {/* <div className="custom-arrows bottom-arrows"> */}
            <Slider {...settings} className="sliderabcdhighlights">
              <Card className="highlightCardmobileneethightlights">
                <Card.Body>
                  <div className="highlightCardneethighlights">
                    <div className="h_icon">
                      <div className="learnSprite highlight2" />
                    </div>
                    <div className="h_title">Live & Interactive Sessions</div>
                    <div className="h_para">
                        It’s not just videos that you would learn from but live
                        and interactive sessions from expert teachers
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <Card className="highlightCardmobileneethightlights">
                <Card.Body>
                  <div className="highlightCardneethighlights">
                    <div className="h_icon">
                      <div className="learnSprite highlight4" />
                    </div>
                    <div className="h_title">
                        100’s of Exercises to Practice
                    </div>
                    <div className="h_para">
                        You wouldn’t just learn through interactive sessions,
                        but practice what you’ve learnt through E-Box
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <Card className="highlightCardmobileneethightlights">
                <Card.Body>
                  <div className="highlightCardneethighlights">
                    <div className="h_icon">
                      <div className="learnSprite neet6" />
                    </div>
                    <div className="h_title">Assessment Patterns</div>
                    <div className="h_para">
                        AI blended Assessment Patterns which would enable the
                        students towards competitive exams.
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <Card className="highlightCardmobileneethightlights">
                <Card.Body>
                  <div className="highlightCardneethighlights">
                    <div className="h_icon">
                      <div className="learnSprite highlight6" />
                    </div>
                    <div className="h_title">
                        Instant Doubt Resolution by Experts
                    </div>
                    <div className="h_para">
                        Get your doubts resolved then and there by expert
                        mentors and teachers anytime, anywhere
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <Card className="highlightCardmobileneethightlights">
                <Card.Body>
                  <div className="highlightCardneethighlights">
                    <div className="h_icon">
                      <div className="learnSprite highlight7" />
                    </div>
                    <div className="h_title">
                        Access to Content Beyond the Course Duration
                    </div>
                    <div className="h_para">
                        Get unlimited access to your course content even after
                        the expiry of your course duration
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <Card className="highlightCardmobileneethightlights">
                <Card.Body>
                  <div className="highlightCardneethighlights">
                    <div className="h_icon">
                      <div className="learnSprite neet7" />
                    </div>
                    <div className="h_title">
                        24 x 7 Access <br /> to Content
                    </div>
                    <div className="h_para">
                        Get access to E-Box all day long for a hassle free and
                        flexible learning experience
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Slider>
          </>
        )}
      </>
    );
  };
}
export default HighlightsProgram;
