import React, { Component } from 'react';

import { Image } from 'react-bootstrap';

class AboutAssessments extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-sm-15">
              <div
                className="sectionTitleMedium text-center"
              >
                                All kinds of Academic Assessments can be conducted using E-BOX.
              </div>

              <div className="row" id="skillOffer">
                <div className="col-sm-12 col-md-4 col-lg-2">

                  <div className="programCard skillCard" style={{ height: '200px' }}>
                    <center>
                      <div className="programImage" style={{ height: '90px' }}>
                        <Image alt="Skill-1" src="/assets/images/Assessments/assess.png" style={{ height: '90px', width: '80px', marginTop: '10px' }} />
                      </div>
                    </center>
                    <div className="pgmSet">
                      <div className="pgmTitle">Internal Assessments</div>
                    </div>
                  </div>

                </div>

                <div className="col-sm-12 col-md-4 col-lg-2">

                  <div className="programCard skillCard" style={{ height: '200px' }}>
                    <center>
                      <div className="programImage" style={{ height: '90px' }}>
                        <Image alt="Skill-1" src="/assets/images/Assessments/lab.png" style={{ height: '90px', width: '80px', marginTop: '10px' }} />
                      </div>
                    </center>
                    <div className="pgmSet">
                      <div className="pgmTitle">Lab Practical Examinations</div>
                    </div>
                  </div>

                </div>

                <div className="col-sm-12 col-md-4 col-lg-2">

                  <div className="programCard skillCard" style={{ height: '200px' }}>
                    <center>
                      <div className="programImage" style={{ height: '90px' }}>
                        <Image alt="Skill-1" src="/assets/images/Assessments/sem.png" style={{ height: '90px', width: '80px', marginTop: '10px' }} />
                      </div>
                    </center>
                    <div className="pgmSet">
                      <div className="pgmTitle">Semester Examinations</div>
                    </div>
                  </div>

                </div>

                <div className="col-sm-12 col-md-4 col-lg-2">

                  <div className="programCard skillCard" style={{ height: '200px' }}>
                    <center>
                      <div className="programImage" style={{ height: '90px' }}>
                        <Image alt="Skill-1" src="/assets/images/Assessments/labassess.png" style={{ height: '90px', width: '80px', marginTop: '10px' }} />
                      </div>
                    </center>
                    <div className="pgmSet">
                      <div className="pgmTitle">Continuous lab practicals</div>
                    </div>
                  </div>

                </div>

                <div className="col-sm-12 col-md-4 col-lg-2">

                  <div className="programCard skillCard" style={{ height: '200px' }}>
                    <center>
                      <div className="programImage" style={{ height: '90px' }}>
                        <Image alt="Skill-1" src="/assets/images/Assessments/training.png" style={{ height: '90px', width: '80px', marginTop: '10px' }} />
                      </div>
                    </center>
                    <div className="pgmSet">
                      <div className="pgmTitle">Placement Training Assessments</div>
                    </div>
                  </div>

                </div>

                <div className="col-sm-12 col-md-4 col-lg-2">

                  <div className="programCard skillCard" style={{ height: '200px' }}>
                    <center>
                      <div className="programImage" style={{ height: '90px' }}>
                        <Image alt="Skill-1" src="/assets/images/Assessments/placement.png" style={{ height: '90px', width: '80px', marginTop: '10px' }} />
                      </div>
                    </center>
                    <div className="pgmSet">
                      <div className="pgmTitle">Entrance Examinations</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AboutAssessments;
