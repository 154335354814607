import React, { Component } from 'react';

class AbcdHighlightProgramCard extends Component {
  render = () => {
    const { element } = this.props;
    const cardClass = window.innerWidth <= 991 ? 'cardStyle' : '';
    return (
      <div className={`highlightCard ${cardClass}`}>
        <div className="h_icon">
          <div className={`learnSprite highlight${element.id}`} />
        </div>
        <div className="h_title">{element.title}</div>
        <div className="h_para">{element.desc}</div>
      </div>
    );
  }
}
export default AbcdHighlightProgramCard;
