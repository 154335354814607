import React, { Component } from 'react';
import Slider from 'react-slick';
import programs from './abcdHighlightProgram.json';
import AbcdHighlightProgramCard from './AbcdHighlightProgramCard';

class HighlightsProgramabcd extends Component {
  render = () => {
    const settings = {
      className: '',
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      adaptiveHeight: true,
      autoplay: true,
      autoplaySpeed: 10000,
    };
    return (
      <>
        {window.innerWidth >= 991 ? (
          <section className="commonsection">
            <div className="container">
              <div className="row highlightabcdsection">
                <div className="col-sm-12">
                  <div className="sectionTitleMedium text-center">
                      Why Technology Enabled Learning through E-Box ?
                  </div>
                </div>
              </div>
              <div className="row cardRow">
                {programs.map((element) => (
                  <div
                    className="col-sm-12 col-md-4 col-lg-3 border-card"
                    key={element.id}
                  >
                    <AbcdHighlightProgramCard element={element} />
                  </div>
                ))}
              </div>
            </div>
          </section>
        ) : (
          <>
            <div className="row highlightabcdsection bgWhite">
              <div className="col-sm-12">
                <div className="sectionTitleMedium text-center abcdpage">
                      Why Technology Enabled Learning through E-Box ?
                </div>
              </div>
            </div>
            <Slider {...settings} className="sliderabcdhighlights bgWhite">
              {programs.map((element) => (
                <AbcdHighlightProgramCard
                  key={element.id}
                  element={element}
                />
              ))}
            </Slider>
          </>
        )}
      </>
    );
  };
}
export default HighlightsProgramabcd;
