import React, { Component } from 'react';
import { Card } from 'react-bootstrap';

class GetPlacementBanner extends Component {
  enrollnow = () => {
    const enrollment = document.querySelector('#enrollPacks');
    window.scrollTo({
      top: enrollment.scrollIntoView(),
      behavior: 'smooth',
    });
  }

  render = () => (
    <>
      {window.innerWidth >= 991 ? (
        <section className="pgmlistsection commonsection bgWhite">
          <div className="container">
            <div className="row">
              <div className="col-md-12 bannerText col-lg-5">
                <div className="sectionTitle">Company Specific Practice Assessments</div>
                <div className="clr20" />
                <p>Stay ahead of Competition in the Job Hunt. Take up our Company Specific Mock Assessments and bag the right job</p>
                <div className="clr20" />
                <div aria-hidden="true" className="btn btn_primary" onClick={this.enrollnow}>View Packs</div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="pgmlistsection commonsection bgWhite">
          <div className="container">
            <div className="row">
              <div className="col-md-12 bannerText col-lg-5">
                <Card className="cardplacement">
                  <Card.Body>
                    <div className="sectionTitlemobile">
                    Company Specific Practice Assessments
                    </div>
                    <div className="sectionparamobile">
                    Stay ahead of Competition in the Job Hunt. Take up our Company Specific Mock Assessments and bag the right job
                    </div>
                    <div className="button_load">
                      <a href="tel:91 90034 69198" className="btn btn_primary call">
                        <span className="spiriteicon phonecallicon" />
                        9003469198
                      </a>
                      <div
                        aria-hidden="true"
                        className="btn btn_primary courseshow"
                        onClick={this.enrollnow}
                      >
                        View Packs
                      </div>
                    </div>
                  </Card.Body>
                </Card>
                <div className="height50" />
              </div>
              <div className="col-md-12 col-lg-7" id="requestForm">
                {/* <EngineeringForm /> */}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default GetPlacementBanner;
