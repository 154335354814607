import React, { Component } from 'react';
import { Image } from 'react-bootstrap';

class AssessmentTypes extends Component {
  render() {
    return (
      <>
        <main className="mentorPage">
          <div className="mentorScreen">
            <section className="registerprocess commonsection rel">
              <div className="container rel" style={{ zIndex: 1 }}>
                <div className="row">
                  <div className="col-sm-12">
                    <div data-aos="fade-up" className="section_title text-center mb20">Types of Questions Supported by E-BOX</div>
                    <div className="clr20 mb20" />
                  </div>

                  <div className="col-sm-12">
                    <div className="leftprocess">
                      <div className="numberslot">
                        <Image alt="Skill-1" src="/assets/images/Assessments/2.png" style={{ height: '72px', width: '64px' }} />
                      </div>
                      <div className="processdetail">
                        <h3>Multiple Choice Questions</h3>

                      </div>
                    </div>
                    <div className="leftprocess">
                      <div className="numberslot">
                        <Image alt="Skill-1" src="/assets/images/Assessments/8.png" style={{ height: '72px', width: '64px' }} />
                      </div>
                      <div className="processdetail">
                        <h3>Fill Ups</h3>

                      </div>
                    </div>

                    <div className="leftprocess">
                      <div className="numberslot">
                        <Image alt="Skill-1" src="/assets/images/Assessments/7.png" style={{ height: '72px', width: '64px' }} />
                      </div>
                      <div className="processdetail">
                        <h3>Match Type</h3>

                      </div>
                    </div>
                    <div className="leftprocess">
                      <div className="numberslot">
                        <Image alt="Skill-1" src="/assets/images/Assessments/4.png" style={{ height: '72px', width: '64px' }} />
                      </div>
                      <div className="processdetail">
                        <h3>Comprehension Based Questions</h3>

                      </div>
                    </div>

                    <div className="leftprocess">
                      <div className="numberslot">
                        <Image alt="Skill-1" src="/assets/images/Assessments/3.png" style={{ height: '80px', width: '80px' }} />
                      </div>
                      <div className="processdetail">
                        <h3>Dynamic Numerical Questions</h3>

                      </div>
                    </div>
                    <div className="leftprocess">
                      <div className="numberslot">
                        <Image alt="Skill-1" src="/assets/images/Assessments/10.png" style={{ height: '72px', width: '64px' }} />
                      </div>
                      <div className="processdetail">
                        <h3>Coding Exercises</h3>

                      </div>
                    </div>

                    <div className="leftprocess">
                      <div className="numberslot">
                        <Image alt="Skill-1" src="/assets/images/Assessments/1.png" style={{ height: '72px', width: '64px' }} />
                      </div>
                      <div className="processdetail">
                        <h3>Code Debugging Exercises</h3>

                      </div>
                    </div>
                    <div className="leftprocess">
                      <div className="numberslot">
                        <Image alt="Skill-1" src="/assets/images/Assessments/6.png" style={{ height: '72px', width: '64px' }} />
                      </div>
                      <div className="processdetail">
                        <h3>Query Writing Exercises</h3>

                      </div>
                    </div>

                    <div className="leftprocess">
                      <div className="numberslot">
                        <Image alt="Skill-1" src="/assets/images/Assessments/9.png" style={{ height: '72px', width: '64px' }} />
                      </div>
                      <div className="processdetail">
                        <h3>UI Design Exercises</h3>

                      </div>
                    </div>
                    <div className="leftprocess">
                      <div className="numberslot">
                        <Image alt="Skill-1" src="/assets/images/Assessments/6.png" style={{ height: '72px', width: '64px' }} />
                      </div>
                      <div className="processdetail">
                        <h3>Digital Design Exercises</h3>

                      </div>
                    </div>

                    <div className="leftprocess">
                      <div className="numberslot">
                        <Image alt="Skill-1" src="/assets/images/Assessments/12.png" style={{ height: '72px', width: '64px' }} />
                      </div>
                      <div className="processdetail">
                        <h3>Electrical Circuit Design</h3>

                      </div>
                    </div>
                    <div className="leftprocess">
                      <div className="numberslot">
                        <Image alt="Skill-1" src="/assets/images/Assessments/5.png" style={{ height: '72px', width: '64px' }} />
                      </div>
                      <div className="processdetail">
                        <h3>Electronics Circuit Design</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </>
    );
  }
}

export default AssessmentTypes;
