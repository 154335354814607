import EMICalculation from 'globals/components/EMICalculation';
import DefaultLoader from 'globals/components/loader/DefaultLoader';
import React, { Component } from 'react';

class AbcdTraining extends Component {
  selectBatch = (batchId) => {
    const { batchSelect } = this.props;
    batchSelect(batchId);
  };

  render = () => {
    const {
      beginnerData, advancedData, integratedData, dataLoading,
    } = this.props;

    let beginnerEMI = 0;
    let advancedEMI = 0;
    let integratedEMI = 0;

    if (!dataLoading) {
      beginnerEMI = beginnerData.batches.length !== 0 && beginnerData.batches.filter((begin) => begin.capacity === 10);
      beginnerEMI = beginnerData.batches.length !== 0 && beginnerEMI[0].batchCost;

      advancedEMI = advancedData.batches.length !== 0 && advancedData.batches.filter((adv) => adv
        .capacity === 10);
      advancedEMI = advancedData.batches.length !== 0 && advancedEMI[0].batchCost;

      integratedEMI = integratedData.batches.length !== 0 && integratedData.batches.filter((inter) => inter.capacity === 10);
      integratedEMI = integratedData.batches.length !== 0 && integratedEMI[0].batchCost;
    }

    return (
      <section className="commonsection placementsection">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="sectionTitleMedium text-center">
                Enroll your Child in E-Box ABCD Program today !
              </div>
              <div className="clr" />
              {
                !dataLoading
                  ? (
                    <div className="row">
                      <div className="col-sm-3 contentzoneimmediate">
                        <div className="abcdenroll">
                          <div className="coursename border-card1">
                            <div className="coursenamecontent">
                              <span>
                                <p>INTEGRATED PREPARATORY COURSE</p>
                              </span>
                              <p>
                                Introduction to Materials | Drawing Fundamentals |
                                Importance of Arts | Basics of Drawing and Sculpting
                              </p>
                            </div>
                          </div>
                          <div className="coursename border-card1">
                            <div className="coursenamecontent">
                              <span>
                                <p>HIGHER LEVEL DRAWING COURSE</p>
                              </span>
                              <p>
                                Introduction to Materials | Drawing | Rendering |
                                Composition | Perspectives | Illustration
                              </p>
                            </div>
                          </div>
                          <div className="coursename border-card1">
                            <div className="coursenamecontent">
                              <span>
                                <p>ADVANCED DRAWING COURSE</p>
                              </span>
                              <p>
                                Technical Drawing | Illustrations | Design
                                Concerts(Fashion Arc, Automobile, Product Design)
                              </p>
                            </div>
                          </div>
                          <div className="coursename border-card1">
                            <div className="coursenamecontent">
                              <span>
                                <p>INTEGRATED DRAWING COURSE</p>
                              </span>
                              <p>
                                Painting | Water Colours | Oil Based | Colour
                                Pencil/Crayon
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3 begineercontent">
                        <div className="begineerModule">
                          <div className="beginner">
                            <div className="pack-head">
                              <div className="label">Beginner</div>
                              <div className="flexPara">
                                <p><span>2</span><span>Months<br />Duration</span></p>
                              </div>
                            </div>
                          </div>
                          <div className="beginner1 border-card1">
                            <div className="tickRound" />
                          </div>
                          <div className="beginner1 border-card1">
                            <div className="tickRound" />
                          </div>
                          <div className="beginner1 border-card1" />
                          <div className="beginner1 border-card1" />
                          <div className="beginnerbutton border-card1">
                            {
                              beginnerData.batches.length !== 0
                                ? (
                                  <>
                                    <p><span>{beginnerData.symbol} {beginnerEMI}</span></p>
                                    {
                                      beginnerData.currencyCode === 'INR'
                                        ? <div className="emiText text-center">EMI starts at {beginnerData.batches[0].symbol} {Math.round(EMICalculation(beginnerEMI) * 100) / 100}/mo</div>
                                        : ''
                                    }
                                  </>
                                )
                                : <div className="emiText batchEmiText1">Batches Coming Soon.</div>
                            }
                            <div className="btn-pack">
                              <div
                                aria-hidden
                                className="btn btn_primary"
                                onClick={() => this.selectBatch(122)}
                              >
                                <span>Choose your Batches</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3 advancedcontent">
                        <div className="begineerModule">
                          <div className="advancedcontent-shadow">
                            <div className="advanced">
                              <div className="pack-head">
                                <div className="label">Advanced</div>
                                <div className="flexPara">
                                  <p><span>4</span><span>Months<br />Duration</span></p>
                                </div>
                              </div>
                            </div>
                            <div className="advanced1 border-card1">
                              <div className="tickRound" />
                            </div>
                            <div className="advanced1 border-card1">
                              <div className="tickRound" />
                            </div>
                            <div className="advanced1 border-card1">
                              <div className="tickRound" />
                            </div>
                            <div className="advanced1 border-card1" />
                            <div className="advancedbutton border-card1">
                              {
                                advancedData.batches.length !== 0
                                  ? (
                                    <>
                                      <p><span>{advancedData.symbol} {advancedEMI}</span> </p>
                                      {
                                        advancedData.currencyCode === 'INR'
                                          ? <div className="emiText text-center">EMI starts at {advancedData.batches[0].symbol} {Math.round(EMICalculation(advancedEMI) * 100) / 100}/mo</div>
                                          : ''
                                      }
                                    </>
                                  )
                                  : <div className="emiText batchEmiText1">Batches Coming Soon.</div>
                              }
                              <div className="btn-pack">
                                <div
                                  aria-hidden
                                  className="btn btn_primary"
                                  onClick={() => this.selectBatch(123)}
                                >
                                  <span>Choose your Batches</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3 integratedcontent">
                        <div className="begineerModule">
                          <div className="integrated">
                            <div className="pack-head">
                              <div className="label">Integrated</div>
                              <div className="flexPara">
                                <p><span>5</span><span>Months<br />Duration</span></p>
                              </div>
                            </div>
                          </div>
                          <div className="integrated1 border-card1">
                            <div className="tickRound" />
                          </div>
                          <div className="integrated1 border-card1">
                            <div className="tickRound" />
                          </div>
                          <div className="integrated1 border-card1">
                            <div className="tickRound" />
                          </div>
                          <div className="integrated1 border-card1">
                            <div className="tickRound" />
                          </div>
                          <div className="integratedbutton border-card1">
                            {
                              integratedData.batches.length !== 0
                                ? (
                                  <>
                                    <p><span>{integratedData.symbol} {integratedEMI}</span> </p>
                                    {
                                      integratedData.currencyCode === 'INR'
                                        ? <div className="emiText text-center">EMI starts at {integratedData.batches[0].symbol} {Math.round(EMICalculation(integratedEMI) * 100) / 100}/mo</div>
                                        : ''
                                    }
                                  </>
                                )
                                : <div className="emiText batchEmiText1">Batches Coming Soon.</div>
                            }
                            <div className="btn-pack">
                              <div
                                aria-hidden
                                className="btn btn_primary"
                                onClick={() => this.selectBatch(124)}
                              >
                                <span>Choose your Batches</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                  : <DefaultLoader />
              }
            </div>
            <div className="clr" />
          </div>
        </div>
      </section>
    );
  }
}

export default AbcdTraining;
