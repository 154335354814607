const EMICalculation = (batchCost) => {
  const percentMonths = 12;
  const interest = 16;
  const ratePercent = interest / percentMonths / 100;

  const EMI = Math.round(batchCost * ratePercent * Math.pow((1 + ratePercent), 12) / (Math.pow((1 + ratePercent), 12) - 1));
  return EMI;
};

export default EMICalculation;
