import GlobalService from 'services/GlobalService';
import { resturls } from './apiurls';
import { getParamsValue } from './index';

/* eslint-disable react/jsx-props-no-spreading */
function VisitorTrack() {
  const markId = getParamsValue('mId');
  const prodId = getParamsValue('pId');
  if ((markId !== false) && (prodId !== false)) {
    GlobalService.generalSelect(
      (respdata) => { }, resturls.visitorTrackURL, { markId, prodId }, 'GET', 'app',
    );
  }
}

export default VisitorTrack;
