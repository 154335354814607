import React, { Component } from 'react';
import {
  Container, Image, Nav, Navbar,
} from 'react-bootstrap';
import './scss/Footer.scss';
import { Link } from 'react-router-dom';

class Footer extends Component {
  render = () => (
    <footer>
      <Container>
        <div className="footer-flex">
          <div className="footerLeft">
            <Navbar.Brand href="/">
              <Image src="/assets/images/white-logo.webp" alt="Ebox Logo" />
            </Navbar.Brand>
            <div className="moto-title">
              We Revolutionise Engineering & <br />Technology Learning
            </div>
          </div>
          <div className="footerRight">
            <Link to="/E-Box-Mechanical-Skilling-Programs-The-Future-of-India's-Industry-4.0-Journey-english" className="title">
              <div className="title">Connect with Us on</div>
            </Link>
            <ul>
              <li>
                <Nav.Link href="https://www.facebook.com/eBoxAmphisoft/" target="_blank" rel="noopener" title="Share on Twitter">
                  <Image src="/assets/images/footer/fb.svg" alt="Facebook" />
                </Nav.Link>
              </li>
              <li>
                <Nav.Link href="https://www.instagram.com/ebox.ai?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank" rel="noopener" title="Share on Twitter">
                  <Image src="/assets/images/footer/instagram.svg" alt="Instagram" />
                </Nav.Link>
              </li>

              <li>
                <Nav.Link href="https://twitter.com/AmphisoftL" target="_blank" rel="noopener" title="Share on Twitter">
                  <Image src="/assets/images/footer/twitter.svg" alt="Twitter" />
                </Nav.Link>
              </li>
              <li>
                <Nav.Link href="https://www.linkedin.com/company/amphisoft-technologies/" target="_blank" title="Share on linkedin" rel="noopener">
                  <Image src="/assets/images/footer/linkedin.svg" alt="Linkedin" />
                </Nav.Link>
              </li>
            </ul>
            <Link to="/termsandconditions" target="_blank" className="termsCondition">
              Terms and Conditions
            </Link>
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer;
