import React, { Component } from 'react';
import {
  Image,
} from 'react-bootstrap';
import registerLeft from '../../assets/images/mentor/register_left.png';

class AssessmentContests extends Component {
  render() {
    return (
      <main className="mentorPage">
        <div className="mentorScreen">

          <section className="regeistersection commonsection">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <div className="clr20" />
                  <div className="clr20" />
                  <Image className="registerImage" src={registerLeft} alt="registerLeft" />
                </div>

                <div className="col-sm-12 col-md-5">
                  <div className="form-title">Buzz Your Campus with These Contests</div>
                  <ul className="blinkcircle">
                    <li>Programming & Code Debugging Contests</li>
                    <li>Query Writing Contests</li>
                    <li>Console Based Application Development Contest</li>
                    <li>UI,DB,Digital Design Contests</li>
                    <li>Web Application Development Contest</li>
                    <li>UML Modeling Contest</li>
                    <li>Technical Quizzes</li>
                    <li>Learnathon & Hackathon</li>
                    <li>Assembly Language Programming Contest</li>
                    <li>Electonics & Electric Circuit Design Contest</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    );
  }
}

export default AssessmentContests;
