import React, { Component } from 'react';

class WhyEbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <>
        <div style={{ backgroundColor: 'white' }}>

          <section className="pgmsection">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="sectionTitleMedium text-center">
                                        Why E-Box ?
                  </div>
                  <div className="clr20" />
                  <div className="sectionTitleMedium">
                                        The drawbacks of the current Manual Assessment System.
                  </div>
                  <div className="row featureSecond">
                    <div className="col-sm-12 featureCard">
                      <div className="featureTop">
                        <div className="featureImgWrap">
                          <div className="sectionTitleMedium text-center" style={{ marginTop: '-5px' }}>
                                                        1
                          </div>
                        </div>
                        <p className="featureTopText" style={{ marginTop: '-20px' }}>Time Consuming</p>
                      </div>
                      <div className="featureText" style={{ marginLeft: '70px', marginTop: '-30px' }}>
                      Lot of Teacher’s time is Spent in Evaluating the Answer Sheets of Students.
                      </div>
                      <div className="clr20" />

                    </div>
                    <div className="col-sm-12 featureCard">
                      <div className="featureTop">
                        <div className="featureImgWrap">
                          <div className="sectionTitleMedium text-center" style={{ marginTop: '-5px' }}>
                                                        2
                          </div>
                        </div>
                        <p className="featureTopText" style={{ marginTop: '-20px' }}>Higher Cost</p>
                      </div>
                      <div className="featureText" style={{ marginLeft: '70px', marginTop: '-30px' }}>
                      Lot of Money is Spent in Stationeries ... Printing the Question Papers,
                      Providing them Answer Sheets ...
                      </div>
                      <div className="clr20" />

                    </div>
                    <div className="col-sm-12 featureCard">
                      <div className="featureTop">
                        <div className="featureImgWrap">
                          <div className="sectionTitleMedium text-center" style={{ marginTop: '-5px' }}>
                                                        3
                          </div>
                        </div>
                        <p className="featureTopText" style={{ marginTop: '-20px' }}>Lack of Standards in Question Setting and in Evaluation</p>
                      </div>
                      <div className="featureText" style={{ marginLeft: '70px', marginTop: '-30px' }}>
                        The Standard of the Question Paper depends on the Quality of the
                        Question Setter.
                      </div>
                      <div className="clr20" />

                    </div>
                    <div className="col-sm-12 featureCard">
                      <div className="featureTop">
                        <div className="featureImgWrap">
                          <div className="sectionTitleMedium text-center" style={{ marginTop: '-5px' }}>
                                                        4
                          </div>
                        </div>
                        <p className="featureTopText">The Evaluation Metrics followed by Different Teachers would be Different.</p>
                      </div>
                      <div className="clr20" />

                    </div>

                    <div className="col-sm-12 featureCard">
                      <div className="featureTop">
                        <div className="featureImgWrap">
                          <div className="sectionTitleMedium text-center" style={{ marginTop: '-5px' }}>
                                                        5
                          </div>
                        </div>
                        <p className="featureTopText">Delay in the Declaration of Results</p>
                      </div>

                    </div>
                  </div>
                  <div className="clr20" />

                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default WhyEbox;
