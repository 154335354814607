import React, { Component } from 'react';
import './scss/FeaturesLearning.scss';

class ProgramsOffered extends Component {
  render = () => (
    <>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div
                className="sectionTitleMedium text-center"
              >
              Why E-Box Powered Campuses are Different and Unique?
              </div>
              <div className="clr20" />
              <div className="row featureSecond mobileMargin">
                <div className="col-sm-4 featureCard">
                  <div className="featureTop">
                    <div className="featureImgWrap">
                      <img src="/assets/images/poweredCampuses/people.svg" className="featureImg" alt="people" />
                    </div>
                    <p className="featureTopText">People</p>
                  </div>
                  <div className="featureSideText">Global Advisory Board</div>
                  <div className="featureText">
                  High Profile Academicians, Renowned Researchers,
                  Industry Experts, Product Engineers, Skilling Assistants
                  </div>
                </div>
                <div className="col-sm-4 featureCard">
                  <div className="featureTop">
                    <div className="featureImgWrap">
                      <img src="/assets/images/poweredCampuses/content.svg" className="featureImg" alt="people" />
                    </div>
                    <p className="featureTopText">Content</p>
                  </div>
                  <div className="featureSideText">AI Driven Courses - First Time in the World</div>
                  <div className="featureText">
                  300+ Courses in All Disciplines of Engineering and Technology Learning.
                  Auto Evaluation and Automation Engines for all Domains of Engineering Studies
                  </div>
                </div>
                <div className="col-sm-4 featureCard">
                  <div className="featureTop">
                    <div className="featureImgWrap">
                      <img src="/assets/images/poweredCampuses/platform.svg" className="featureImg" alt="people" />
                    </div>
                    <p className="featureTopText">Platform</p>
                  </div>
                  <div className="featureSideText">AI Driven Active Learning Platform</div>
                  <div className="featureText">
                  We Own the Only Exclusive Learning & Skilling Platform for Engineering &
                  Technology Education
                  </div>
                </div>
              </div>
              <div className="row featureSecond">
                <div className="col-sm-6 featureCard">
                  <div className="featureTop">
                    <div className="featureImgWrap">
                      <img src="/assets/images/poweredCampuses/career.svg" className="featureImg" alt="people" />
                    </div>
                    <p className="featureTopText">Career</p>
                  </div>
                  <div className="featureSideText">Entrepreneurship, Higher Studies & Dream Offers</div>
                  <div className="featureText">
                  We Hail from a Product Company - We Know the Nuances of Building Products
                  and Creating Entrepreneurs. We Know the Process to Build Products at Rapid
                  Speed. We Are a Team of Highly Qualified Academicians and Professionals -
                  We know the Roadmaps for Genuine Higher Studies. 60% of High Package -
                  10Lakhs+ Freshers Jobs in India are Filled through E-Box Skilling.
                  </div>
                </div>
                <div className="col-sm-6 featureCard">
                  <div className="featureTop">
                    <div className="featureImgWrap">
                      <img src="/assets/images/poweredCampuses/process.svg" className="featureImg" alt="people" />
                    </div>
                    <p className="featureTopText">Process</p>
                  </div>
                  <div className="featureSideText">Modern Learning Methodologies & Blended Skilling Models</div>
                  <div className="featureText">
                  AI Driven, Emphasis on Math and Problem Solving, Practice Oriented
                  Learning, Skill Based Learning, Product Oriented Learning, Less Monologue
                  & More Dialogue Sessions, Game Oriented Learning, Social Learning Models,
                  Competitive Learning, Live Interactions with Industry Mentors,
                  Anytime-Anywhere Learning, Less Lectures More Tutorials & Practicals,
                  Intellectual Ecosystem
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="knowMoreBtn"><a href="/powered-campuses" className="btn btn_primary">Know More</a></div>
        </div>
      </section>
    </>
  )
}
export default ProgramsOffered;
