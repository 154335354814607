import React from 'react';
import { Link } from 'react-router-dom';
import './NotFound.scss';
import Header from '../Header';
import Footer from '../Footer';

function NotFound() {
  return (
    <>
      <Header />
      <section className="notfound-layout">
        <img src="/assets/images/page-not-found-404.svg" alt="notfound" className="image" />
        <h1>The page you were looking for does not exist.</h1>
        <p>You may have mistyped the address or the page may have been moved.</p>
        <div className="btn-area">
          <Link to="/contact" className="btn btn_primary_outer">Contact Us</Link>
          <Link to="/" className="btn btn_primary">Go to Home</Link>
        </div>
      </section>
      <Footer />
    </>
  );
}
export default NotFound;
