import React, { Component } from 'react';
import './scss/ProgramsOffered.scss';
import {
  Container,
} from 'react-bootstrap';

class NumberSection extends Component {
  render = () => (
    <section className="numberSection">
      <Container>
        <ul className="numberFlex">
          <li>
            <div className="label">120</div>
            <p>Courses</p>
          </li>
          <li>
            <div className="label">1M+</div>
            <p>Learners</p>
          </li>
          <li>
            <div className="label">2.3M</div>
            <p>Assessments</p>
          </li>
          <li>
            <div className="label">14.3M</div>
            <p>Practice Attempts</p>
          </li>
          <li>
            <div className="label">100+</div>
            <p>Clients</p>
          </li>
        </ul>
      </Container>
    </section>
  )
}
export default NumberSection;
