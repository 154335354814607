import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import EMICalculation from 'globals/components/EMICalculation';
import PlacementGoldPack from './Packs/PlacementGoldPack';
import PlacementDiamondPack from './Packs/PlacementDiamondPack';
import PlacementPlatinumPack from './Packs/PlacementPlatinumPack';

class PlacementTraining extends Component {
  selectBatch = (batchId) => {
    const { batchSelect } = this.props;
    batchSelect(batchId);
  }

  viewSyllabus = (syllabusType) => {
    const { syllabus } = this.props;
    syllabus(syllabusType);
  }

  render = () => {
    const {
      goldRespData, diamondRespData, platinumRespData, batchLoading,
    } = this.props;
    const { symbol, currencyCode, planCost: goldPlanCost } = goldRespData;
    const { planCost: diamondPlanCost } = diamondRespData;
    const {planCost: platinumPlanCost } = platinumRespData;
    console.log('planCost', goldPlanCost, platinumPlanCost, diamondPlanCost);
    const goldEMI = goldRespData.batches.length !== 0 ? goldRespData.batches[0].batchCost : 0;
    const platinumEMI = platinumRespData.batches.length !== 0 ? platinumRespData.batches[0].batchCost : 0;
    const diamondEMI = diamondRespData.batches.length !== 0 ? diamondRespData.batches[0].batchCost : 0;

    return (
      <section className="commonsection placementsection bgWhite">
        <div className="container">
          <div className="packNavs">
            {
              !batchLoading
                ? (
                  <Tabs defaultActiveKey="platinum">
                    <Tab eventKey="gold" title="Gold">
                      <div className="packQuote">Ideal For IT service companies</div>
                      <PlacementGoldPack goldRespData={goldRespData} />
                      <div className="limelink">
                        <span className="a_link" aria-hidden="true" onClick={() => this.viewSyllabus('gold')}>View Syllabus</span>
                      </div>
                      <div className="packBtn">
                        {
                          goldRespData.batches.length !== 0
                            ? (
                              <>
                                <div className="rateText"><span>{symbol}</span> {goldRespData.batches[0].batchCost}</div>
                                {
                                  currencyCode === 'INR'
                                    ? <div className="emiText">EMI starts at {symbol} {Math.round(EMICalculation(goldEMI) * 100) / 100}/mo</div>
                                    : ''
                                }
                              </>
                            )
                              :

                              (
                                <div>
                                    <div className="emiText batchEmiText mobPlanCost">{symbol} {goldPlanCost}</div>
                                    <div className="emiText">EMI starts at {symbol} {Math.round(EMICalculation(goldPlanCost) * 100) / 100}/mo</div>
                                </div>)
                        }
                        <div aria-hidden className={goldRespData.batches.length !== 0 ? "btn btn_primary modalbutton" : "btn btn_primary modalbutton disableSelectBatch"} onClick={() => this.selectBatch(61)}><span>Select a Batch</span></div>
                      </div>
                    </Tab>
                    <Tab eventKey="diamond" title="Diamond">
                      <div className="packQuote">Ideal For IT product companies</div>
                      <PlacementDiamondPack diamondRespData={diamondRespData} />
                      <div className="limelink">
                        <span className="a_link" aria-hidden="true" onClick={() => this.viewSyllabus('diamond')}>View Syllabus</span>
                      </div>
                      <div className="packBtn">
                        {
                          diamondRespData.batches.length !== 0
                            ? (
                              <>
                                <div className="rateText"><span>{symbol}</span> {diamondRespData.batches[0].batchCost}</div>
                                {
                                  currencyCode === 'INR'
                                    ? <div className="emiText">EMI starts at {symbol} {Math.round(EMICalculation(diamondEMI) * 100) / 100}/mo</div>
                                    : ''
                                }
                              </>
                            )
                              :
                              (
                                <div>
                                     <div className="emiText batchEmiText mobPlanCost">{symbol} {diamondPlanCost}</div>
                                    <div className="emiText">EMI starts at {symbol} {Math.round(EMICalculation(diamondPlanCost) * 100) / 100}/mo</div>
                                </div>)
                        }
                        <div aria-hidden className={diamondRespData.batches.length !== 0 ? "btn btn_primary modalbutton" : "btn btn_primary modalbutton disableSelectBatch"} onClick={() => this.selectBatch(64) }><span>Select a Batch</span></div>
                      </div>
                    </Tab>
                    <Tab eventKey="platinum" title="Platinum">
                      <div className="packQuote">Ideal For high package companies</div>
                      <PlacementPlatinumPack platinumRespData={platinumRespData} />
                      <div className="limelink">
                        <span className="a_link" aria-hidden="true" onClick={() => this.viewSyllabus('platinum')}>View Syllabus</span>
                      </div>
                      <div className="packBtn">
                        {
                          platinumRespData.batches.length !== 0
                            ? (
                              <>
                                <div className="rateText"><span>{symbol}</span> {platinumRespData.batches[0].batchCost}</div>
                                {
                                  currencyCode === 'INR'
                                    ? <div className="emiText">EMI starts at {symbol} {Math.round(EMICalculation(platinumEMI) * 100) / 100}/mo</div>
                                    : ''
                                }
                              </>
                            )
                              : 
                              (
                                <div>
                                    <div className="emiText batchEmiText mobPlanCost">{symbol} {platinumPlanCost}</div>
                                    <div className="emiText">EMI starts at {symbol} {Math.round(EMICalculation(platinumPlanCost) * 100) / 100}/mo</div>
                                </div>)
                        }
                        <div aria-hidden className={platinumRespData.batches.length !== 0 ? "btn btn_primary modalbutton" : "btn btn_primary modalbutton disableSelectBatch"} onClick={ () => this.selectBatch(65)}><span>Select a Batch</span></div>
                      </div>
                    </Tab>
                  </Tabs>
                )
                : ''
            }
          </div>
        </div>
      </section>
    );
  }
}

export default PlacementTraining;
