import React, { Component } from 'react';
import {
  Container, Col, Row,
} from 'react-bootstrap';
import FBpixel from 'globals/utils/FBpixel';
import Footer from 'globals/components/Footer';
// import LeadForm from 'globals/components/LeadForm';
import HeaderWithoutMenu from 'globals/components/HeaderWithoutMenu';
import { Helmet } from 'react-helmet';
import DefaultLoader from 'globals/components/loader/DefaultLoader';
import SchoolBanner from './SchoolBanner';
import QueriesContact from '../../globals/components/QueriesContact';
import './scss/school.scss';
import './scss/schoolresponsive.scss';
import SchoolProgramCard from './SchoolProgramCard';
import programListsUrl from './SchoolProgramLists.json';

class School extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    this.enablePixel();
    this.programData = [];
  }

  componentDidMount = () => {
    this.fetchProgramLists();
    // document.addEventListener('mouseleave', this.onBrowserTabFocus);
    // setTimeout(() => {
    //   const { formModalShow } = this.state;
    //   if (!formModalShow) {
    //     this.setState({ formModalShow: true });
    //     this.focusTextBox();
    //   }
    // }, 15000);
  }

  fetchProgramLists = () => {
    this.programData = programListsUrl;
    this.setState({ loading: false });
  }

  focusTextBox = () => {
    const focusName = document.getElementsByName('name')[0];
    focusName.focus();
  };

  enablePixel = () => {
    FBpixel.trackCustom('ViewContent', {
      content_ids: [{ 'Page Name': 'Schools' }],
    });
  };

  //   fetchFormModal = () => {
  //     this.setState({
  //       formModalShow: true,
  //     });
  //   };

  //   handleFormClose = () => this.setState({
  //     formModalShow: false,
  //   });

  //   onBrowserTabFocus = () => {
  //     this.setState({ formModalShow: true });
  //     this.focusTextBox();
  //   };

  //   programFilterHandle = (eventKey) => {
  //     this.setState({ activeTab: eventKey });
  //   };

  enrollnow = () => {
    const enrollment = document.querySelector('#pgmsection');
    window.scrollTo({
      top: enrollment.scrollIntoView(),
      behavior: 'smooth',
    });
  };

  render = () => {
    const {
      loading,
    } = this.state;

    return (
      <main className="schoolPage">
        <Helmet>
          <title>E-Box | Schools</title>
          <link rel="canonical" href="https://e-box.co.in/schools" />
          <meta
            name="description"
            content="School Coding Program, School Tuitions, AnyBody Can Draw, NEET, Creative Engineering Designers Jr,
            JEE, Aerial Machine Designer Jr, PolySkill Robotic Designers Jr."
          />
          <meta name="keywords" content="E-Box Schools" />
          <meta
            property="og:title"
            content="E-Box Schools"
          />
          <meta
            property="og:url"
            content="https://e-box.co.in/schools"
          />

          <meta
            itemProp="image"
            content="https://e-box.co.in/sharelogos/schoolsharelogo.png"
          />
          <meta
            property="og:image"
            content="https://e-box.co.in/sharelogos/schoolsharelogo.png"
          />
          <meta
            name="twitter:image"
            content="https://e-box.co.in/sharelogos/schoolsharelogo.png"
          />
          <meta
            name="twitter:image:src"
            content="https://e-box.co.in/sharelogos/schoolsharelogo.png"
          />
          <meta
            name="facebook:image"
            content="https://e-box.co.in/sharelogos/schoolsharelogo.png"
          />
          <meta
            name="facebook:image:src"
            content="https://e-box.co.in/sharelogos/schoolsharelogo.png"
          />
          <meta
            name="linkedin:image"
            content="https://e-box.co.in/sharelogos/schoolsharelogo.png"
          />
          <meta
            name="linkedin:image:src"
            content="https://e-box.co.in/sharelogos/schoolsharelogo.png"
          />
          <meta
            name="whatsapp:image"
            content="https://e-box.co.in/sharelogos/schoolsharelogo.png"
          />
          <meta
            name="whatsapp:image:src"
            content="https://e-box.co.in/sharelogos/schoolsharelogo.png"
          />
          <link
            itemProp="thumbnailUrl"
            href="https://e-box.co.in/sharelogos/schoolsharelogo.png"
          />
        </Helmet>
        <HeaderWithoutMenu />
        <SchoolBanner />
        <div id="pgmsection">
          <section className="pgmsection commonsection">
            <Container>
              <div className="col-sm-12">
                <div className="sectionTitleMedium text-center">Programs offered</div>
              </div>
              <Row>
                <Col xs={12}>
                  {(!loading) ? (
                    <>
                      <div className="program-group">
                        <Row>
                          {
                            this.programData.map((element) => (
                              <Col md={4} sm={12} key={element.id}>
                                <SchoolProgramCard element={element} />
                              </Col>
                            ))
                          }
                        </Row>
                      </div>
                    </>
                  )
                    : (
                      <div className="loading-wrapper">
                        <DefaultLoader />
                      </div>
                    )}
                </Col>
              </Row>
            </Container>
          </section>
        </div>
        <div className="bgWhite">
          <QueriesContact />
        </div>
        {/* <div className="callbackbtn button_load">
          <div
            aria-hidden
            className="btn btn_primary call"
            onClick={this.fetchFormModal}
          >
            Request a call back
          </div>
          <div
            aria-hidden
            className="btn btn_primary courseshow"
            onClick={this.enrollnow}
          >
            Enroll Now
          </div>
        </div> */}
        <Footer />
      </main>
    );
  };
}

export default School;
