import React, { Component } from 'react';
import styles from './DefaultLoader.module.scss';

class DefaultLoader extends Component {
  render = () => (
    <div className={styles.df_loading_grid}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}

export default DefaultLoader;
