import Axios from 'axios';
import {
  restbaseurl, appurl, reacturl, probackendurl,
} from 'globals/utils/constants';
// import { updateUserInfo } from 'globals/utils/index';

class RestDataSource {
  static async SendRequest(method, url, callback, data = {}, urlType = 'catalog') {
    let updateBaseUrl;
    if (urlType === 'app') {
      updateBaseUrl = appurl;
    } else if (urlType === 'react') {
      updateBaseUrl = reacturl;
    } else if (urlType === 'pro') {
      updateBaseUrl = probackendurl;
    } else {
      updateBaseUrl = restbaseurl;
    }

    try {
      let req;
      if ((data instanceof FormData) === true) {
        console.log('tex');
        req = Axios.request({
          baseURL: updateBaseUrl,
          method,
          url,
          data,
          withCredentials: true,
          headers: { 'Content-Type': 'multipart/form-data' },
        });
      } else {
        req = Axios.request({
          baseURL: updateBaseUrl,
          method,
          url,
          params: data,
          withCredentials: true,
        });
      }
      const resp = (await req);
      const respdata = resp.data;
      if (resp.status === 200) {
        const { estatus, emessage } = respdata;
        if (estatus === true && emessage === 'success') {
          if (String(url).search('auth/login') !== -1) {
            const { data: { valid, isBlocked } } = respdata;
            if (valid === true && isBlocked === false) {
              // updateUserInfo(respdata.c1);
            }
          }
        }
      }
      callback(resp.data);
    } catch (err) {
      if (err.response && err.response.status === 401 && err.response.data.message === 'Invalid token') {
        document.location.href = '/login';
      } else if (err.response && err.response.status === 401) {
        document.location.href = '/unauthorized';
      } else if (err.response && err.response.status === 500) {
        // document.location.href = '/500';
      }
    }
  }

  static async GetData(callback, url, data = {}, method = 'GET', urlType = 'catalog') {
    RestDataSource.SendRequest(method, url, callback, data, urlType);
  }

  static async Save(callback, url, data, urlType) {
    RestDataSource.SendRequest('post', url, callback, data, urlType);
  }

  static async Update(callback, url, data) {
    RestDataSource.SendRequest('put', `${url}/${data.id}`, callback, data);
  }

  static async Delete(callback, url, data) {
    RestDataSource.SendRequest('delete', `${url}/${data.id}`, callback, data);
  }

  static async Put(callback, url, data) {
    RestDataSource.SendRequest('put', url, callback, data);
  }
}

export default RestDataSource;
