/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import EMICalculation from 'globals/components/EMICalculation';

class PlacementBanner extends Component {
  enrollnow = () => {
    const enrollment = document.querySelector('#enrollPacks');
    window.scrollTo({
      top: enrollment.scrollIntoView(),
      behavior: 'smooth',
    });
  };

  render = () => {
    const {
      enrollLogin, enrollLoginURL,
    } = this.props;
    return (
      <>
        {window.innerWidth >= 991 ? (
          <section className="pgmlistsectionneet commonsection bgWhite">
            <div className="container">
              <div className="row">
                <div className="col-md-12 bannerText col-lg-6" id="neet_enroll">
                  <div className="sectionTitle">
                    Enroll in the most effective program for NEET
                  </div>
                  <div className="clr20" />
                  <p>
                    The NEET Course provides a comprehensive training by covering all the concepts
                    in Physics, Chemistry, Botany, Zoology and includes the following activities:
                    Video Lectures, Live Doubt Clearing Sessions, Chapterwise Practice Tests,
                    Formative Assessments, Cumulative Assessments, Unit Tests, Half Portion Tests,
                    Full Portion Tests and Mock Assessments.
                  </p>
                  <div className="clr" />
                  <div className="emi_neet_Btns">
                    <div className="neetEnrollLayer">
                      <div className="neetEnrollSec">
                        <div className="batch_wrap">
                          <span className="batch_for">FastTrack Revision Batch For 2021</span>
                        </div>
                        <a
                          className="btn btn_primary neetEnrollBtn"
                          onClick={() => enrollLogin('NEET2021')}
                          href={enrollLoginURL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Enroll Now @ &#8377; 20000
                        </a>
                        <div className="emiTextneetweb list_detail">
                          EMI starts at &#8377;
                          {Math.round(EMICalculation(20000))}/mo
                        </div>
                        {/* <div className="list_detail">
                       Validity : 1 Year
                      </div> */}
                      </div>
                      <div className="vertical_line"> </div>
                      <div className="neetEnrollSec mr20">
                        <div className="batch_wrap">
                          <span className="batch_for">For Batch 2022</span>
                        </div>
                        <a
                          className="btn btn_primary neetEnrollBtn"
                          onClick={() => enrollLogin('NEET2022')}
                          href={enrollLoginURL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Enroll Now @ &#8377; 35000
                        </a>
                        <div className="emiTextneetweb list_detail">
                          EMI starts at &#8377;
                          {Math.round(EMICalculation(35000))}/mo
                        </div>
                      </div>
                    </div>
                    <div className="neetEnrollLayer">
                      <div className="neetEnrollSec">
                        <div className="batch_wrap">
                          <span className="batch_for">For Batch 2023</span>
                        </div>
                        <a
                          className="btn btn_primary neetEnrollBtn"
                          onClick={() => enrollLogin('NEET2023')}
                          href={enrollLoginURL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Enroll Now @ &#8377; 70000
                        </a>
                        <div className="emiTextneetweb list_detail">
                          EMI starts at &#8377;
                          {Math.round(EMICalculation(70000))}/mo
                        </div>
                      </div>
                      <div className="vertical_line"> </div>
                      <div className="neetEnrollSec mr20">
                        <div className="batch_wrap">
                          <span className="batch_for">For Batch 2024</span>
                        </div>
                        <a
                          className="btn btn_primary neetEnrollBtn"
                          onClick={() => enrollLogin('NEET2024')}
                          href={enrollLoginURL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Enroll Now @ &#8377; 105000
                        </a>
                        <div className="emiTextneetweb list_detail">
                          EMI starts at &#8377;
                          {Math.round(EMICalculation(105000))}/mo
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="pgmlistsectionneet commonsection">
            <div className="container">
              <div className="row">
                <div className="col-md-12 bannerText col-lg-5">
                  <Card className="cardplacement">
                    <Card.Body>
                      <div className="sectionTitlemobile" id="neet_enroll">
                          Enroll in the most effective program for NEET
                      </div>
                      <div className="sectionparamobile">
                          The NEET Course provides a comprehensive training by covering
                          all the concepts in Physics, Chemistry, Botany, Zoology and
                          includes the following activities:
                          Video Lectures, Live Doubt Clearing Sessions, Chapterwise Practice Tests,
                          Formative Assessments, Cummulative Assessments, Unit Tests,
                          Half Portion Tests, Full Portion Tests and Mock Assessments.
                      </div>
                      <div>
                        <div className="batchWrap">
                          <div className="batch_wrap_mobile">
                            <span className="batch_for_mobile">FastTrack Revision Batch For 2021</span>
                          </div>
                          <div className="button_load">
                            <a
                              type="button"
                              aria-hidden="true"
                              className="btn btn_primary courseshowneet"
                              onClick={() => enrollLogin('NEET2021')}
                              href={enrollLoginURL}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                                Enroll Now @ &#8377; 20000
                            </a>

                          </div>
                          <div className="emiTextneet">
                              EMI starts at &#8377;
                            {Math.round(EMICalculation(20000) * 100) / 100}
                            /mo
                          </div>
                          {/* <div className="emiTextneet">Validity : 1 Year</div> */}
                        </div>
                        <div className="batchWrap">
                          <div className="batch_wrap_mobile">
                            <span className="batch_for_mobile">For Batch 2022</span>
                          </div>
                          <div className="button_load">
                            <a
                              type="button"
                              aria-hidden="true"
                              className="btn btn_primary courseshowneet"
                              href={enrollLoginURL}
                              onClick={() => enrollLogin('NEET2022')}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                                Enroll Now @ &#8377; 35000
                            </a>

                          </div>
                          <div className="emiTextneet">
                              EMI starts at &#8377;
                            {Math.round(EMICalculation(35000) * 100) / 100}
                            /mo
                          </div>
                          {/* <div className="emiTextneet">Validity : 2 Years</div> */}
                        </div>
                        <div className="batchWrap">
                          <div className="batch_wrap_mobile">
                            <span className="batch_for_mobile">For Batch 2023</span>
                          </div>
                          <div className="button_load">
                            <a
                              type="button"
                              aria-hidden="true"
                              className="btn btn_primary courseshowneet"
                              onClick={() => enrollLogin('NEET2023')}
                              href={enrollLoginURL}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                                Enroll Now @ &#8377; 70000
                            </a>

                          </div>
                          <div className="emiTextneet">
                              EMI starts at &#8377;
                            {Math.round(EMICalculation(70000) * 100) / 100}
                            /mo
                          </div>
                          {/* <div className="emiTextneet">Validity : 1 Year</div> */}
                        </div>
                        <div className="batchWrap">
                          <div className="batch_wrap_mobile">
                            <span className="batch_for_mobile">For Batch 2024</span>
                          </div>
                          <div className="button_load">
                            <a
                              type="button"
                              aria-hidden="true"
                              className="btn btn_primary courseshowneet"
                              onClick={() => enrollLogin('NEET2024')}
                              href={enrollLoginURL}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                                Enroll Now @ &#8377; 105000
                            </a>

                          </div>
                          <div className="emiTextneet">
                              EMI starts at &#8377;
                            {Math.round(EMICalculation(105000) * 100) / 100}
                            /mo
                          </div>
                          {/* <div className="emiTextneet">Validity : 1 Year</div> */}
                        </div>
                      </div>

                    </Card.Body>
                  </Card>
                  <div className="height50" />
                </div>
                <div className="col-md-12 col-lg-7" id="requestForm">
                  {/* <EngineeringForm /> */}
                </div>
              </div>
            </div>
          </section>
        )}
      </>
    );
  };
}

export default PlacementBanner;
