import React, { Component } from 'react';
import {
  Card,
} from 'react-bootstrap';

import Slider from 'react-slick';

class CourseSlider extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  modalCourses = (id) => {
    console.log('fun', id);
  }

  previous() {
    this.slider.slickPrev();
  }

  next() {
    this.slider.slickNext();
  }

  render() {
    const colorarr = ['#ffc300', '#463a9f', '#067bc2', '#ffc300', '#463a9f', '#067bc2', '#ffc300', '#463a9f'];
    const homeBannerImages = [
      {
        id: 'image1',
        name: 'Foundational Courses',
        course1: 'Learn Advanced Python Programming',
        course2: 'Python Basics for Beginners',
        course3: 'Learn Advanced Java Programming 1377',
        course4: 'Introduction to Algorithm Analysis and Design',
        course5: 'C Basics for Beginners: Learn C Fundamentals by Coding',
        course6: 'Data Structures : Deep Dive Using C',
        course7: 'The Complete MySQL Developer Course',
        course8: 'Servlet & JSP : A Complete Guide',
        course9: 'Hands-On Application Development with Java',
        course10: 'Spring Framework',
      },
      {
        id: 'image2',
        name: 'Foundational Courses',
        course1: 'C Basics for Beginners: Learn C Fundamentals by Coding',
        course2: 'Build Real World Websites using HTML and CSS',
        course3: 'Learn Advanced C++ Programming',
        course4: 'Servlet & JSP : A Complete Guide',
        course5: 'C++ Basics for Beginners: Learn C++ Fundamentals by Coding',
        course6: 'Java Basics for Beginners: Learn Java Fundamentals by Coding',
        course7: 'C# Basics for Beginners: Learn C# Fundamentals by Coding',
        course8: 'Python Basics for Beginners: Learn Python Fundamentals by Coding',
        course9: 'The Complete MySQL Developer Course',
        course10: 'Oracle Database Course Online',
      },
      {
        id: 'image3',
        name: 'Foundational Courses',
        course1: 'Learn Advanced C# Programming',
        course2: 'Learn Advanced Java Programming',
      },
      {
        id: 'image4',
        name: 'Advanced Courses',
        course1: 'Servlet & JSP : A Complete Guide',
        course2: 'Introduction to Algorithm Analysis and Design',
        course3: 'Hands-On Application Development with Java',
        course4: 'Hibernate Development from Scratch',
        course5: 'Spring Framework',
        course6: 'Learn MongoDB : Leading NoSQL Database from scratch',
        course7: 'ExpressJS from Beginner to Advanced',
        course8: 'Selenium WebDriver with Java – Basics to Advanced Frameworks',
        course9: 'The Complete Groovy Scripting Course',
        course10: 'Learn Jenkins from Scratch',
      },
      {
        id: 'image5',
        name: 'Advanced Courses',
        course1: 'Complete Cucumber Framework for BDD',
        course2: 'Git For Beginners',
        course3: 'Docker for the Absolute Beginner – DevOps',
        course4: 'Comprehensive ASP.NET MVC Course',
        course5: 'Spring Boot',
        course6: 'React JS',
      },
      {
        id: 'image6',
        name: 'Niche Courses',
        course1: 'Spark with Scala - Hands On with Big Data!',
        course2: 'Comprehensive Course on Hadoop Analytic Tool',
        course3: 'Android Online Course – Become an App Developer',
        course4: 'Chef Fundamentals : A Recipe for Automating Infrastructure',
        course5: 'Learn Maven From Scratch',
        course6: 'UFT',
        course7: 'Tableau Course - Become a Data Visualizer',
        course8: 'Comprehensive Subversion',
        course9: 'D3 JS – Produce Dynamic and Interactive Data Visualizations',
        course10: 'UIPath - Training',
      },
      {
        id: 'image7',
        name: 'Niche Courses',
        course1: 'Angular - Learn to Build Single Page Application',
      },
      {
        id: 'image8',
        name: 'Trending Courses',
        course1: 'Data Cleaning with Numpy and Pandas',
        course2: 'Statistics For Decision Making',
        course3: 'Machine Learning Techniques – Learning Made Easy',
        course4: 'Knowledge Discovery Process Models: From Traditional to Agile Modeling',
      },
    ];
    const bannerCard = homeBannerImages.map((img, index) => (
      <div key={img.id}>
        <Card
          style={{
            margin: '50px', width: '500px', height: '400px', marginLeft: '100px', backgroundColor: colorarr[index], color: 'white',
          }} onClick={() => this.modalCourses(img.id)}
        >
          <Card.Body style={{ marginTop: '10px' }}>
            <Card.Title style={{ fontWeight: 'bold', fontSize: '25px', textAlign: 'center' }}>{img.name}</Card.Title>
            <hr />
            <Card.Text style={{ marginLeft: '20px', color: 'white', fontWeight: '900' }}>
              <ul style={(index === '0') || (index === '1') || (index === '3') || (index === '5') ? { listStyleType: 'circle' } : { listStyleType: 'none' }}>
                <li style={{ listStyleType: 'circle' }}>{img.course1}<br /></li>
                <li style={(index === '6') ? { listStyleType: 'none' } : { listStyleType: 'circle' }}>{img.course2}<br /></li>
                <li style={(index === '2') || (index === '6') ? { listStyleType: 'none' } : { listStyleType: 'circle' }}>{img.course3}<br /></li>
                <li style={(index === '2') || (index === '6') ? { listStyleType: 'none' } : { listStyleType: 'circle' }}>{img.course4}<br /></li>
                <li style={(index === '2') || (index === '6') || (index === '7') ? { listStyleType: 'none' } : { listStyleType: 'circle' }}>{img.course5}<br /></li>
                <li style={(index === '2') || (index === '6') || (index === '7') ? { listStyleType: 'none' } : { listStyleType: 'circle' }}>{img.course6}<br /></li>
                <li>{img.course7}<br /></li>
                <li>{img.course8}<br /></li>
                <li>{img.course9}<br /></li>
                <li>{img.course10}<br /></li>
              </ul>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
    ));

    return (
      <>

        <h2 style={{ textAlign: 'center', marginTop: '5%' }}>IT Skill Courses</h2>
        <div>
          <Slider
            className="center"
            centerMode
            infinite
            centerPadding="60px"
            slidesToShow={2}
            speed={500}
            autoplay
            autoplaySpeed={2000}
            style={{ width: '100%', marginLeft: '5%' }}
          >
            {bannerCard}
          </Slider>
        </div>
        <br /><br /><br />

      </>
    );
  }
}

export default CourseSlider;
