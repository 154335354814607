import React, { Component } from 'react';
import './scss/CommonComponent.scss';

class AZCompanyList extends Component {
  render = () => {
    const { isEnggTuition } = this.props;
    return (
      <section className="commonsection">
        <div className={` ${isEnggTuition ? 'sectionTitle' : 'sectionTitleMedium'} text-center half-centered-section`}>
          {isEnggTuition ? 'A - Z, You name the company and  You Will Find E-Box Alumnus There!' : ' A - Z, You name the company and  You will find our alumni.'}
        </div>
        <div className="clr" />
        <div className="sectionpara text-center half-centered-section">
          We have successfully moulded over 10 lakh students from 100+ colleges
          and universities and we have a very strong alumni network across the globe
        </div>
        <div className="hero-bkg-animated" />
      </section>
    );
  }
}

export default AZCompanyList;
