import React, { Component } from 'react';
import {
  Container, Col, Row,
} from 'react-bootstrap';
import './scss/StepPracticeLearning.scss';

class WhyLearnEbox extends Component {
  render = () => (
    <section className="commonsection">
      <Container>
        <Row>
          <Col xs={12} className="learnebox">
            <div className="sectionTitleMedium text-center half-centered-section">
              Why should you choose E-Box for Learning &amp; Skilling ?
            </div>
            <div className="clr20" />
            <Row>
              <Col sm={12} md={3}>
                <div className="learnCard text-center">
                  <div className="learnimg">
                    <div className="learnSprite learn1" />
                  </div>
                  <div className="label">Practice Oriented Learning</div>
                  <p>
                    Learning through E-Box is completely Hands-on and practice based thereby helping you understand the intricacies of Live Project Scenarios
                  </p>
                </div>
              </Col>
              <Col sm={12} md={3}>
                <div className="learnCard text-center">
                  <div className="learnimg">
                    <div className="learnSprite learn2" />
                  </div>
                  <div className="label">High Quality Content</div>
                  <p>
                    E-Box’s programs and Courses are designed by Professionals in the Industry &amp; Academicians, assuring a quality learning experience
                  </p>
                </div>
              </Col>
              <Col sm={12} md={3}>
                <div className="learnCard text-center">
                  <div className="learnimg">
                    <div className="learnSprite learn3" />
                  </div>
                  <div className="label">24 x 7 Support</div>
                  <p>
                    With 100’s Mentors online ready to get your Doubts clarified and to Hand Hold you, you will always have someone to sort things our for you
                  </p>
                </div>
              </Col>
              <Col sm={12} md={3}>
                <div className="learnCard text-center">
                  <div className="learnimg">
                    <div className="learnSprite learn4" />
                  </div>
                  <div className="label">Industry Mentors</div>
                  <p>
                    All our Mentors are Top Professionals from their respective Industries. In E-Box you Learn and get mentored by professionals
                  </p>
                </div>
              </Col>
              <Col sm={12} md={3}>
                <div className="learnCard text-center">
                  <div className="learnimg">
                    <div className="learnSprite learn5" />
                  </div>
                  <div className="label">Auto Evaluation</div>
                  <p>
                    Auto Evaluation is the Core of E-Box. Your solutions and answers are evaluated instantaneously by E-Box, thereby saving learners a lot of time
                  </p>
                </div>
              </Col>
              <Col sm={12} md={3}>
                <div className="learnCard text-center">
                  <div className="learnimg">
                    <div className="learnSprite learn6" />
                  </div>
                  <div className="label">AI Blended Learning</div>
                  <p>
                    With Auto - Evaluation being the core of the Platform, E-Box uses AI &amp; ML to assist Learners during their course of Study
                  </p>
                </div>
              </Col>
              <Col sm={12} md={3}>
                <div className="learnCard text-center">
                  <div className="learnimg">
                    <div className="learnSprite learn7" />
                  </div>
                  <div className="label">Personalised Learning</div>
                  <p>
                    E-Box’s AI algorithms provide you with Exercises that are Personalised and Adaptive based on your Interest and Calibre
                  </p>
                </div>
              </Col>
              <Col sm={12} md={3}>
                <div className="learnCard text-center">
                  <div className="learnimg">
                    <div className="learnSprite learn8" />
                  </div>
                  <div className="label">Real Time Metrics</div>
                  <p>
                    Data becomes the core for evolving a personalised learning. E-Box uses these metrics for an Auto Corrective Learning Process
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default WhyLearnEbox;
