import React, { Component } from 'react';
import './scss/StepPracticeLearning.scss';

class StepPracticeLearning extends Component {
  render = () => {
    const { isEnggTuition } = this.props;

    return (
      <section className="learnsection commonsection">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className={` ${isEnggTuition ? 'sectionTitle' : 'sectionTitleMedium'} text-center`}>
                You will Learn through our Scientifically Designed &amp; Proven 5 Step Practice Oriented Learning Process
              </div>
              <div className="clr20" />

              <div className="row">
                <div className="col-sm-6">
                  <div className="step-card">
                    <div className="step-image">
                      <div className="learnSprite iAnalyse" />
                    </div>
                    <div className="stepDesc">
                      <div className="step-title">iAnalyse</div>
                      <div className="step-para">
                        {isEnggTuition ? 'Aims at training the learners in applying the concepts and constraints in other familiar situation and break the solutions into parts to analyse the correlation between parts' : ' In this session, you will find varieties of exercises to improve your code analysis, testing and debugging skills.'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="step-card">
                    <div className="step-image">
                      <div className="learnSprite iAssess" />
                    </div>
                    <div className="stepDesc">
                      <div className="step-title">iAssess</div>
                      <div className="step-para">
                        {isEnggTuition ? 'Evaluates the ability of learners in solving problems under time constrained environment while attempting to showcase their acquired skills' : ' In this session, you will have activities to self assess your knowledge and skills on the specific topic.'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="step-card">
                    <div className="step-image">
                      <div className="learnSprite iDesign" />
                    </div>
                    <div className="stepDesc">
                      <div className="step-title">iDesign</div>
                      <div className="step-para">
                        {isEnggTuition ? 'Intends to train the learners in justifying the decisions and course of actions through experimenting and hypothesising for generating new ideas and products thereby changing the ways of viewing things' : 'In this session, you will start designing and creating your own programs.'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="step-card">
                    <div className="step-image">
                      <div className="learnSprite iExplore" />
                    </div>
                    <div className="stepDesc">
                      <div className="step-title">iExplore</div>
                      <div className="step-para">
                        {isEnggTuition ? 'Includes exercises which enable the learners to explore the ideas, interpret, summarise and paraphrase by referring to various information available in books and other resources.' : ' In this session, you will find interesting set of activities which will make you explore more on the specific topic.'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="step-card">
                    <div className="step-image">
                      <div className="learnSprite iLearn" />
                    </div>
                    <div className="stepDesc">
                      <div className="step-title">iLearn</div>
                      <div className="step-para">
                        {isEnggTuition ? 'Encompasses exercises which enable the learners to recall and find the facts from course materials pertaining to the session' : 'In this session, you will find video lectures and other resources to learn the concepts.'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default StepPracticeLearning;
