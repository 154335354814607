import React, { Component } from 'react';
import './scss/learning.scss';

class Learningabcd extends Component {
  render = () => {
    const { isEnggTuition } = this.props;

    return (
      <section className="learnsection commonsection">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div
                className={` ${
                  isEnggTuition ? 'sectionTitle' : 'sectionTitleMedium'
                } text-center`}
              >
                Why should your Child learn Drawing ?
              </div>
              <div className="clr20" />

              <div className="row">
                <div className="col-sm-6">
                  <div className="step-card">
                    <div className="step-image">
                      <div className="learnSprite abcd1" />
                    </div>
                    <div className="stepDesc">
                      <div className="step-title">
                        Develops fine Motor Skills
                      </div>
                      <div className="step-para">
                        {isEnggTuition
                          ? 'Encompasses exercises which enable the learners to recall and find the facts from course materials pertaining to the session'
                          : 'Drawing creates immediate visual feedback that helps your child identify the best ways to produce the desired result.'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="step-card">
                    <div className="step-image">
                      <div className="learnSprite abcd2" />
                    </div>
                    <div className="stepDesc">
                      <div className="step-title">
                        Helps Establish Concentration
                      </div>
                      <div className="step-para">
                        {isEnggTuition
                          ? 'Intends to train the learners in justifying the decisions and course of actions through experimenting and hypothesising for generating new ideas and products thereby changing the ways of viewing things'
                          : 'Drawing aids your child in observing small details, concentrating on achieving a specific result and practicing tricky tasks thereby helping your child mature.'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="step-card">
                    <div className="step-image">
                      <div className="learnSprite abcd3" />
                    </div>
                    <div className="stepDesc">
                      <div className="step-title">
                        Encourages Visual Analysis
                      </div>
                      <div className="step-para">
                        {isEnggTuition
                          ? 'Includes exercises which enable the learners to explore the ideas, interpret, summarise and paraphrase by referring to various information available in books and other resources.'
                          : ' Drawing can help your child perform fundamental visual analysis of everyday spaces and creates the perfect opportunity to learn concepts like distance, size comparison and textural differences'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="step-card">
                    <div className="step-image">
                      <div className="learnSprite abcd4" />
                    </div>
                    <div className="stepDesc">
                      <div className="step-title">
                        Improves Hand-Eye Coordination
                      </div>
                      <div className="step-para">
                        {isEnggTuition
                          ? 'Evaluates the ability of learners in solving problems under time constrained environment while attempting to showcase their acquired skills'
                          : 'Drawing enables your child to draw connections between what he or she sees and what he or she does. This hand-eye coordination is important in athletic and recreational situations, as well as in academic scenarios'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="step-card">
                    <div className="step-image">
                      <div className="learnSprite abcd5" />
                    </div>
                    <div className="stepDesc">
                      <div className="step-title">
                        Increases Individual Confidence
                      </div>
                      <div className="step-para">
                        {isEnggTuition
                          ? 'Aims at training the learners in applying the concepts and constraints in other familiar situation and break the solutions into parts to analyse the correlation between parts'
                          : 'Drawing can help your child feel more intrinsic motivation, self-worth and validity. This affirmation will make him or her more confident in other areas that may not come as naturally as drawing.'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="step-card">
                    <div className="step-image">
                      <div className="learnSprite abcd6" />
                    </div>
                    <div className="stepDesc">
                      <div className="step-title">
                        Teaches Creative Problem Solving
                      </div>
                      <div className="step-para">
                        {isEnggTuition
                          ? 'Aims at training the learners in applying the concepts and constraints in other familiar situation and break the solutions into parts to analyse the correlation between parts'
                          : 'Along with visual analysis and concentration, drawing encourages your child to solve problems creatively. When he or she draws, your child would determine the best way to connect body parts, portray emotions and depict specific textures.'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };
}
export default Learningabcd;
