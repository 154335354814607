import React, { Component } from 'react';

class AdvancedPack extends Component {
  render = () => (
    <div className="placement-item diamond-block">
      <div className="packName">Advanced</div>
      <ul className="packUl">
        <li>
          <div className="packIcon calendar" />
          <span>4</span>
          <p>Months</p>
        </li>
      </ul>
      <div className="placement-middle"><span>&#8377;</span> 20,000</div>
    </div>
  )
}

export default AdvancedPack;
