import React from 'react';
import { Nav } from 'react-bootstrap';
// import { useHistory } from 'react-router-dom';
import Signature from '../../assets/images/signature.svg';
import ButtonArrow from '../../assets/images/workshop/nextarrow.png';
import styles from './scss/AmphiSignatureBlend.module.scss';

const AmphiSignatureBlend = () => (
  <>
    <div className={styles.signatureBlendLayout}>
      <div className={styles.introducing}>
        Introducing
      </div>
      <div className={styles.amphiSignatureBlend}>
        <span className={styles.amphi}>Amphi</span>

        <div className={styles.amphiSignatureBlendImg}>
          <img src={Signature} alt="Signature" className={styles.signature} />
          <br />
          <span className={styles.blend}>Blend</span>
        </div>
      </div>

      <div className={styles.descContainer}>
        <div className={styles.desc}>
          To Develop Higher-Order Thinkers and Product Developers in AI Era
        </div>
      </div>

      <div className={styles.learnMoreBtn}>
        <Nav.Link href="/amphiSignatureBlend">
          <div className={styles.btn}>
            <span>Learn More</span>
            <img src={ButtonArrow} alt="" />
          </div>

        </Nav.Link>
      </div>
    </div>
  </>
);
export default AmphiSignatureBlend;
