import React, { Component } from 'react';
import 'globals/components/scss/StepPracticeLearning.scss';

class NeetStepByPractice extends Component {
  render = () => {

    return (
      <section className="learnsection commonsection">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="sectionTitleMedium text-center">
                You will Learn through our Scientifically Designed &amp; Proven 3 Step Practice Oriented Learning Process
              </div>
              <div className="clr20" />

              <div className="row">
                <div className="col-sm-6">
                  <div className="step-card">
                    <div className="step-image">
                      <div className="learnSprite iLearn" />
                    </div>
                    <div className="stepDesc">
                      <div className="step-title">Lecture Session</div>
                      <div className="step-para">
                        In this session, aspirants will find video lectures and other resources to learn the concepts.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="step-card">
                    <div className="step-image">
                      <div className="learnSprite iDesign" />
                    </div>
                    <div className="stepDesc">
                      <div className="step-title">Practice Session</div>
                      <div className="step-para">
                        In this session, aspirants will start practising and solve problems on multiple scenarios.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="step-card">
                    <div className="step-image">
                      <div className="learnSprite iAssess" />
                    </div>
                    <div className="stepDesc">
                      <div className="step-title">Knowledge Recall</div>
                      <div className="step-para">
                        In this session, aspirants will find varieties of concept based questions in order to check the understanding of their capability.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default NeetStepByPractice;
