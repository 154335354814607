import React, { Component } from 'react';
import { Accordion, Card } from 'react-bootstrap';
// import { resturls } from 'globals/utils/apiurls';
// import GlobalService from 'services/GlobalService';
import parse from 'html-react-parser';
import evangelistFAQURL from './EvangelistFaq.json';

class EvangelistFAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: null,
      activeAccIndex: 0,
    };
  }

  componentDidMount() {
    this.fetchFAQ();
  }

  fetchFAQ = () => {
    // GlobalService.generalSelect(
    //   (respdata) => {
    //     this.setState({ loading: false, data: respdata });
    //   }, `${resturls.evangelistFAQURL}`, '', 'GET', 'react',
    // );
    this.setState({ loading: false, data: evangelistFAQURL });
  }

  render = () => {
    const { data, loading, activeAccIndex } = this.state;
    return (
      <section className="commonsection bgWhite">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="tagline text-center">Frequently asked Questions</div>
            </div>

            <div className="col-sm-12">
              {
                (!loading)
                  ? (
                    <Accordion defaultActiveKey="0">
                      {
                        data.map((element, index) => (
                          <Card key={element.key} className={(activeAccIndex === index) ? 'active' : ''}>
                            <Card.Header>
                              <Accordion.Toggle
                                variant="link" eventKey={`${index}`}
                                onClick={() => {
                                  if (activeAccIndex === index) {
                                    this.setState({ activeAccIndex: '' });
                                  } else {
                                    this.setState({ activeAccIndex: index });
                                  }
                                }}
                              >
                                <span className="blew-shrink" />
                                <span>{element.question}</span>
                              </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={`${index}`}>
                              <Card.Body>
                                {parse(element.answer)}
                              </Card.Body>
                            </Accordion.Collapse>
                          </Card>
                        ))
                      }
                    </Accordion>
                  )
                  : ''
              }
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default EvangelistFAQ;
