import EMICalculation from 'globals/components/EMICalculation';
import React, { Component } from 'react';

class PlacementGoldPack extends Component {
  render = () => {
    const { goldRespData } = this.props;
    const { batches, symbol, currencyCode, planCost } = goldRespData;
    const EMI = batches.length !== 0 ? batches[0].batchCost : 0;
    return (
      <div className="placement-item gold-block">
        <div className="packName">Gold</div>
        <ul className="packUl">
          <li>
            <div className="packIcon calendar" />
            <span>15</span>
            <p>Interactive live classes</p>
          </li>
          <li>
            <div className="packIcon mentor" />
            <span>1</span>
            <p>Year Mentor Support</p>
          </li>
        </ul>
        {
          batches.length !== 0
            ? (
              <>
                <div className="emiText batchEmiText planCost"><span>{symbol}</span> {batches[0].batchCost}</div>
                {
                  currencyCode === 'INR'
                    ? <div className="emiText">EMI starts at {batches[0].symbol} {Math.round(EMICalculation(EMI) * 100) / 100}/mo</div>
                    : ''
                }
              </>
            )
              : (
                <div>
                    <div className="emiText batchEmiText planCost">{symbol} {planCost}</div>
                    <div className="emiText">EMI starts at {symbol} {Math.round(EMICalculation(planCost) * 100) / 100}/mo</div>
                </div>)
        }
      </div>
    );
  }
}

export default PlacementGoldPack;
