import React, { Component } from 'react';
import './scss/CommonComponent.scss';
import {
  Container, Row, Col, Image,
} from 'react-bootstrap';
import Slider from 'react-slick';

class LimeLight extends Component {
  render = () => {
    const settings = {
      infinite: true,
      rows: 1,
      slidesPerRow: 1,
      slidesToShow: 3,
      slidesToScroll: 1,
      cssEase: 'linear',
      autoplay: true,
      adaptiveHeight: true,
      autoplaySpeed: 5000,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <section className="limelight commonsection mbarrows">
        <Container>
          <Row>
            <Col sm={12}>
              <div className="sectionTitleMedium">E-Box in the Limelight</div>
              <div className="clr20" />

              <div className="bottom-arrows custom-arrows">
                <Slider {...settings}>
                  <div className="limecard">
                    <div className="limeImage">
                      <Image src="assets/images/limelight/hindu.webp" alt="The Hindu" />
                    </div>
                    <div className="limetextpara">
                      Free online NEET crash course for government school students from June 15
                    </div>
                    <div className="limelink">
                      <a rel="noopener noreferrer" target="_blank" href="https://www.thehindu.com/news/national/tamil-nadu/free-online-neet-crash-course-for-government-school-students-from-june-15/article31692830.ece" className="a_link">Read the Full Story</a>
                    </div>
                  </div>

                  <div className="limecard">
                    <div className="limeImage">
                      <Image src="assets/images/limelight/express.webp" alt="Express" />
                    </div>
                    <div className="limetextpara">
                      Chief Minister Edappadi K Palaniswami on Tuesday kickstarted government’s free online coaching classes for National Eligibility cum Entrance Test (NEET), for class 12 students.
                    </div>
                    <div className="limelink">
                      <a rel="noopener noreferrer" target="_blank" href="https://www.newindianexpress.com/states/tamil-nadu/2020/may/28/free-online-coaching-for-tamil-nadu-neet-aspirants-from-june-15-2149132.html#:~:text=CHENNAI%3A%20The%20Department%20of%20School,Tamil%20Nadu%20government%20has%20announced" className="a_link">Read the Full Story</a>
                    </div>
                  </div>

                  <div className="limecard">
                    <div className="limeImage">
                      <Image src="assets/images/limelight/the-times-of-india.webp" alt="The times of India" />
                    </div>
                    <div className="limetextpara">
                    Free NEET course: Registrations open for Neet Online E-box crash course offered by TN education department
                    </div>
                    <div className="limelink">
                      <a rel="noopener noreferrer" target="_blank" href="https://timesofindia.indiatimes.com/home/education/news/free-neet-course-registrations-open-for-neet-online-e-box-crash-course-offered-by-tn-education-department/articleshow/76181582.cms" className="a_link">Read the Full Story</a>
                    </div>
                  </div>
                </Slider>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default LimeLight;
