import Footer from 'globals/components/Footer';
import Header from 'globals/components/Header';
import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import './Blog.scss';
import MentorsModal from './MentorsModal';

class BlogCorporate extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
  }

  render = () => {
    const settings = {
      className: '',
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 10000,
      dots: true,
    };

    return (
      <>
        <Helmet>
          <title>
            E-Box |
            Why-E-Box-is-considered-to-be-the-right-platform-for-Tech-Skilling
          </title>
          <link
            rel="canonical"
            href="https://e-box.co.in/Why-E-Box-is-considered-to-be-the-right-platform-for-Tech-Skilling"
          />
          <meta
            name="description"
            content="Eduportal Vs EduTech Platform"
          />

          <meta name="keywords" content="Corporate" />

          <meta
            property="og:title"
            content="E-Box | Why-E-Box-is-considered-to-be-the-right-platform-for-Tech-Skilling"
          />
          <meta
            property="og:url"
            content="https://e-box.co.in/Why-E-Box-is-considered-to-be-the-right-platform-for-Tech-Skilling"
          />

          <meta
            itemProp="image"
            content="https://e-box.co.in/sharelogos/corporatesharelogo.png"
          />
          <meta
            property="og:image"
            content="https://e-box.co.in/sharelogos/corporatesharelogo.png"
          />
          <meta
            name="twitter:image"
            content="https://e-box.co.in/sharelogos/corporatesharelogo.png"
          />
          <meta
            name="twitter:image:src"
            content="https://e-box.co.in/sharelogos/corporatesharelogo.png"
          />
          <meta
            name="facebook:image"
            content="https://e-box.co.in/sharelogos/corporatesharelogo.png"
          />
          <meta
            name="facebook:image:src"
            content="https://e-box.co.in/sharelogos/corporatesharelogo.png"
          />
          <meta
            name="linkedin:image"
            content="https://e-box.co.in/sharelogos/corporatesharelogo.png"
          />
          <meta
            name="linkedin:image:src"
            content="https://e-box.co.in/sharelogos/corporatesharelogo.png"
          />
          <meta
            name="whatsapp:image"
            content="https://e-box.co.in/sharelogos/corporatesharelogo.png"
          />
          <meta
            name="whatsapp:image:src"
            content="https://e-box.co.in/sharelogos/corporatesharelogo.png"
          />
          <link
            itemProp="thumbnailUrl"
            href="https://e-box.co.in/sharelogos/corporatesharelogo.png"
          />
        </Helmet>
        <main className="corporateBlog">
          <Header />

          <section className="pgmlistsection commonsection bgWhite">
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  {/* Article - 1 */}
                  <article>
                    <div className="article-row">
                      <div className="article-col-2">
                        <div className="blogLink">
                          <Link to="/niche-it-skill-training-programs">
                            Blogs<span>/</span>Right platform for tech skilling
                          </Link>
                        </div>
                        <div className="blogTitle">
                          Why E-Box is considered to be the right platform for
                          Tech Skilling?
                        </div>
                        <div className="blogType">
                          <label>by Team E-Box</label>
                          <label>10 min read</label>
                        </div>
                      </div>
                      <div className="article-col-2">
                        <div className="blogImage">
                          <Image src="/assets/images/blogs/blogimg.png" />
                        </div>
                      </div>
                    </div>
                  </article>
                  <div className="clr" />

                  {/* Article - 2 */}
                  <article className="commonArticle">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="blogsubtitle">
                        Eduportal Vs EduTech Platform
                        </div>
                        <div className="blogsubpara">
                          The convergence of various technologies has made our lives highly sophisticated causing many traditional systems to change. Most important among them is the way we learn. The change in ecology continues to create business avenues. Fortunately or unfortunately, education has fallen under the purview of enterprise. Online-learning is gaining prominence and the market size of various Online learning technologies is estimated to be USD 200Billion by 2025.
                          <br />
                          <br />
                          It is evident that various enterprises are vying for their share of the pie.  This has led to the mushrooming of numerous Online learning companies. Unfortunately, due to their dogfight, the consumers are confused and the standard of education is diluted.  The onus now is on the consumer to choose the company that is truly educational. 
                          <br />
                          <br />
                          Since, Online-Learning is in its novice stage, it is important that the consumers are educated about the different components that should be considered before choosing a particular model for learning.  One of the most important aspects to know is the difference between video based educational portals and Edu-Tech Platforms.
                        </div>
                      </div>
                    </div>
                  </article>

                  {/* Article - 3 */}
                  <article className="commonArticle">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="blogsubtitle">
                        What are Eduportals and why are they Ineffective?
                        </div>
                        <div className="blogsubpara">
                          Edu-Portals are content aggregators more like a digital publishing houses; the only difference is that the content is digital video instead of text books. These are similar to video sharing channels with a group of videos created on educational domain. A few quiz and assessment components are built into it in order to camouflage it as an educational platform. We should understand that quiz and assignment components are age-old learning aids that are becoming popular because of the availability of higher bandwidth.
                          <br />
                          <br />
                          They are ineffective because, effectiveness has to be measured from the perspective of outcome and the impact it has on the learners. Essentially, it has to meet the learning objectives of the candidates.  Learning has to be approached scientifically and it has been noticed that books provide a much higher registration of knowledge than Edu-portals.
                          <br />
                          <br />
                          Book reading allows brain to imagine, analyze and memorize in contrast to EduPortals which creates a visual impact like a movie and has a low knowledge registration on the brains. The limitation with Eduportal is that it makes a low impact and leaves minimal knowledge registration.  That explains why Eduportals are ineffective.
                          <br />
                          <br />
                          One would be surprised to note that there are so many International Eduportal companies that have built their brands as Online learning companies. They have done so by spending enormous amounts of money in advertisements. They have succeeded in registering their brands in the minds of the people as E-Learning companies.
                          <br />
                          <br />
                          Now, it is on the consumers to do the due diligence in order to differentiate between an Eduportal and an EdTech platform.
                        </div>
                      </div>
                    </div>
                  </article>

                  {/* Article - 4 */}
                  <article className="commonArticle">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="blogsubtitle">
                        How to differentiate an EduTech platform from an Eduportal? 
                        </div>
                        <div className="blogsubpara">
                          EduTech platforms are built through research on learning sciences.  This is what makes all the difference! EduTech platforms are built for the real purpose of imparting knowledge and enhancing skills. They are built to achieve concrete objectives in education and not so much for business reasons. Research and development happens over many years and will continue to be an ongoing process. They focus on transformation of knowledge into skills.
                          <br />
                          <br />
                          Let us use E-box to discuss EduTech Platforms further.
                          <br />
                          <br />
                          E-box is largely considered as the right platform for tech skilling. E-Box is NOT a video based edu-portal and that is why E-Box is admired by top technology companies.  It is a key platform that solves the problems related to skilling or talent transformation. Any platform will have to prove itself before being endorsed by corporate.  E-box has found its place in corporate after multiple demos with numerous technology teams.
                          <br />
                          <br />
                          One of the talent-transformation heads of a large corporate introduced E-Box to their learners thus "We are bringing you skilling programs through one of the most amazing EdTech platforms"
                          <br />
                          <br />
                          That should have been an astounding moment for all those who built E-box.
                        </div>
                      </div>
                    </div>
                  </article>

                  {/* Article - 5 */}
                  <article className="commonArticle">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="blogsubtitle">
                        Therefore, E-Box is a real EduTech platform. So what's amazing about this EdTech platform?
                        </div>
                        <div className="blogsubpara">
                          This platform was built over a decade and 20 years of learning science research has gone into it. It should surely have its massive differential features. It has got numerous auto-evaluation technology components that can be blended with concepts, skills and knowledge registrations. 
                          <br />
                          <br />
                          Content and Concepts of various courses blended with technology components for auto evaluation forms the core which powers the platform as a magnificent artificial intelligence platform. Without these core automation features, no platform can provide the real AI touch in education.
                          <br />
                          <br />
                          One needs to understand that AI features that many EduPortals claim to have incorporated are all user interactions through data summarization. They cannot provide the real AI touch at the knowledge and skill delivery levels.
                        </div>
                      </div>
                    </div>
                  </article>

                  {/* Article - 6 */}
                  <article className="commonArticle">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="blogsubtitle">
                        So how is E-Box –an EduTech platform different? 
                        </div>
                        <div className="blogsubpara">
                        For every course that is rendered, E-box classifies the course into unique segments that are scientific. The concepts are carefully researched and appropriate tech components are blended with the concepts. If the existing tech component doesn’t fit the requirement, the SME experts work with the development teams to build new components that suit the needs, thus ensuring knowledge registration and skill transformation .  All components are built with auto evaluation models to suit the AI requirements during practice based learning.
                          <br />
                          <br />
                          Therefore, E-Box brings in a new paradigm of learning called as practice oriented learning. All practice activities are auto evaluated, thus making the learning transparent and the learner accountable with a minute by minute data recorded about his/her learning. The system detects the difficulties faced by the learner in applying the knowledge towards solving problems and provides appropriate interventions at the problem solving level. The AI engine crunches the data in real time to provide the necessary hand-holding to the learner. This is why E-Box is said to be an ultimate EdTech platform and thus an amazing one.
                          <br />
                          <br />
                          Now, the answer to the question of why E-Box is considered the right Edutech platform becomes easy. Acquiring skill in any domain requires practice and application. Hence, just knowledge modeled EduPortal would not suffice for skill enablement. It requires a realistic Edu-Tech platform like E-Box for practice oriented learning through AI based auto evaluation mechanisms that ensure practice. This would guarantee highest levels of knowledge registration and skill outcome. In addition, E-Box can provide transparent metrics to evaluate the skill levels of an individual or that of an organization. E-box effectively enables the leadership team to take appropriate corrective measures to keep the skill parameters healthy at various levels. This is why E-Box is considered to be the right platform for Tech Skilling.
                        </div>
                      </div>
                    </div>
                  </article>


                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="sliders">
                    <Slider {...settings}>
                      <div className="sliderImg">
                        <Link to="/niche-it-skill-training-programs/angular-bootcamp">
                          <Image src="/assets/images/blogs/cpSlider/Angular.jpg" />
                        </Link>
                      </div>
                      <div className="sliderImg">
                        <Link to="/niche-it-skill-training-programs/reactjs-bootcamp">
                          <Image src="/assets/images/blogs/cpSlider/React.jpg" />
                        </Link>
                      </div>
                      <div className="sliderImg">
                        <Link to="/niche-it-skill-training-programs/springboot-bootcamp">
                          <Image src="/assets/images/blogs/cpSlider/Spring_Boot.jpg" />
                        </Link>
                      </div>
                    </Slider>
                  </div>

                  <div className="codingCol2 formDesign">
                    <MentorsModal />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </main>
      </>
    );
  };
}

export default BlogCorporate;
