class UserInfo {
  constructor() {
    this.ses_username = '';
    this.ses_name = '';
    this.ses_role = '';
    this.ses_userid = '';
    this.ses_auth = false;
    this.ses_update = false;
  }

  static getName = () => this.ses_name;

  static getUsername = () => this.ses_username;

  static getRole = () => this.ses_role;

  static getUserid = () => this.ses_userid;

  static isAuth = () => this.ses_auth;

  static isUpdate = () => this.ses_update;

  static setUserDetail = (inp) => {
    this.ses_username = inp.userName ? inp.userName : '';
    this.ses_name = inp.name ? inp.name : '';
    this.ses_role = inp.userRole ? inp.userRole : '';
    this.ses_userid = inp.userId ? inp.userId : '';
    this.ses_auth = inp.userPresent ? inp.userPresent : false;
    this.ses_update = true;
  }

  static printInfo = () => `${this.ses_username},${this.ses_name},${this.ses_role},${this.ses_userid}`;
}
export default UserInfo;
