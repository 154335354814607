import React, { Component } from 'react';
import { Card, Image } from 'react-bootstrap';

class NeetLearn extends Component {
  render = () => (
    <>
      <section className="commonsection">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="sectionTitleMedium text-center">
                How will you learn through E-Box
              </div>
            </div>
          </div>
          <div className="row cardRowlearn">
            {/* CARD-1 */}
            <div className="rowLearncontent">
              <Card className="neetlearnCard">
                <Card.Body>
                  <div className="highlightCardlearn">
                    <div className="h_icon">
                      {/* <div className="learnSprite neet1" /> */}
                      <Image src="/assets/images/neet/icons/icon1.webp" />
                    </div>
                    <div className="h_title">Master classes</div>
                  </div>
                </Card.Body>
              </Card>
            </div>

            {/* CARD-2 */}
            <div className="rowLearncontent">
              <Card className="neetlearnCard">
                <Card.Body>
                  <div className="highlightCardlearn">
                    <div className="h_icon">
                      <Image src="/assets/images/neet/icons/icon2.webp" />
                    </div>
                    <div className="h_title">Learning Videos</div>
                  </div>
                </Card.Body>
              </Card>
            </div>

            {/* CARD-3 */}
            <div className="rowLearncontent">
              <Card className="neetlearnCard">
                <Card.Body>
                  <div className="highlightCardlearn">
                    <div className="h_icon">
                      <Image src="/assets/images/neet/icons/icon3.webp" />
                    </div>
                    <div className="h_title">Learning Titbits</div>
                  </div>
                </Card.Body>
              </Card>
            </div>

            {/* CARD-4 */}
            <div className="rowLearncontent">
              <Card className="neetlearnCard">
                <Card.Body>
                  <div className="highlightCardlearn">
                    <div className="h_icon">
                      <Image src="/assets/images/neet/icons/icon4.webp" />
                    </div>
                    <div className="h_title">Discussion Forums</div>
                  </div>
                </Card.Body>
              </Card>
            </div>

            {/* CARD-5 */}
            <div className="rowLearncontent">
              <Card className="neetlearnCard">
                <Card.Body>
                  <div className="highlightCardlearn">
                    <div className="h_icon">
                      <Image src="/assets/images/neet/icons/icon5.webp" />
                    </div>
                    <div className="h_title">Mentor Support</div>
                  </div>
                </Card.Body>
              </Card>
            </div>

            {/* CARD-6 */}
            <div className="rowLearncontent">
              <Card className="neetlearnCard">
                <Card.Body>
                  <div className="highlightCardlearn">
                    <div className="h_icon">
                      <Image src="/assets/images/neet/icons/icon6.webp" />
                    </div>
                    <div className="h_title">Editorials</div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default NeetLearn;
