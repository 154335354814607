import React, { Component } from 'react';
import UserInfo from 'models/UserInfo';
import {
  Container, Navbar, Nav,
} from 'react-bootstrap';
// import GlobalService from 'services/GlobalService';
// import { resturls } from 'globals/utils/apiurls';
import { prourl } from 'globals/utils/constants';
import './scss/Header.scss';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navBtns: '',
      // univList: [],
    };
  }

  componentDidMount = () => {
    setTimeout(() => {
      const navBtns = (UserInfo.isAuth() === true) ? (
        <Nav.Link className="btn btn_primary" href={`${prourl}/`}>Dashboard</Nav.Link>
      )
        : (
          <>
            <Nav.Link className="btn btn_primary" href={`${prourl}`}>
              Login/Register
            </Nav.Link>
          </>
        );

      this.setState({ navBtns });
    }, 500);
    // this.retCollegesList();
  }

  // retCollegesList = () => {
  //   GlobalService.generalSelect(
  //     (respdata) => {
  //       const { status, message, data } = respdata;
  //       if (status === true && message === 'success') {
  //         this.setState({ univList: data });
  //       }
  //     }, resturls.retCollegesList,
  //   );
  // }

  sideMenuClose = () => {
    const menuClass = document.querySelector('.closemenu');
    menuClass.click();
  }

  render = () => {
    const { navBtns } = this.state;
    return (
      <div className="main-header">
        <Container>
          <Navbar expand="lg">
            <Navbar.Brand href="/">
              <img src="/assets/images/eboxlogo.svg" alt="E-Box" />
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse>
              <div className="mobileHeader">
                <Navbar.Brand href="/">
                  <img src="/assets/images/eboxlogo.svg" alt="E-Box" />
                </Navbar.Brand>
                <Navbar.Toggle className="closemenu">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <g transform="translate(0.214 0.214)">
                      <path
                        className="b"
                        d="M7.208,101.947l3.812-3.812.786-.786a.3.3,0,0,0,0-.42l-.841-.841a.3.3,0,0,0-.42,0l-4.6,4.6-4.6-4.6a.3.3,0,0,0-.42,0l-.841.841a.3.3,0,0,0,0,.42l4.6,4.6-4.6,4.6a.3.3,0,0,0,0,.42l.841.841a.3.3,0,0,0,.42,0l4.6-4.6,3.812,3.812.786.786a.3.3,0,0,0,.42,0l.841-.841a.3.3,0,0,0,0-.42Z"
                        transform="translate(5.84 -90.15)"
                      />
                    </g>
                  </svg>
                </Navbar.Toggle>
              </div>
              <Nav className="ml-auto">
                <Nav.Link href="/#program" onClick={this.sideMenuClose}>Why E-Box</Nav.Link>
                <Nav.Link href="/#trainerDrivenPrograms" onClick={this.sideMenuClose}>Programs & Courses</Nav.Link>
                {/* <Nav.Link href="/assessments">Assessments</Nav.Link> */}
                {/* <Nav.Link href="/mentor">Mentors</Nav.Link> */}
                <Nav.Link href="/digital">Digital</Nav.Link>
                <Nav.Link href="/powered-campuses">Universities</Nav.Link>
                {/* {(univList.length > 0) && (
                  <NavDropdown className="univ-drop" title="Universities">
                    {univList.map((x) => (
                      <NavDropdown.Item key={x.code} href={`/college/${x.code}`}
                       title={x.name}>{x.code}</NavDropdown.Item>
                    ))}
                  </NavDropdown>
                )}
                <Nav.Link href="https://blog.e-box.co.in/">Blogs</Nav.Link> */}
                <Nav.Link href="/contact">Contact Us</Nav.Link>
              </Nav>
              <Nav>
                {navBtns}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </div>
    );
  }
}

export default Header;
