/* eslint-disable react/jsx-props-no-spreading */
function getParamsValue(paramOption) {
  const getLocation = window.location.href;
  const url = new URL(getLocation);
  const params = url.searchParams.get(paramOption);
  if (params !== null && params !== undefined) {
    return params;
  }
  return false;
}

function eboxTitleName(pageName) {
  const element = document.getElementsByTagName('title')[0];
  element.parentNode.removeChild(element);

  const title = document.createElement('title');
  title.innerHTML = pageName;
  document.head.appendChild(title);
}

// eslint-disable-next-line import/prefer-default-export
export { getParamsValue, eboxTitleName };
