import React, { Component } from 'react';
import {
  Image,
} from 'react-bootstrap';
import mentor1 from '../../assets/images/mentor/mentor-1.png';
import banner from '../../assets/images/mentor/bannerAssess.png';

import '../mentor/mentorHome.scss';

class FeaturesEbox extends Component {
  render() {
    return (
      <>
        <main style={{ backgroundColor: 'white' }}>
          <div className="mentorScreen">
            <section className="mentorsection_1 commonsection" id="advanatages">
              <div className="container">
                <div className="row">
                  <div className="section_title col-sm-12">Features of E-BOX Assessment</div>
                  <div className="clr20" />
                  <div className="col-sm-12 col-md-7">
                    <ul className="blinkcircle">
                      <li data-aos="fade-up">Create Question Papers</li>
                      <li data-aos="fade-up">Assign Faculties</li>
                      <li data-aos="fade-up">Examination Scheduling</li>
                      <li data-aos="fade-up">Student allocation</li>
                      <li data-aos="fade-up">Different evaluation methods</li>
                      <li data-aos="fade-up">Reporting and Analysis</li>
                      <li data-aos="fade-up">Notification and alerts</li>
                    </ul>
                  </div>
                  <div className="col-sm-12 col-md-5">
                    <Image className="mentor-1" src={mentor1} alt="mentor1" />
                  </div>
                </div>
              </div>
            </section>
            <section className="mentorsection_1 commonsection">
              <div className="container">
                <div className="row">
                  <div data-aos="fade-right" className="col-sm-12 col-md-5 col-md-pull-7">
                    <Image className="mentor-2" src={banner} alt="mentor2" />
                  </div>
                  <div className="col-sm-12 col-md-7 col-md-push-5">
                    <div data-aos="fade-up" className="section_title">Benefits of E-BOX</div>
                    <div className="clr20" />
                    <ul className="blinkcircle">
                      <li data-aos="fade-up">
                        <p className="section_title"> Efficient Exam Management</p>
                        <p data-aos="fade-left" className="parastyle">
                                                    An online exam solution in cloud environment
                        </p>
                        <p data-aos="fade-left" className="parastyle">
                          Saves time and reduces faculty workload significantly
                        </p>
                        <p data-aos="fade-left" className="parastyle">
                        Track students progress and improve learning outcomes.
                        </p>
                      </li>

                      <li data-aos="fade-up">
                        <p className="section_title">Secrecy and Transparency</p>
                        <p data-aos="fade-left" className="parastyle">
                        Simplify exam process to make it easy and transparent
                        </p>
                        <p data-aos="fade-left" className="parastyle">
                        Ensure strict confidentiality of the question paper
                        </p>

                      </li>

                      <li data-aos="fade-up">
                        <p className="section_title">Cost Saving</p>
                        <p data-aos="fade-left" className="parastyle">
                          Reduce printing and stationery costs of question papers
                        </p>
                        <p data-aos="fade-left" className="parastyle">
                          Avoid transportation costs
                        </p>
                      </li>

                    </ul>

                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>

      </>
    );
  }
}

export default FeaturesEbox;
