import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './scss/CourseTrackCard.scss';

class CourseTrackCard extends Component {
  render = () => {
    const { trackDetail } = this.props;
    return (
      <div className="roleCard">
        <Link to={`/courseTrack/${trackDetail.courseId}/${trackDetail.catalogId}`}>
          <div className="roleImage">
            <img src={trackDetail.logo} alt={trackDetail.courseName} />
          </div>
          <div className="roledetail">{trackDetail.courseName}</div>
        </Link>
      </div>
    );
  }
}

export default CourseTrackCard;
