import React, { Component } from 'react';
import { contactNumber } from 'globals/utils/constants';

class GetmeplacedQueriesCall extends Component {
  render = () => {
    const { isEnggTuition, fetchFormModal } = this.props;
    return (
      <>
        {isEnggTuition
          ? (
            <>
              <section className="commonsection querysection engg_tution">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="callFlex">
                        <div className="sectionTitle2">Still have any Queries ?</div>
                        <a href={`tel:${contactNumber}`} className="btn btn_primary modalbutton">
                          <span>Call us on {contactNumber}</span>
                        </a>
                        {window.innerWidth >= 768 ? (
                          <>
                            <div className="sectionTitle2 mar15">or</div>
                            <span className="btn btn_primary">
                              <span aria-hidden onClick={fetchFormModal}>
                                Request a callback from us
                              </span>
                            </span>
                          </>
                        ) : '' }
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          ) : (
            <section className="commonsection querysection">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="callFlex">
                      <div className="sectionTitle2">Get in Touch with us Now</div>
                      <a href={`tel:${contactNumber}`} className="btn btn_primary">
                        <span>Call us on {contactNumber}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
      </>
    );
  }
}

export default GetmeplacedQueriesCall;
