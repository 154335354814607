import EMICalculation from 'globals/components/EMICalculation';
import React, { Component } from 'react';

class PlacementPlatinumPack extends Component {
  render = () => {
    const { platinumRespData } = this.props;
    const { batches, symbol, currencyCode, planCost } = platinumRespData;
    const EMI = batches.length !== 0 ? batches[0].batchCost : 0;
    return (
      <div className="placement-item platinum-block">
        <div className="tagtext">6 Interviews</div>
        <div className="packName">Platinum</div>
        <ul className="packUl">
          <li>
            <div className="packIcon calendar" />
            <span>35</span>
            <p>Interactive live classes</p>
          </li>
          <li>
            <div className="packIcon mentor" />
            <span>2</span>
            <p>Year Mentor Support</p>
          </li>
        </ul>
        {
          batches.length !== 0
            ? (
              <>
                <div className="emiText batchEmiText planCost"><span>{symbol}</span> {batches[0].batchCost}</div>
                {
                  currencyCode === 'INR'
                    ? <div className="emiText">EMI starts at {batches[0].symbol} {Math.round(EMICalculation(EMI) * 100) / 100}/mo</div>
                    : ''
                }
              </>
            )
              : (
                <div>
                    <div className="emiText batchEmiText planCost">{symbol} {planCost}</div>
                    <div className="emiText">EMI starts at {symbol} {Math.round(EMICalculation(planCost) * 100) / 100}/mo</div>
                </div>)
        }
      </div>
    );
  }
}

export default PlacementPlatinumPack;
