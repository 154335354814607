import React, { Component } from 'react';

class BeginnerPack extends Component {
  render = () => (
    <div className="placement-item gold-block">
      <div className="packName">Beginner</div>
      <ul className="packUl">
        <li>
          <div className="packIcon calendar" />
          <span>2</span>
          <p>Months</p>
        </li>
      </ul>
      <div className="placement-middle"><span>&#8377;</span> 10,000</div>
    </div>
  )
}

export default BeginnerPack;
