import React, { Component } from 'react';
import { Container, Navbar } from 'react-bootstrap';
import './scss/Header.scss';

class HeaderWithoutMenu extends Component {
  render = () => (
    <>
      {window.innerWidth >= 500 ? (
        <div className="main-header no-menu-header">
          <Container>
            <Navbar expand="lg">
              <Navbar.Brand href="/">
                <img src="/assets/images/eboxlogo.svg" alt="E-Box" />
              </Navbar.Brand>
              {/* { navBtns } */}
            </Navbar>
          </Container>
        </div>
      ) : (
        <div className="main-header no-menu-header">
          <Container>
            <Navbar expand="lg">
              <Navbar.Brand href="/">
                <img src="/assets/images/white-logo.webp" alt="E-Box" />
              </Navbar.Brand>
              {/* { navBtns } */}
            </Navbar>
          </Container>
        </div>
      )}
    </>
  );
}

export default HeaderWithoutMenu;
