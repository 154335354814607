import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import parse from 'html-react-parser';
import Slider from 'react-slick';
import FBpixel from 'globals/utils/FBpixel';
import LeadForm from 'globals/components/LeadForm';
import Footer from 'globals/components/Footer';
import HeaderWithoutMenu from 'globals/components/HeaderWithoutMenu';
import QueriesContact from 'globals/components/QueriesContact';
import GlobalService from 'services/GlobalService';
import { resturls } from 'globals/utils/apiurls';
import { Helmet } from 'react-helmet';
import HighlightsProgramabcd from './HighlightsProgramabcd';
import AbcdBanner from './AbcdBanner';
import AbcdTraining from './AbcdTraining';
import AbcdTrainingCarsouel from './AbcdTrainingCarsouel';
import './scss/abcd.scss';
import './scss/abcdresponsive.scss';
import BeginnerPack from './Packs/BeginnerPack';
import AdvancedPack from './Packs/AdvancedPack';
import IntegratedPack from './Packs/IntegratedPack';
import Learningabcd from './Learningabcd';

class Abcd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subjectData: '',
      respdata: '',
      modalShow: false,
      batchModalShow: false,
      formModalShow: false,
      syllabusModal: false,
      syllabusList: [],
      syllabusTitle: '',
      activeTab: '10',
      rateCard: {},
      beginnerData: '',
      advancedData: '',
      integratedData: '',
      dataLoading: true,
      timeIncrement: 30000,
      delayMultipleCount: 30000,
    };
    this.enablePixel();
  }

  componentDidMount = () => {
    this.getBeginnerBatch(122);
    const { delayMultipleCount } = this.state;
    sessionStorage.modalOpened = false;
    if (sessionStorage.getItem('modalOpened') === 'false') this.showFormModal(delayMultipleCount);
  }

  showFormModal = (delayMultipleCount) => {
    setTimeout(() => {
      if (sessionStorage.getItem('modalOpened') === 'false') {
        this.fetchFormModal();
        this.focusTextBox();
      }
    }, delayMultipleCount);
  }

  focusTextBox = () => {
    const focusName = document.getElementsByName('name')[0];
    focusName.focus();
  };

  enablePixel = () => {
    FBpixel.trackCustom('ViewContent', {
      content_ids: [{ 'Page Name': 'AnyBody Can Draw' }],
    });
  };

  getBeginnerBatch = (batchId) => {
    GlobalService.generalSelect(
      (respdata) => {
        this.getAdvancedBatch(respdata, 123);
      },
      `${resturls.tutoringPlanDetailsUrl}?id=${batchId}`,
      '',
      'GET',
      'app',
    );
  }

  getAdvancedBatch = (beginnerData, batchId) => {
    GlobalService.generalSelect(
      (respdata) => {
        this.getIntegratedBatch(beginnerData, respdata, 124);
      },
      `${resturls.tutoringPlanDetailsUrl}?id=${batchId}`,
      '',
      'GET',
      'app',
    );
  }

  getIntegratedBatch = (beginnerData, advancedData, batchId) => {
    GlobalService.generalSelect(
      (respdata) => {
        this.setState({
          beginnerData,
          advancedData,
          integratedData: respdata,
          dataLoading: false,
        });
      },
      `${resturls.tutoringPlanDetailsUrl}?id=${batchId}`,
      '',
      'GET',
      'app',
    );
  }

  selectBatch = (batchId) => {
    sessionStorage.setItem('modalOpened', true);
    let rateCard = { three: 0, ten: 0 };
    switch (batchId) {
      case 122:
        rateCard = { three: 30000, ten: 10000 };
        break;
      case 123:
        rateCard = { three: 60000, ten: 20000 };
        break;
      case 124:
        rateCard = { three: 75000, ten: 25000 };
        break;
      default:
        break;
    }

    GlobalService.generalSelect(
      (respdata) => {
        this.setState({
          batchModalShow: true,
          formModalShow: false,
          respdata,
          rateCard,
        });
      },
      `${resturls.tutoringPlanDetailsUrl}?id=${batchId}`,
      '',
      'GET',
      'app',
    );
    sessionStorage.setItem('modalOpened', true);
  };

  selectSyllabus = (syllabusType) => {
    if (syllabusType === 'Beginner') {
      this.setState({
        syllabusModal: true,
        formModalShow: false,
        syllabusTitle: 'Beginner',
        syllabusList: [
          'Introduction to Materials',
          'Drawing',
          'Rendering',
          'Composition',
          'Perspectives',
          'Illustration',
        ],
      });
    }

    if (syllabusType === 'Advanced') {
      this.setState({
        syllabusModal: true,
        formModalShow: false,
        syllabusTitle: 'Advanced',
        syllabusList: [
          'Introduction to Materials',
          'Drawing',
          'Rendering',
          'Composition',
          'Perspectives',
          'Illustration',
          'Technical Drawing',
          'Illustrations',
          'Design Concerts(Fashion Arc, Automobile, Product Design)',
        ],
      });
    }

    if (syllabusType === 'Integrated') {
      this.setState({
        syllabusModal: true,
        formModalShow: false,
        syllabusTitle: 'Integrated',
        syllabusList: [
          'Introduction to Materials',
          'Drawing',
          'Rendering',
          'Composition',
          'Perspectives',
          'Illustration',
          'Technical Drawing',
          'Illustrations',
          'Design Concerts(Fashion Arc, Automobile, Product Design)',
          'Painting',
          'Water Colours',
          'Oil Based',
          'Colour Pencil/Crayon',
        ],
      });
    }
    sessionStorage.setItem('modalOpened', true);
  };

  handleClose = () => this.setState({
    modalShow: false,
  });

  handleBatchModalClose = () => {
    const { delayMultipleCount, timeIncrement } = this.state;
    sessionStorage.setItem('modalOpened', false);
    this.setState({
      batchModalShow: false,
      activeTab: '10',
      delayMultipleCount: delayMultipleCount + timeIncrement,
    });
  };

  fetchFormModal = () => {
    this.setState({
      formModalShow: true,
    });
  };

  handleFormClose = () => {
    const { delayMultipleCount, timeIncrement } = this.state;
    this.showFormModal(delayMultipleCount + timeIncrement);
    sessionStorage.setItem('modalOpened', false);
    this.setState({
      formModalShow: false,
      delayMultipleCount: delayMultipleCount + timeIncrement,
    });
  }

  handleSyllabusClose = () => {
    const { delayMultipleCount, timeIncrement } = this.state;
    sessionStorage.setItem('modalOpened', false);
    this.setState({
      syllabusModal: false,
      delayMultipleCount: delayMultipleCount + timeIncrement,
    });
  }

  onBrowserTabFocus = () => {
    const { formModalShow, syllabusModal, batchModalShow } = this.state;
    if (syllabusModal || batchModalShow) {
      this.setState({ formModalShow: false });
    } else if (!formModalShow) {
      this.setState({ formModalShow: true });
      this.focusTextBox();
    }
  };

  programFilterHandle = (eventKey) => {
    this.setState({ activeTab: eventKey });
  };

  enrollnow = () => {
    const enrollment = document.querySelector('#enrollPacks');
    window.scrollTo({
      top: enrollment.scrollIntoView(),
      behavior: 'smooth',
    });
  };

  render = () => {
    const {
      subjectData,
      modalShow,
      batchModalShow,
      respdata,
      formModalShow,
      syllabusModal,
      syllabusList,
      syllabusTitle,
      activeTab,
      beginnerData,
      advancedData,
      integratedData,
      dataLoading,
    } = this.state;
    let placementPacks = '';

    if (subjectData !== '') {
      if (subjectData.packs === 1) {
        placementPacks = <IntegratedPack />;
      } else if (subjectData.packs === 2) {
        placementPacks = (
          <>
            <AdvancedPack />
            <IntegratedPack />
          </>
        );
      } else if (subjectData.packs === 3) {
        placementPacks = (
          <>
            <BeginnerPack />
            <AdvancedPack />
            <IntegratedPack />
          </>
        );
      }
    }

    // let nav3Items = false;
    // let nav10Items = false;
    // if (respdata !== '') {
    //   nav3Items = respdata.batches.filter((element) => element.capacity === 3);
    //   nav10Items = respdata.batches.filter((element) => element.capacity === 10);
    // }

    return (
      <main className="abcdPage">
        <Helmet>
          <title>E-Box | AnyBody Can Draw</title>
          <link rel="canonical" href="https://e-box.co.in/anybodycandraw/" />
          <meta
            name="description"
            content="Is your Child a Budding Picasso? Enroll your child in E-Box’s exclusive ABCD program and lay the foundation for your Child’s Colorful Future"
          />
          <meta name="keywords" content="AnyBody Can Draw" />
          <meta
            property="og:title"
            content="E-Box | Drawing | Rendering | Painting | Composition"
          />
          <meta
            property="og:url"
            content="https://e-box.co.in/anybodycandraw/"
          />

          <meta
            itemProp="image"
            content="https://e-box.co.in/sharelogos/abcdsharelogo.png"
          />
          <meta
            property="og:image"
            content="https://e-box.co.in/sharelogos/abcdsharelogo.png"
          />
          <meta
            name="twitter:image"
            content="https://e-box.co.in/sharelogos/abcdsharelogo.png"
          />
          <meta
            name="twitter:image:src"
            content="https://e-box.co.in/sharelogos/abcdsharelogo.png"
          />
          <meta
            name="facebook:image"
            content="https://e-box.co.in/sharelogos/abcdsharelogo.png"
          />
          <meta
            name="facebook:image:src"
            content="https://e-box.co.in/sharelogos/abcdsharelogo.png"
          />
          <meta
            name="linkedin:image"
            content="https://e-box.co.in/sharelogos/abcdsharelogo.png"
          />
          <meta
            name="linkedin:image:src"
            content="https://e-box.co.in/sharelogos/abcdsharelogo.png"
          />
          <meta
            name="whatsapp:image"
            content="https://e-box.co.in/sharelogos/abcdsharelogo.png"
          />
          <meta
            name="whatsapp:image:src"
            content="https://e-box.co.in/sharelogos/abcdsharelogo.png"
          />
          <link
            itemProp="thumbnailUrl"
            href="https://e-box.co.in/sharelogos/abcdsharelogo.png"
          />
        </Helmet>

        <HeaderWithoutMenu />
        <AbcdBanner />
        <div>
          <Learningabcd />
        </div>

        <div className="highlightsSection">
          <HighlightsProgramabcd />
        </div>

        <div className="placementPacks" id="enrollPacks">
          {window.innerWidth >= 991 ? (
            <AbcdTraining
              batchSelect={this.selectBatch}
              beginnerData={beginnerData}
              advancedData={advancedData}
              integratedData={integratedData}
              dataLoading={dataLoading}
            />
          ) : (
            <AbcdTrainingCarsouel
              batchSelect={this.selectBatch}
              syllabus={this.selectSyllabus}
              beginnerData={beginnerData}
              advancedData={advancedData}
              integratedData={integratedData}
              dataLoading={dataLoading}
            />
          )}
        </div>
        <div className="bgWhite">
          <QueriesContact />
        </div>
        {/* <div className="callbackbtn">
          <div
            aria-hidden
            className="btn btn_primary"
            onClick={this.fetchFormModal}
          >
            Request a call back
          </div>
        </div> */}
        {window.innerWidth >= 991
          ? (
            <div className="callbackbtn button_load">
              <div
                aria-hidden
                className="btn btn_primary call"
                onClick={this.fetchFormModal}
              >
         Request a call back
              </div>
              <div
                aria-hidden
                className="btn btn_primary courseshow"
                onClick={this.enrollnow}
              >
         Enroll Now
              </div>
            </div>
          ) : (
            <div className="callbackbtn button_load">
              <div
                aria-hidden
                className="btn btn_primary courseshow"
                onClick={this.fetchFormModal}
              >
       Request a call back
              </div>
              <div
                aria-hidden
                className="btn btn_primary call"
                onClick={this.enrollnow}
              >
       Enroll Now
              </div>
            </div>

          )}
        <Footer />

        {/* BATCH MODAL */}
        { batchModalShow ? (
          <Modal
            show={batchModalShow}
            onHide={this.handleBatchModalClose}
            backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            className="batch-modal"
          >
            <div className="modal-body">
              <div
                aria-hidden
                className="iconsprite closeIcon"
                onClick={this.handleBatchModalClose}
              />
              <div className="sectionTitle2 text-center">
                {
                  !respdata.plan
                    ? 'Select your batch'
                    : respdata.plan
                }

              </div>

              <div className="row">
                <div className="col-sm-12">
                  {
                    (respdata.batches.length !== 0)
                      ? (
                        <>
                          {/* <div className="center-navs mt20">
                            <Nav
                              variant="pills" defaultActiveKey={activeTab}
                              onSelect={this.programFilterHandle}
                            >
                              {
                                nav3Items.length !== 0
                                && (
                                  <Nav.Item>
                                    <Nav.Link eventKey="3">3 Member Batch<br />&#8377;
                                    {rateCard.three}</Nav.Link>
                                  </Nav.Item>
                                )
                              }
                              {
                                nav10Items.length !== 0
                                && (
                                  <Nav.Item>
                                    <Nav.Link eventKey="10">10 Member Batch<br />&#8377;
                                    {rateCard.ten}</Nav.Link>
                                  </Nav.Item>
                                )
                              }
                            </Nav>
                          </div> */}

                          <Slider
                            className="batchCarsouel custom-arrows bottom-arrows"
                            infinite
                            slidesToShow={2}
                            slidesToScroll={1}
                            adaptiveHeight
                            focusOnSelect
                            centerMode
                            centerPadding={100}
                            responsive={[
                              {
                                breakpoint: 1024,
                                settings: {
                                  slidesToShow: 1,
                                  slidesToScroll: 1,
                                  centerMode: false,
                                  centerPadding: 0,
                                },
                              },
                            ]}
                          >
                            {
                              respdata.batches.filter(
                                (x) => (x.capacity.toString() === activeTab),
                              ).map((element) => (
                                <div className="modal-batch-card" key={element.id}>
                                  <ul>
                                    <li className="calendar">
                                      <div className="label">Batch Starts on</div>
                                      <p>{element.batchDate}</p>
                                    </li>
                                    <li className="class">
                                      <div className="label">Live Classes</div>
                                      <p>
                                        {element.startDate.split(' ')[3]}&nbsp;
                                        {element.startDate.split(' ')[4]}&nbsp;
                                        &nbsp;-&nbsp;
                                        {element.endDate.split(' ')[3]}&nbsp;
                                        {element.endDate.split(' ')[4]}
                                      </p>
                                    </li>
                                    <li className="clock">
                                      <div className="label">Days</div>
                                      <p>{element.sessionDays.toString()}</p>
                                    </li>
                                  </ul>
                                  <a
                                    className="btn btn_primary"
                                    href={element.paymentLink}
                                  >
                                    Enroll Now
                                  </a>
                                </div>
                              ))
                            }
                          </Slider>
                        </>
                      )
                      : <div className="text-center nobatch">Batches Coming Soon. !!!</div>
                  }
                </div>
              </div>
            </div>
          </Modal>
        ) : (
          ''
        )}

        {
          modalShow ? (
            <Modal
              show
              onHide={this.handleClose}
              className="courseModal"
            >
              <div className="modal-top">
                <div
                  aria-hidden
                  className="iconsprite closeIcon courseclose"
                  onClick={this.handleClose}
                />
                <div className="course-top">
                  <div className="label">{subjectData.title}</div>
                  <p>{subjectData.description}</p>
                </div>
              </div>

              <div className="modal-body">
                <div className="row">
                  <div className="col-sm-8">
                    <div className="mod-tit">About this course</div>
                    <p className="mod-p">{subjectData.about}</p>
                  </div>
                  <div className="col-sm-4">
                    <div className="grey-tit">Includes</div>
                    <ul className="include-ul">{parse(subjectData.includes)}</ul>
                  </div>
                </div>
                <div className="clr20" />

                <div className="row">
                  <div className="col-sm-12">
                    <div className="mod-tit">Topics Covered</div>
                    <ul className="topicslist">{parse(subjectData.topics)}</ul>
                  </div>
                </div>

                <hr />
                <div className="clr20" />
                <div className="row included">
                  <div className="col-sm-12 big-tit">Included In</div>
                  <div className="clr20" />

                  <div className="placement-flex">{placementPacks}</div>
                </div>
              </div>
            </Modal>
          ) : (
            ''
          )
        }

        {/* Form Modal */}
        {
          (formModalShow && !syllabusModal && !batchModalShow) ? (
            <Modal
              show={formModalShow}
              onHide={this.handleFormClose}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              className="form-modal engineerForm-modal"
            >
              <Modal.Body>
                <div
                  aria-hidden
                  className="iconsprite closeIcon"
                  onClick={this.handleFormClose}
                />
                <LeadForm
                  registrationPurpose="AnyBody Can Draw"
                  gaCategory="ABCD"
                  gaLabel="Demo Class"
                />
              </Modal.Body>
            </Modal>
          ) : (
            ''
          )
        }

        {/* Syllabus Modal */}
        {
          syllabusModal ? (
            <Modal
              show={syllabusModal}
              onHide={this.handleSyllabusClose}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              className="topics-modal"
            >
              <Modal.Body>
                <div
                  aria-hidden
                  className="iconsprite closeIcon"
                  onClick={this.handleSyllabusClose}
                />
                <div className="sectionTitle2 text-center">
                  {syllabusTitle} Pack
                </div>
                <ul className="topicsUl">
                  {syllabusList.map((element) => (
                    <li key={element}>{element}</li>
                  ))}
                </ul>
              </Modal.Body>
            </Modal>
          ) : (
            ''
          )
        }
      </main>
    );
  };
}

export default Abcd;
