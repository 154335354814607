import ReactPixel from 'react-facebook-pixel';

const pixelOptions = {
  autoConfig: true,
  debug: false,
};
const pixelId = 248858209548567;
const FBpixel = ReactPixel;

FBpixel.init(pixelId, pixelOptions);

export default FBpixel;
