import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import ReactGA from 'react-ga';
import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import { resturls } from 'globals/utils/apiurls';
import GlobalService from 'services/GlobalService';

class LeadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      modalAlert: {
        show: false,
        msg: '',
        classType: '',
      },
    };
  }

  formSubmit = (fields, formkIns) => {
    const {
      registrationPurpose, gaCategory, gaLabel, track,
    } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const formData = {
      name: fields.name,
      email: fields.email,
      mobilenumber: fields.mobilenumber,
      registrationPurpose,
      track: track || '',
      utm_source: urlParams.has('utm_source')
        ? urlParams.get('utm_source')
        : '',
      utm_campaign: urlParams.has('utm_campaign')
        ? urlParams.get('utm_campaign')
        : '',
    };
    ReactGA.event({
      category: gaCategory,
      action: 'Submit',
      label: gaLabel,
    });
    if (Object.keys(formData).length !== 0) {
      this.setState({ disabled: true });
      GlobalService.generalSelect(
        (respdata) => {
          if (respdata) {
            this.setState({
              disabled: false,
              modalAlert: {
                show: true,
                msg: 'Thank you for your interest...! We will reach you soon.',
                classType: 'alert-success',
              },
            });
            formkIns.resetForm();
          } else {
            this.setState({
              disabled: false,
              modalAlert: {
                show: true,
                msg: 'Oops..! Something went wrong. Please try again.',
                classType: 'alert-danger',
              },
            });
          }
          setTimeout(() => {
            this.setState({
              disabled: false,
              modalAlert: {
                show: false,
                msg: '',
                classType: '',
              },
            });
          }, 4000);
        },
        `${resturls.registerWebsiteUser}`, formData, 'POST', 'app',
      );
    }
  };

  render = () => {
    const { disabled, modalAlert } = this.state;
    const { show, msg, classType } = modalAlert;
    return (
      <>
        <div className="formDesign engineerForm">
          <div className="formtexts">
            <div className="formTitle">Talk to an Expert Now</div>
            <div className="formPara">
            A few Details about Yourself and our Experts will contact you
            </div>
          </div>
          <Formik
            initialValues={{
              name: '',
              email: '',
              mobilenumber: '',
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Name is required'),
              email: Yup.string()
                .matches(
                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  'Invalid email address',
                )
                .email('Email is invalid')
                .required('Email is required'),
              mobilenumber: Yup.number()
                .typeError('Invalid Mobile Number')
                .required('Mobile Number is required')
                .test('mobileValidate', 'Invalid Mobile Number', (value) => {
                  if (value && value.toString().length === 10) {
                    return true;
                  }
                  return false;
                }),
            })}
            onSubmit={this.formSubmit}
            render={({ errors, touched }) => (
              <Form>
                <Row>
                  <Col sm={12}>
                    <div className="form-group">
                      <div className="label">Name *</div>
                      <Field
                        name="name"
                        type="text"
                        className={`form-control${
                          errors.name && touched.name ? ' is-invalid' : ''
                        }`}
                        placeholder="Name"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                  <Col sm={12}>
                    <div className="form-group">
                      <div className="label">Email *</div>
                      <Field
                        name="email"
                        type="text"
                        className={`form-control${
                          errors.email && touched.email ? ' is-invalid' : ''
                        }`}
                        placeholder="Email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                  <Col sm={12}>
                    <div className="form-group">
                      <div className="label">Mobile Number *</div>
                      <Field
                        name="mobilenumber"
                        type="text"
                        className={`form-control${
                          errors.mobilenumber && touched.mobilenumber
                            ? ' is-invalid'
                            : ''
                        }`}
                        placeholder="Mobile"
                      />
                      <ErrorMessage
                        name="mobilenumber"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </Col>
                  <Col sm={12}>
                    <div className="btn_section text-center">
                      {show ? (
                        <div className="alert-block">
                          <div id="main-alert" className={`alert ${classType}`}>
                            {msg}
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                      <button
                        type="submit"
                        className={`btn btn_primary modalbutton ${
                          disabled ? 'btn_disabled' : ''
                        }`}
                      >
                        Request a Callback
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          />
        </div>
      </>
    );
  };
}

export default LeadForm;
