import React, { Component } from 'react';
import FBpixel from 'globals/utils/FBpixel';
import { Container, Navbar, Image } from 'react-bootstrap';
import { appurl } from 'globals/utils/constants';

class EvangelistHeader extends Component {
  constructor(props) {
    super(props);
    this.enablePixel();
  }

  enablePixel = () => {
    FBpixel.trackCustom('ViewContent', {
      content_ids: [{ 'Page Name': 'CP' }],
    });
  }

  render = () => (
    <div className="main-header no-menu-header">
      <Container>
        <Navbar expand="lg">
          <Navbar.Brand href="/">
            <Image src="/assets/images/eboxlogo.svg" alt="E-Box" />
          </Navbar.Brand>
          <a href={`${appurl}/marketer/signIn`} className="btn btn_primary_outer"> Sign in </a>
        </Navbar>
      </Container>
    </div>
  )
}
export default EvangelistHeader;
