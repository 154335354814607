import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import EMICalculation from 'globals/components/EMICalculation';
import DefaultLoader from 'globals/components/loader/DefaultLoader';
import BeginnerPack from './Packs/BeginnerPack';
import AdvancedPack from './Packs/AdvancedPack';
import IntegratedPack from './Packs/IntegratedPack';

class AbcdTrainingCarsouel extends Component {
  selectBatch = (batchId) => {
    const { batchSelect } = this.props;
    batchSelect(batchId);
  };

  viewSyllabus = (syllabusType) => {
    const { syllabus } = this.props;
    syllabus(syllabusType);
  };

  render = () => {
    const {
      beginnerData, advancedData, integratedData, dataLoading,
    } = this.props;

    let beginnerEMI = 0;
    let advancedEMI = 0;
    let integratedEMI = 0;

    if (!dataLoading) {
      beginnerEMI = beginnerData.batches.length !== 0 && beginnerData.batches.filter((begin) => begin.capacity === 10);
      beginnerEMI = beginnerData.batches.length !== 0 && beginnerEMI[0].batchCost;

      advancedEMI = advancedData.batches.length !== 0 && advancedData.batches.filter((adv) => adv
        .capacity === 10);
      advancedEMI = advancedData.batches.length !== 0 && advancedEMI[0].batchCost;

      integratedEMI = integratedData.batches.length !== 0 && integratedData.batches.filter((inter) => inter.capacity === 10);
      integratedEMI = integratedData.batches.length !== 0 && integratedEMI[0].batchCost;
    }

    return (
      <section className="commonsection placementsection bgWhite">
        <div className="container">
          <div className="sectionTitleMedium text-center">
            Enroll your Child in E-Box ABCD Program today !
          </div>
          <div className="clr20" />

          {
            !dataLoading
              ? (
                <div className="packNavs">
                  <Tabs defaultActiveKey="Advanced">
                    <Tab eventKey="Beginner" title="Beginner">
                      <BeginnerPack />
                      <div className="limelink">
                        <span
                          className="a_link"
                          aria-hidden="true"
                          onClick={() => this.viewSyllabus('Beginner')}
                        >
                          View Syllabus
                        </span>
                      </div>
                      <div className="packBtn">
                        {
                          beginnerData.batches.length !== 0
                            ? (
                              <>
                                <div className="rateText">{beginnerData.symbol} {beginnerEMI}</div>
                                {
                                  beginnerData.currencyCode === 'INR'
                                    ? <div className="emiText text-center">EMI starts at {beginnerData.batches[0].symbol} {Math.round(EMICalculation(beginnerEMI) * 100) / 100}/mo</div>
                                    : ''
                                }
                              </>
                            )
                            : <div className="emiText batchEmiText">Batches Coming Soon.</div>
                        }
                        <div
                          aria-hidden
                          className="btn btn_primary courseshowabcd"
                          onClick={() => this.selectBatch(122)}
                        >
                          <span>Choose your Batches</span>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="Advanced" title="Advanced">
                      <AdvancedPack />
                      <div className="limelink">
                        <span
                          className="a_link"
                          aria-hidden="true"
                          onClick={() => this.viewSyllabus('Advanced')}
                        >
                          View Syllabus
                        </span>
                      </div>
                      <div className="packBtn">
                        {
                          advancedData.batches.length !== 0
                            ? (
                              <>
                                <div className="rateText">{advancedData.symbol} {advancedEMI}</div>
                                {
                                  advancedData.currencyCode === 'INR'
                                    ? <div className="emiText text-center">EMI starts at {advancedData.batches[0].symbol} {Math.round(EMICalculation(advancedEMI) * 100) / 100}/mo</div>
                                    : ''
                                }
                              </>
                            )
                            : <div className="emiText batchEmiText">Batches Coming Soon.</div>
                        }
                        <div
                          aria-hidden
                          className="btn btn_primary courseshowabcd"
                          onClick={() => this.selectBatch(123)}
                        >
                          <span>Choose your Batches</span>
                        </div>
                      </div>
                    </Tab>
                    <Tab eventKey="Integrated" title="Integrated">
                      <IntegratedPack />
                      <div className="limelink">
                        <span
                          className="a_link"
                          aria-hidden="true"
                          onClick={() => this.viewSyllabus('Integrated')}
                        >
                          View Syllabus
                        </span>
                      </div>
                      <div className="packBtn">
                        {
                          integratedData.batches.length !== 0
                            ? (
                              <>
                                <div className="rateText">{integratedData.symbol} {integratedEMI}</div>
                                {
                                  integratedData.currencyCode === 'INR'
                                    ? <div className="emiText text-center">EMI starts at {integratedData.batches[0].symbol} {Math.round(EMICalculation(integratedEMI) * 100) / 100}/mo</div>
                                    : ''
                                }
                              </>
                            )
                            : <div className="emiText batchEmiText">Batches Coming Soon.</div>
                        }
                        <div
                          aria-hidden
                          className="btn btn_primary courseshowabcd"
                          onClick={() => this.selectBatch(124)}
                        >
                          <span>Choose your Batches</span>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              )
              : <DefaultLoader />
          }
        </div>
      </section>
    );
  }
}

export default AbcdTrainingCarsouel;
