import React, { Component } from 'react';
import { resturls } from 'globals/utils/apiurls';
import { appurl } from 'globals/utils/constants';
import copy from 'copy-to-clipboard';
import Footer from 'globals/components/Footer';
import GlobalService from 'services/GlobalService';
import FBpixel from 'globals/utils/FBpixel';
import DocumentMeta from 'react-document-meta';
import QueriesCall from 'globals/components/QueriesCall';
import './scss/evangelist.scss';
import './scss/evangelistResponsive.scss';
import {
  Image, Row, Col, Toast,
} from 'react-bootstrap';
import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import {
  FacebookShareButton, WhatsappShareButton, LinkedinShareButton, TwitterShareButton,
} from 'react-share';
import EvangelistHeader from './EvangelistHeader';
import EvangelistFAQ from './EvangelistFAQ';

class EvangelistHome extends Component {
  constructor(props) {
    super(props);
    this.enablePixel();
    this.enrollForm = React.createRef();
    this.state = {
      toastShow: false,
      formDisplay: true,
      msgData: '',
      errorMsg: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mouseleave', this.onBrowserTabFocus);
  }

  onBrowserTabFocus = () => {
    const requestForm = document.querySelector('#request-form');
    window.scrollTo({
      top: requestForm.scrollIntoView(),
      behavior: 'smooth',
    });
  }

  programsScroll = () => {
    window.scrollTo({
      top: this.enrollForm.current.offsetTop,
      behavior: 'smooth',
    });
  }

  enablePixel = () => {
    FBpixel.trackCustom('ViewContent', {
      content_ids: [{ 'Page Name': 'CP' }],
    });
  }

  formSubmit = (fields, formkIns) => {
    const urlParams = new URLSearchParams(window.location.search);
    const formData = {
      name: fields.name,
      email: fields.email,
      mobilenumber: fields.mobilenumber,
      password: fields.password,
      registrationPurpose: 'Evangelist',
      utm_source: (urlParams.has('utm_source')) ? urlParams.get('utm_source') : '',
      utm_campaign: (urlParams.has('utm_campaign')) ? urlParams.get('utm_campaign') : '',
    };

    if (Object.keys(formData).length !== 0) {
      GlobalService.generalSelect(
        (respdata) => {
          if (respdata.message.toLowerCase() === 'success') {
            formkIns.resetForm();
            this.setState({
              formDisplay: false,
              msgData: '',
              errorMsg: false,
            });
          } else {
            this.setState({
              formDisplay: true,
              msgData: respdata.message,
              errorMsg: true,
            });
            setTimeout(() => {
              this.setState({
                errorMsg: false,
              });
            }, 5000);
          }
        }, `${resturls.marketerSignupURL}`, formData, 'GET', 'app',
      );
    }
  };

  Copytext = () => {
    copy(window.location.href);
    this.setState({ toastShow: true });
  }

  toastClose = () => {
    this.setState({ toastShow: false });
  }

  componentWilUnmount() {
    window.removeEventListener('focus', this.onBrowserTabFocus);
  }

  render = () => {
    const {
      toastShow, formDisplay, msgData, errorMsg,
    } = this.state;

    const meta = {
      title: 'E-Box | Evangelist',
      description: 'Evangelist',
      canonical: 'https://e-box.co.in/evangelist',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'Evangelist, Partner',
        },
      },
    };

    return (
      <DocumentMeta {...meta}>
        <main className="evangelistPage">
          <EvangelistHeader />
          <section className="pgmlistsection evangelist commonsection bgWhite">
            <div className="container">
              <div className="row">
                <div className="col-md-5 bannerText col-sm-12">
                  <div className="sectionTitle">Become an E-Box Evangelist today !</div>
                  <div className="sectionpara">
                    Start promoting Courses and Programs offered by E-Box, help learners learn the right thing
                    the right way and earn with every purchase made.
                  </div>
                  <div aria-hidden className="btn btn_primary banner_btn element_hide" onClick={this.programsScroll}>Become an Evangelist Now</div>
                </div>
              </div>
            </div>
          </section>

          <section className="stepsection commonsection" ref={this.enrollForm}>
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-6">
                  <div className="sectionTitleMedium">Become an E-Box Evangelist &amp; Start Earning in 2 Simple Steps!</div>
                  <div className="clr20" />

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="step-card">
                        <div className="step-image">
                          <Image src="/assets/images/evangelist/icons/user.svg" />
                        </div>
                        <div className="stepDesc">
                          <div className="step-title">Sign Up</div>
                          <div className="step-para">
                            Sign Up with our E-Box Evangelist Program and explore the Courses and Programs Offered
                            by E-Box
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <div className="step-card">
                        <div className="step-image">
                          <Image src="/assets/images/evangelist/icons/recommendation.svg" />
                        </div>
                        <div className="stepDesc">
                          <div className="step-title">Recommend</div>
                          <div className="step-para">
                            Share and Recommend Courses and Programs to Hundreds of Learning using your Personalized Link
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <div className="step-card">
                        <div className="step-image">
                          <Image src="/assets/images/evangelist/icons/mail.svg" />
                        </div>
                        <div className="stepDesc">
                          <div className="step-title">Earn</div>
                          <div className="step-para">
                            Earn Referral Fee for every learner who purchases using your Personalized Link you’ve
                            shared
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 col-lg-6 form-col" id="request-form">
                  <div className="center-form">
                    <div className="sectionTitle2 text-center">Sign Up now !</div>
                    <div className="sectionpara2 text-center">
                      Start promoting E-Box Courses and Programs and Start Earning
                    </div>

                    {
                      (formDisplay)
                        ? (
                          <Formik
                            initialValues={{
                              name: '',
                              email: '',
                              mobilenumber: '',
                              password: '',
                            }}
                            validationSchema={Yup.object().shape({
                              name: Yup.string()
                                .required('Name is required'),
                              email: Yup.string()
                                .email('Email is invalid')
                                .required('Email is required'),
                              mobilenumber: Yup.number()
                                .typeError('Invalid Mobile Number')
                                .required('Mobilenumber is required')
                                .test('mobileValidate', 'Invalid Mobile Number', (value) => {
                                  if (value && value.toString().length === 10) {
                                    return true;
                                  }
                                  return false;
                                }),
                              password: Yup.string()
                                .required('Password is required')
                                .min(8, 'Password must be at least 8 characters'),
                            })}
                            onSubmit={this.formSubmit}
                            render={({ errors, touched }) => (
                              <Form className="evangelistForm">
                                <Row>
                                  <Col sm={12}>
                                    <div className="form-group">
                                      <div className="label">Name *</div>
                                      <Field name="name" type="text" className={`form-control${errors.name && touched.name ? ' is-invalid' : ''}`} placeholder="Name" autoComplete="off" />
                                      <ErrorMessage name="name" component="div" className="invalid-feedback" />
                                    </div>
                                  </Col>
                                  <Col sm={12}>
                                    <div className="form-group">
                                      <div className="label">Email Id *</div>
                                      <Field
                                        name="email"
                                        type="text"
                                        className={`form-control${(errors.email && touched.email) || (errorMsg) ? ' is-invalid' : ''}`} placeholder="Email" autoComplete="off"
                                      />
                                      {
                                        (errorMsg)
                                          ? <div className="customerror">{msgData}</div>
                                          : ''
                                      }
                                      <ErrorMessage name="email" component="div" className="invalid-feedback" />
                                    </div>
                                  </Col>
                                  <Col sm={12}>
                                    <div className="form-group">
                                      <div className="label">Mobile Number *</div>
                                      <Field name="mobilenumber" type="text" className={`form-control${errors.mobilenumber && touched.mobilenumber ? ' is-invalid' : ''}`} placeholder="Mobile" autoComplete="off" />
                                      <ErrorMessage name="mobilenumber" component="div" className="invalid-feedback" />
                                    </div>
                                  </Col>
                                  <Col sm={12}>
                                    <div className="form-group">
                                      <div className="form-group">
                                        <div className="label">Set a Password *</div>
                                        <Field name="password" type="password" className={`form-control${errors.password && touched.password ? ' is-invalid' : ''}`} placeholder="Password" autoComplete="off" />
                                        <ErrorMessage name="password" component="div" className="invalid-feedback" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm={12}>
                                    <div className="mt20 text-center">
                                      <button type="submit" className="btn btn_primary">Submit</button>
                                    </div>
                                  </Col>
                                </Row>
                              </Form>
                            )}
                          />
                        )
                        : (
                          <div className="successLink">
                            Thanks for registering. <a rel="noopener noreferrer" href={appurl + resturls.marketerSigninURL}>Click here</a> to login
                          </div>
                        )

                    }

                  </div>
                </div>
              </div>
            </div>
          </section>

          <EvangelistFAQ />

          <section className="commonsection spreadsection">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="spreadFlex">
                    <div className="spreadText">
                      <div className="sectionTitle2">Spread the Word !</div>
                      <div className="sectionpara2">
                        Do you know someone who can become an E-Box Evangelist ? Share the word with them
                        and help them earn and monetise their content.
                      </div>
                    </div>
                    <div className="shareIcons">
                      <div className="shareText">Share via</div>
                      <ul>
                        <li>
                          <WhatsappShareButton url="http://e-box.co.in/evangelist/">
                            <Image src="/assets/images/evangelist/icons/whatsapp-icon.svg" />
                          </WhatsappShareButton>
                        </li>
                        <li>
                          <FacebookShareButton url="https://www.facebook.com/sharer/sharer.php?u=http://e-box.co.in/evangelist/">
                            <Image src="/assets/images/evangelist/icons/fb.svg" />
                          </FacebookShareButton>
                        </li>
                        <li>
                          <TwitterShareButton url="http://e-box.co.in/evangelist">
                            <Image src="/assets/images/evangelist/icons/twitter.svg" />
                          </TwitterShareButton>
                        </li>
                        <li>
                          <LinkedinShareButton url="http://e-box.co.in/evangelist">
                            <Image src="/assets/images/evangelist/icons/linkedin.svg" />
                          </LinkedinShareButton>
                        </li>
                      </ul>
                    </div>
                    <div className="copyIcon">
                      <div className="orpart">Or</div>
                      <div className="sharepart">
                        <div className="shareText">Copy Link</div>
                        <ul>
                          <li aria-hidden title="Copy to clipboard" onClick={this.Copytext}>
                            <Image src="/assets/images/evangelist/icons/url.svg" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clr20" />
              </div>
            </div>
          </section>

          <div className="bgWhite">
            <QueriesCall />
          </div>

          <Footer />

          <Toast onClose={this.toastClose} show={toastShow} delay={4000} autohide>
            <Toast.Header>
              <strong className="mr-auto">URL Copied to Clipboard</strong>
            </Toast.Header>
          </Toast>
        </main>
      </DocumentMeta>
    );
  }
}
export default EvangelistHome;
