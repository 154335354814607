import React, { Component } from 'react';
// import PlacementForm from './PlacementForm';
import { Card } from 'react-bootstrap';

class PlacementBanner extends Component {
  enrollnow = () => {
    const enrollment = document.querySelector('#enrollPacks');
    window.scrollTo({
      top: enrollment.scrollIntoView(),
      behavior: 'smooth',
    });
  };

  render = () => {
    const { batchLoading } = this.props;
    return (
      <>
        {window.innerWidth >= 991 ? (
          <section className="pgmlistsection commonsection bgWhite">
            <div className="container">
              <div className="row">
                <div className="col-md-12 bannerText col-lg-5">
                  <div className="sectionTitle">
                    Enroll in our comprehensive corporate ready program
                  </div>
                  <div className="clr20" />
                  <div className="enroll-text">
                    New batches starts on every Monday and Thursday
                  </div>
                  <div className="clr" />
                  {
                    !batchLoading
                      ? (
                        <div
                          aria-hidden="true"
                          className="btn btn_primary"
                          onClick={this.enrollnow}
                        >
                        Enroll Now
                        </div>
                      )
                      : ''
                  }
                </div>
                {/* <div className="col-md-12 col-lg-7" id="placement-form">
            {
              (window.innerWidth >= 991) ? <PlacementForm /> : ''
            }
          </div> */}
              </div>
            </div>
          </section>
        ) : (
          <section className="pgmlistsection commonsection">
            <div className="container">
              <div className="row">
                <div className="col-md-12 bannerText col-lg-5">
                  <Card className="cardplacement">
                    <Card.Body>
                      <div className="sectionTitlemobile">
                          Enroll in our comprehensive corporate ready program
                          and crack placement tests with confidence
                      </div>
                      <div className="sectionparamobile">
                          New batches starts on every Monday and Thursday
                      </div>
                      <div className="button_load">
                        <a
                          href="tel:91 93459 40387"
                          aria-hidden="true"
                          className="btn btn_primary call"
                        >
                          <span className="spiriteicon phonecallicon" />
                          93459 40387
                        </a>
                        <div
                          aria-hidden="true"
                          className="btn btn_primary courseshow"
                          onClick={this.enrollnow}
                        >
                            Enroll Now
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                  <div className="height50" />
                </div>
                <div className="col-md-12 col-lg-7" id="requestForm">
                  {/* <EngineeringForm /> */}
                </div>
              </div>
            </div>
          </section>
        )}
      </>
    );
  }
}

export default PlacementBanner;
