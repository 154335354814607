import React, { Component } from 'react';
import { Table } from 'react-bootstrap';

class NeetEligiblityContent extends Component {
  render = () => {
    const { handleEligibilityModal } = this.props;
    return (
      <div className="contentWrapper">
        <div className="modalHeadWrapper">
          <div className="headWrap">
            <p className="ModalHeadText">National Eligibility Cum Entrance test (UG)</p>
          </div>
          <img
            src="/assets/images/neet/icons/cancel.svg"
            alt="translate"
            className="closeIcon"
            onClickCapture={handleEligibilityModal}
          />
        </div>
        <div className="aboutWrapper">
          <div className="aboutTextWrap">
            <p className="aboutHead">01. About NEET Exam</p>
            <p className="aboutContent">NATIONAL ELIGIBILITY CUM ENTRANCE TEST (UG) is conducted by National Testing Agency (NTA) for admission to MBBS/BDS Courses and other undergraduate medical courses in approved/recognized Medical/Dental & other Colleges/ Institutes in India.</p>
          </div>
          <div className="yellowDivWrap">
            <p className="colorDivHead">NEET UG Exam date</p>
            <p className="colorDivText">01.08.2021</p>
          </div>
          <div className="blueDivWrap">
            <p className="colorDivHead">Application form date</p>
            <p className="colorDivText">To be announced</p>
          </div>
        </div>
        <div className="criteriaWrapper">
          <div className="criteriaTextWrap">
            <p className="aboutHead">02. Eligibility Criteria</p>
            <div className="eligibilityWrapper">
              <div className="ageLimitWrap">
                <p className="ageHeadText">Age limit for Candidates</p>
                <div className="ageCardWrap">
                  <div className="ageCard">
                    <p className="ageCriteria">{'> 17 years'}</p>
                    <p className="ageDescText">The lower age limit for candidates aspiring for NEET 2021 should be 17 years and born on or before December 31, 2003</p>
                  </div>
                  <div className="ageCard">
                    <p className="ageCriteria">{'< 25 years'}</p>
                    <p className="ageDescText firstEnd">The upper age limit for General Category is 25 years</p>
                    <p className="ageCriteria secHead">{'< 30 years'}</p>
                    <p className="ageDescText">The upper age limit for Reserved Category and PwD is 30 years.</p>
                  </div>
                </div>
              </div>
              <div className="ageLimitWrap">
                <p className="ageHeadText">Required Percentage of Marks</p>
                <div className="ageCardWrap">
                  <div className="ageCard secondCard">
                    <Table responsive="md" className="markTable">
                      <thead>
                        <tr>
                          <th><p className="tableHeadText">category</p></th>
                          <th><p className="tableHeadText">Percentage</p></th>
                          <th><p className="tableHeadText">Physically challenged</p></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><p className="tableDescText">General candidates</p></td>
                          <td><p className="percentText">50%</p></td>
                          <td><p className="percentText">45%</p></td>
                        </tr>
                        <tr>
                          <td><p className="tableDescText">Scheduled Caste/Scheduled Tribe/ Other Backward Classes</p></td>
                          <td><p className="percentText">40%</p></td>
                          <td><p className="percentText">40%</p></td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="examPatternWrap">
          <div className="criteriaTextWrap">
            <div className="eligibilityWrapper">
              <div className="ageLimitWrap">
                <p className="aboutHeadExam">03. Exam Pattern</p>
                <div className="examCardWrap">
                  <div className="patternWrapper">
                    <div className="translateIcon">
                      <img src="/assets/images/neet/icons/translate.svg" alt="translate" />
                    </div>
                    <div className="descTextWrap">
                      <p className="examPatternHead">Language</p>
                      <p className="examPatternText">Question paper will be available in 11 languages.</p>
                    </div>
                  </div>
                  <div className="patternWrapper">
                    <div className="questionIcon">
                      <img src="/assets/images/neet/icons/questions.svg" className="examImg" alt="translate" />
                    </div>
                    <div className="descTextWrap">
                      <p className="examPatternHead">Questions</p>
                      <p className="examPatternText">Consists of 180 questions divided into Physics, Chemistry and Biology section. 3 hrs in offline exam mode.</p>
                    </div>
                  </div>
                  <div className="patternWrapper">
                    <div className="examIcon">
                      <img src="/assets/images/neet/icons/exam.svg" alt="translate" />
                    </div>
                    <div className="descTextWrap">
                      <p className="examPatternHead">Marks</p>
                      <p className="examPatternText">Each question carries 4 marks and -1 negative marking for each wrong answer.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ageLimitWrap">
                <p className="ageHeadText1">Required Percentage of Marks</p>
                <div className="ageCardWrap">
                  <div className="ageCard secondCard">
                    <Table responsive="md" className="markTable">
                      <thead>
                        <tr>
                          <th><p className="tableHeadText">subject</p></th>
                          <th><p className="tableHeadText">No. of. Questions</p></th>
                          <th><p className="tableHeadText">Total Marks</p></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><p className="tableDescText">Biology</p></td>
                          <td><p className="percentText">90</p></td>
                          <td><p className="percentText">360</p></td>
                        </tr>
                        <tr>
                          <td><p className="tableDescText">Chemistry</p></td>
                          <td><p className="percentText">45</p></td>
                          <td><p className="percentText">180</p></td>
                        </tr>
                        <tr>
                          <td><p className="tableDescText">Physics</p></td>
                          <td><p className="percentText">45</p></td>
                          <td><p className="percentText">180</p></td>
                        </tr>
                        <tr>
                          <td><p className="tableDescText">Total</p></td>
                          <td><p className="percentText">180</p></td>
                          <td><p className="percentText">720</p></td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="criteriaWrapper">
          <div className="criteriaTextWrap">
            <p className="aboutHead">04. How to apply for NEET</p>
            <div className="stepsDivWrap">
              <div className="stepWrapper">
                <div className="stepCircle1">
                  <p className="stepText">STEP 1</p>
                </div>
                <div className="stepCardWrap">
                  <p className="stepHead">Log in</p>
                  <p className="stepDescText">Log into official NTA website (<span className="pinkDescText">www.ntaneet.nic.in</span>)</p>
                </div>
              </div>
              <div className="stepWrapper">
                <div className="stepCircle">
                  <p className="stepText">STEP 2</p>
                </div>
                <div className="stepCardWrap">
                  <p className="stepHead">Upload your documents</p>
                  <div className="pointWrapper">
                    <div className="pointDiv" />
                    <div>
                      <p className="stepDescText">Passport size photo</p>
                      <p className="greyDecText">JPG/JPEG<p className="greyDot" /><span className="greyDecText">10kb to 200kb</span></p>
                    </div>
                  </div>
                  <div className="pointWrapper">
                    <div className="pointDiv" />
                    <div>
                      <p className="stepDescText">Passport size photo</p>
                      <p className="greyDecText">JPG/JPEG<p className="greyDot" /><span className="greyDecText">10kb to 200kb</span></p>
                    </div>
                  </div>
                  <div className="pointWrapper">
                    <div className="pointDiv" />
                    <div>
                      <p className="stepDescText">Signature photo</p>
                      <p className="greyDecText">JPG/JPEG<p className="greyDot" /><span className="greyDecText">4kb to 30kb</span></p>
                    </div>
                  </div>
                  <div className="pointWrapper">
                    <div className="pointDiv" />
                    <div>
                      <p className="stepDescText">Left hand thumb impression</p>
                      <p className="greyDecText">JPG/JPEG<p className="greyDot" /><span className="greyDecText">10kb to 50kb</span></p>
                    </div>
                  </div>
                  <div className="pointWrapper">
                    <div className="pointDiv" />
                    <div>
                      <p className="stepDescText">Class 10th certificate</p>
                      <p className="greyDecText">JPG/JPEG<p className="greyDot" /><span className="greyDecText">100kb to 300kb</span></p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="stepWrapper">
                <div className="stepCircle">
                  <p className="stepText">STEP 3</p>
                </div>
                <div className="stepCardWrap">
                  <p className="stepHead">Pay Application fees</p>
                  <p className="stepDescText">Fees Structure :</p>
                  <p className="stepDescText">General (UR) – <span className="stepMoney">Rs.1500</span></p>
                  <p className="stepDescText">General -EWS/OBC(NCL) – <span className="stepMoney">Rs.1400</span></p>
                  <p className="stepDescText">SC/ST/PwD/Transgender – <span className="stepMoney">Rs.800</span></p>
                  <p className="stepDescText mrTop">Payment methods :</p>
                  <div className="pointWrapper">
                    <div className="pointDiv" />
                    <div>
                      <p className="stepDescText">Credit card/ Debit card</p>
                    </div>
                  </div>
                  <div className="pointWrapper">
                    <div className="pointDiv" />
                    <div>
                      <p className="stepDescText">Net Banking</p>
                    </div>
                  </div>
                  <div className="pointWrapper">
                    <div className="pointDiv" />
                    <div>
                      <p className="stepDescText">UPI</p>
                    </div>
                  </div>
                  <div className="pointWrapper">
                    <div className="pointDiv" />
                    <div>
                      <p className="stepDescText">PAYTM</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="stepWrapper">
                <div className="stepCircle">
                  <p className="stepText">STEP 4</p>
                </div>
                <div className="stepCardWrap">
                  <p className="stepHead">Submit the Application</p>
                  <p className="stepDescText padBot">A confirmation message is sent to your email address and mobile number after final submission.</p>
                  <p className="stepDescText padTop">Candidates can access their respective admit cards by entering their registration number, birth date and security pin. It is mandatory to carry the admit card to the exam centre along with ID proof for verification.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default NeetEligiblityContent;
