import React, { Component } from 'react';
import DefaultLoader from 'globals/components/loader/DefaultLoader';
import { contactNumber } from 'globals/utils/constants';
import PlacementGoldPack from './Packs/PlacementGoldPack';
import PlacementDiamondPack from './Packs/PlacementDiamondPack';
import PlacementPlatinumPack from './Packs/PlacementPlatinumPack';

class PlacementTraining extends Component {
  selectBatch = (batchId) => {
    const { batchSelect } = this.props;
    batchSelect(batchId);
  }

  render = () => {
    const {
      goldRespData, diamondRespData, platinumRespData, batchLoading,
    } = this.props;
    return (
      <section className="commonsection placementsection">
        <div className="container">
          <div id="courseOffered" className="row">
            <div className="col-sm-12">
              <div className="sectionTitleMedium text-center">Corporate ready program plans we offer</div>
              <div className="clr" />

              {
                !batchLoading
                  ? (
                    <div className="placements-card">
                      <div className="placement-head">
                        <div className="placement-row">
                          <div className="placement-th">
                            <div className="placementTextBlock">
                              <div className="placementText">Not Sure, Which plan to choose ?</div>
                              <a href={`tel:${contactNumber}`} className="btn btn_primary"><span>Call us on {contactNumber}</span></a>
                            </div>

                          </div>
                          <div className="placement-th">
                            <PlacementGoldPack goldRespData={goldRespData} />
                            <div className="btn-pack">
                              <div aria-hidden className={goldRespData.batches.length !== 0 ? 'btn btn_primary_outer' : 'btn btn_primary modalbutton disableSelectBatch'} onClick={() => this.selectBatch(61)}><span>Select a Batch</span></div>
                            </div>
                          </div>
                          <div className="placement-th">
                            <PlacementDiamondPack diamondRespData={diamondRespData} />
                            <div className="btn-pack">
                              <div aria-hidden className={diamondRespData.batches.length !== 0 ? 'btn btn_primary_outer' : 'btn btn_primary modalbutton disableSelectBatch'} onClick={() => this.selectBatch(64)}><span>Select a Batch</span></div>
                            </div>
                          </div>
                          <div className="placement-th">
                            <PlacementPlatinumPack platinumRespData={platinumRespData} />
                            <div className="btn-pack">
                              <div aria-hidden className={platinumRespData.batches.length !== 0 ? 'btn btn_primary_outer' : 'btn btn_primary modalbutton disableSelectBatch'} onClick={() => this.selectBatch(65)}><span>Select a Batch</span></div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="placement-body panel-group">
                        <div className="courseOffer">Courses Offered</div>
                        <div className="placement-row">
                          <div className="placement-td">
                            <span>Quantitative Aptitude</span>
                          </div>
                          <div className="placement-td">
                            <div className="tickRound" />
                          </div>
                          <div className="placement-td diamond-column">
                            <div className="tickRound" />
                          </div>
                          <div className="placement-td">
                            <div className="tickRound" />
                          </div>
                        </div>
                        <div className="placement-row">
                          <div className="placement-td">
                            <span>Problem Solving and Programming</span>
                          </div>
                          <div className="placement-td ">
                            <div className="tickRound" />
                          </div>
                          <div className="placement-td diamond-column">
                            <div className="tickRound" />
                          </div>
                          <div className="placement-td">
                            <div className="tickRound" />
                          </div>
                        </div>
                        <div className="placement-row">
                          <div className="placement-td">
                            <span>Data Structures and its applications</span>
                          </div>
                          <div className="placement-td ">
                            <div className="tickRound" />
                          </div>
                          <div className="placement-td diamond-column">
                            <div className="tickRound" />
                          </div>
                          <div className="placement-td">
                            <div className="tickRound" />
                          </div>
                        </div>
                        <div className="placement-row">
                          <div className="placement-td">
                            <span>Design and Analysis of Algorithms</span>
                          </div>
                          <div className="placement-td">
                            <div className="tickRound" />
                          </div>
                          <div className="placement-td diamond-column">
                            <div className="tickRound" />
                          </div>
                          <div className="placement-td">
                            <div className="tickRound" />
                          </div>
                        </div>
                        <div className="placement-row">
                          <div className="placement-td">
                            <span>TCS Ninja Practice Course</span>
                          </div>
                          <div className="placement-td">
                            <div className="tickRound" />
                          </div>
                          <div className="placement-td diamond-column">
                            <div className="tickRound" />
                          </div>
                          <div className="placement-td">
                            <div className="tickRound" />
                          </div>
                        </div>
                        <div className="placement-row">
                          <div className="placement-td">
                            <span>Complex Problem Solving and Algorithmic Thinking</span>
                          </div>
                          <div className="placement-td" />
                          <div className="placement-td diamond-column">
                            <div className="tickRound" />
                          </div>
                          <div className="placement-td">
                            <div className="tickRound" />
                          </div>
                        </div>
                        <div className="placement-row">
                          <div className="placement-td">
                            <span>Object Oriented Programming using Java</span>
                          </div>
                          <div className="placement-td" />
                          <div className="placement-td diamond-column">
                            <div className="tickRound" />
                          </div>
                          <div className="placement-td">
                            <div className="tickRound" />
                          </div>
                        </div>
                        <div className="placement-row">
                          <div className="placement-td">
                            <span>MySQL Developer Course</span>
                          </div>
                          <div className="placement-td" />
                          <div className="placement-td diamond-column">
                            <div className="tickRound" />
                          </div>
                          <div className="placement-td">
                            <div className="tickRound" />
                          </div>
                        </div>
                        <div className="placement-row">
                          <div className="placement-td">
                            <span>InfyTQ Practice Course</span>
                          </div>
                          <div className="placement-td" />
                          <div className="placement-td diamond-column">
                            <div className="tickRound" />
                          </div>
                          <div className="placement-td">
                            <div className="tickRound" />
                          </div>
                        </div>
                        <div className="placement-row">
                          <div className="placement-td">
                            <span>TCS Code Vita Practice Course</span>
                          </div>
                          <div className="placement-td" />
                          <div className="placement-td diamond-column">
                            <div className="tickRound" />
                          </div>
                          <div className="placement-td">
                            <div className="tickRound" />
                          </div>
                        </div>
                        <div className="placement-row">
                          <div className="placement-td">
                            <span>UI Development</span>
                          </div>
                          <div className="placement-td" />
                          <div className="placement-td diamond-column" />
                          <div className="placement-td">
                            <div className="tickRound" />
                          </div>
                        </div>
                        <div className="placement-row">
                          <div className="placement-td">
                            <span>Dynamic Web Application Development</span>
                          </div>
                          <div className="placement-td" />
                          <div className="placement-td diamond-column" />
                          <div className="placement-td">
                            <div className="tickRound" />
                          </div>
                        </div>

                      </div>
                    </div>
                  )
                  : <DefaultLoader />
              }
            </div>
            <div className="clr" />

          </div>
        </div>
      </section>
    );
  }
}

export default PlacementTraining;
