import React, {
  useEffect, useRef, useState,
} from 'react';
import {
  Image, Menu, Segment, TransitionablePortal,
} from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
// import { withTranslation } from 'react-i18next';
import { mediaBreakpoint } from 'globals/utils/globalVariables';
import { prourl } from 'globals/utils/constants';
import UserInfo from 'models/UserInfo';
import { Nav } from 'react-bootstrap';
import logo from './assets/eboxlogo.webp';
import barOpen from './assets/barOpen.svg';
import barClose from './assets/barClose.svg';
import style from './header.module.scss';

const Header = () => {
  const history = useHistory();
  const [activeItem, setActiveItem] = useState(
    window.location.href.split('/').pop(),
  );
  const headerRef = useRef();
  const [open, setOpen] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);

  const menuList = [
    'home',
    'about',
    // 'ebox',
    'product',
    'services',
    // 'socialConnect',
    // 'howWeWork',
    'overseasStudies',
    'digital',
    'programs',
    // "blog",
    'e-boxAcademy',
    'e-boxAwards',
  ];

  const controlNavbar = () => {
    if (typeof window !== 'undefined') {
      if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        headerRef.current.style.top = '-50%';
      } else {
        // if scroll up show the navbar
        headerRef.current.style.top = '0';
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (window.location.href.split('/').pop().length === 0) {
      setActiveItem('home');
    } else {
      setActiveItem(window.location.href.split('/').pop());
    }
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (
      typeof window !== 'undefined'
      && window.innerWidth > mediaBreakpoint.mobile
    ) {
      window.addEventListener('scroll', controlNavbar);

      // cleanup function
      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastScrollY]);

  const handleItemClick = (value) => {
    console.log(value);
    setActiveItem(value);
    setOpen(false);
    history.push(`/${value}`);
  };
  const formatString = (input) => input.replace(/([a-z])([A-Z])/g, '$1 $2');
  const renderMenu = () => (
    <>
      {React.Children.toArray(
              menuList?.map((menuName) => (
                <>
                  <Menu.Item
                    className={style.menuName}
                    name={(formatString(menuName.replaceAll(' ', '')))}
                    color={activeItem === menuName ? 'purple' : 'black'}
                    active={activeItem === menuName}
                    onClick={() => handleItemClick(menuName.replaceAll(' ', ''))}
                  />
                </>
              )),
      )}
    </>
  );

  const renderNavBtns = () => {
    const navBtns = (UserInfo.isAuth() === true) ? (
      <Nav.Link className={style.primaryBtn} href={`${prourl}/`}>Dashboard</Nav.Link>
    )
      : (
        <>
          <Nav.Link className={style.primaryBtn} href={`${prourl}`}>
            Login / Register
          </Nav.Link>
        </>
      );
    return navBtns;
  };

  const renderResponsiveMenuSection = () => (
    <>
      <Menu className={style.menu} fluid vertical>
        {renderMenu()}
      </Menu>
      <div className={style.mobNavBtns}>
        {renderNavBtns()}
      </div>
    </>
  );

  const isResponsive = window.innerWidth < mediaBreakpoint.mobile;
  return (
    <>
      <div ref={headerRef} className={style.headerWrapper}>
        <div className={style.header}>
          <div className={style.iocHeaderLogoWrapper}>
            <Image className={style.iocHeaderLogo} src={logo} />
          </div>
          <Menu className={style.headerMenuWrapper} pointing secondary>
            {React.Children.toArray(
              menuList?.map((menuName) => (
                <>
                  <Menu.Item
                    className={style.menuName}
                    color={activeItem === menuName ? 'purple' : 'black'}
                    active={activeItem === menuName}
                    onClick={() => handleItemClick(menuName.replaceAll(' ', ''))}
                  >
                    {formatString(menuName)}
                  </Menu.Item>
                </>
              )),
            )}
          </Menu>
          <div className={style.signupBtnWrapper}>
            {renderNavBtns()}
          </div>
          <div className={open ? style.barIconClose : style.barIconOpen}>
            {!open ? (
              <>
                <img
                  onClickCapture={() => setOpen(!open)}
                  src={barOpen}
                  alt="barOpen"
                />
              </>
            ) : (
              <>
                <img
                  onClickCapture={() => setOpen(!open)}
                  src={barClose}
                  alt="barClose"
                />
              </>
            )}
          </div>
          <TransitionablePortal
            onClose={() => setOpen(false)}
            open={open}
          >
            <Segment className={style.menuWrapper}>
              {renderResponsiveMenuSection()}
            </Segment>
          </TransitionablePortal>
        </div>
        {isResponsive && <div className={style.divider} />}
      </div>
    </>
  );
};

// export default withTranslation('common')(Header);
export default Header;
