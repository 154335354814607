import React, { Component } from 'react';
import 'globals/components/scss/CommonComponent.scss';
import {
  Container, Row, Col, Image,
} from 'react-bootstrap';
import Slider from 'react-slick';
import DefaultLoader from 'globals/components/loader/DefaultLoader';
import testimonialURL from './NeetLearnerTestimonial.json';

class NeetLearnerTestimonial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      testimonialData: '',
    };
  }

  componentDidMount = () => {
    this.fetchTestimonial();
  }

  fetchTestimonial = () => {
    this.setState({
      loading: false, testimonialData: testimonialURL,
    });
  }

  render = () => {
    const { loading, testimonialData } = this.state;
    const settings = {
      className: '',
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      autoplay: true,
      autoplaySpeed: 5000,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <section className="commonsection learnerTestimonial neetLearner">
        <Container>
          <Row>
            <Col sm={12}>
              <div className="sectionTitleMedium">
                What do our Learners Say about E-Box
              </div>
            </Col>
            <br/>
            <br/>
            <Col sm={12}>
              <div className="custom-arrows bottom-arrows">
                <Slider {...settings}>
                  {
                    (!loading)
                      ? testimonialData.map((element) => (
                        <div className="learnerCard" key={element.Person}>
                          <div className="learnerDesc">
                            <div className="learnerImage">
                              <Image src={`/assets/images/neet/testimonials/${element.photo}.jpg`} alt={element.person} />
                              <div className="name-cmp">
                                <p>{element.person}</p>
                                <span>{element.place}</span>
                              </div>
                            </div>
                            <div className="label">
                              {element.testimonial}
                            </div>
                          </div>
                        </div>
                      ))
                      : <DefaultLoader />
                  }
                </Slider>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default NeetLearnerTestimonial;
