import React, { Component } from 'react';

class GetPlacementPlatinumPack extends Component {
  render = () => (
    <>
      <div className="tagtext1">Recommened</div>
      <div className="placement-item1 platinum-block">
        <div className="packName1">Product and Service Companies</div>
        <ul className="packUl1">
          <li>
            <div className="packIcon arrow" />
            <span>60</span>
            <p>Practice Assessments</p>
          </li>
          <li>
            <div className="packIcon home" />
            <span>12</span>
            <p>Diff. Company Assessments</p>
          </li>
        </ul>
        <div className="placement-middle">
          <span>&#8377;</span> 3,000
        </div>
      </div>
    </>
  );
}

export default GetPlacementPlatinumPack;
