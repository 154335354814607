import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import PlacementGoldPack from './Packs/GetPlacementGoldPack';
import PlacementDiamondPack from './Packs/GetPlacementDiamondPack';
import PlacementPlatinumPack from './Packs/GetPlacementPlatinumPack';

class GetPlacementTrainingCarsouel extends Component {
  selectBatch = (batchId) => {
    const { batchSelect } = this.props;
    batchSelect(batchId);
  }

  viewSyllabus = (syllabusType) => {
    const { syllabus } = this.props;
    syllabus(syllabusType);
  }

  render = () => (
    <section className="commonsection placementsection bgWhite">
      <div className="container">
        <div className="sectionTitleMedium text-center">Bundled Practice Assessments</div>
        <div className="clr20" />
        <div className="packNavs">
          <Tabs defaultActiveKey="combo">
            <Tab eventKey="services" title="Service Companies">
              <PlacementGoldPack />
              <div className="limelink1">
                <span className="a_link" aria-hidden="true" onClick={() => this.viewSyllabus('gold')}>View Syllabus</span>
              </div>
              <div className="packBtn">
                <div className="rateText">&#8377; 1,500</div>
                <a aria-hidden href="tel:91 90034 69198" className="btn btn_primary_outer contactbutton"><span>CONTACT US</span></a>
              </div>
            </Tab>
            <Tab eventKey="product" title="Product Companies">
              <PlacementDiamondPack />
              <div className="limelink1">
                <span className="a_link" aria-hidden="true" onClick={() => this.viewSyllabus('diamond')}>View Syllabus</span>
              </div>
              <div className="packBtn">
                <div className="rateText">&#8377; 2,500</div>
                <a aria-hidden href="tel:91 90034 69198" className="btn btn_primary_outer contactbutton"><span>CONTACT US</span></a>
              </div>
            </Tab>
            <Tab eventKey="combo" title="Product and Service Companies">
              <PlacementPlatinumPack />
              <div className="limelink1">
                <span className="a_link" aria-hidden="true" onClick={() => this.viewSyllabus('platinum')}>View Syllabus</span>
              </div>
              <div className="packBtn">
                <div className="rateText">&#8377; 3000</div>
                <a aria-hidden href="tel:91 90034 69198" className="btn btn_primary_outer contactbutton"><span>CONTACT US</span></a>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </section>
  )
}

export default GetPlacementTrainingCarsouel;
