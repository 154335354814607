import React, { Component } from 'react';
import { Card } from 'react-bootstrap';

class NeetModes extends Component {
  render = () => (
    <>
      {window.innerWidth >= 768 ? (
        <section className="commonsection bgWhite">
          <div className="container">
            <div className="row learning_modes">
              <div className="col-sm-12">
                <div className="sectionTitleMedium text-center">
                  Different Learning Modes in E-Box
                </div>
              </div>
            </div>
            <div className="row cardRow">
              {/* CARD-1 */}
              <div className="col-sm-12 col-md-4 col-lg-3 border-card">
                <div className="highlightCard">
                  <div className="h_icon">
                    <div className="learnSprite neet1" />
                  </div>
                  <div className="h_title">Concept Driven Learning</div>
                </div>
              </div>

              {/* CARD-2 */}
              <div className="col-sm-12 col-md-4 col-lg-3 border-card">
                <div className="highlightCard">
                  <div className="h_icon">
                    <div className="learnSprite coding-1" />
                  </div>
                  <div className="h_title">Application Oriented Learning</div>
                </div>
              </div>

              {/* CARD-3 */}
              <div className="col-sm-12 col-md-4 col-lg-3 border-card">
                <div className="highlightCard">
                  <div className="h_icon">
                    <div className="learnSprite highlight10" />
                  </div>
                  <div className="h_title">Mobile Learning</div>
                </div>
              </div>

              {/* CARD-4 */}
              <div className="col-sm-12 col-md-4 col-lg-3 border-card">
                <div className="highlightCard">
                  <div className="h_icon">
                    <div className="learnSprite neet3" />
                  </div>
                  <div className="h_title">Competitive Learning</div>
                </div>
              </div>

              {/* CARD-5 */}
              <div className="col-sm-12 col-md-4 col-lg-3 border-card">
                <div className="highlightCard">
                  <div className="h_icon">
                    <div className="learnSprite neet4" />
                  </div>
                  <div className="h_title">Unstructured Learning</div>
                </div>
              </div>

              {/* CARD-6 */}
              <div className="col-sm-12 col-md-4 col-lg-3 border-card">
                <div className="highlightCard">
                  <div className="h_icon">
                    <div className="learnSprite neet2" />
                  </div>
                  <div className="h_title">Scenario Based Learning</div>
                </div>
              </div>

              {/* CARD-7 */}
              <div className="col-sm-12 col-md-4 col-lg-3 border-card">
                <div className="highlightCard">
                  <div className="h_icon">
                    <div className="learnSprite neet5" />
                  </div>
                  <div className="h_title">Micro Learning</div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="commonsection bgWhite">
          {/* <div className="container"> */}
          <div className="row learning_modes">
            <div className="col-sm-12">
              <div className="sectionTitleMedium text-center">
                  Different Learning Modes in E-Box
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <Card className="highlightCardmobileneet">
              <Card.Body className="cardbodyneet">
                <div className="highlightCardneet">
                  <div className="h_iconneet">
                    <div className="learnSprite neet1" />
                  </div>
                  <div className="h_title">Concept Driven Learning</div>
                </div>
              </Card.Body>
            </Card>

            <Card className="highlightCardmobileneet">
              <Card.Body className="cardbodyneet">
                <div className="highlightCardneet">
                  <div className="h_iconneet">
                    <div className="learnSprite coding-1" />
                  </div>
                  <div className="h_title">Application Oriented Learning</div>
                </div>
              </Card.Body>
            </Card>

            <Card className="highlightCardmobileneet">
              <Card.Body className="cardbodyneet">
                <div className="highlightCardneet">
                  <div className="h_iconneet">
                    <div className="learnSprite highlight10" />
                  </div>
                  <div className="h_title">Mobile Learning</div>
                </div>
              </Card.Body>
            </Card>

            <Card className="highlightCardmobileneet">
              <Card.Body className="cardbodyneet">
                <div className="highlightCardneet">
                  <div className="h_iconneet">
                    <div className="learnSprite neet3" />
                  </div>
                  <div className="h_title">Competitive Learning</div>
                </div>
              </Card.Body>
            </Card>

            <Card className="highlightCardmobileneet">
              <Card.Body className="cardbodyneet">
                <div className="highlightCardneet">
                  <div className="h_iconneet">
                    <div className="learnSprite neet4" />
                  </div>
                  <div className="h_title">Unstructured Learning</div>
                </div>
              </Card.Body>
            </Card>

            <Card className="highlightCardmobileneet">
              <Card.Body className="cardbodyneet">
                <div className="highlightCardneet">
                  <div className="h_iconneet">
                    <div className="learnSprite neet2" />
                  </div>
                  <div className="h_title">Scenario Based Learning</div>
                </div>
              </Card.Body>
            </Card>

            <Card className="highlightCardmobileneet">
              <Card.Body className="cardbodyneet">
                <div className="highlightCardneet">
                  <div className="h_iconneet">
                    <div className="learnSprite neet5" />
                  </div>
                  <div className="h_title">Micro Learning</div>
                </div>
              </Card.Body>
            </Card>
          </div>
          {/* </div> */}
        </section>
      )}
    </>
  );
}
export default NeetModes;
