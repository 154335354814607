import React, { Component } from 'react';
import '../../globals/components/Cards/cards.scss';
import { Image } from 'react-bootstrap';

class SchoolProgramCard extends Component {
  render = () => {
    const { element } = this.props;
    return (
      <a className="programCard" href={element.link}>
        <div className="programImage">
          <Image src={`/assets/images/programs/${element.photo}`} alt="User" />
        </div>
        <div className="pgmSet">
          <div className="pgmTitle">{element.title}</div>
          <p>{element.description}</p>
        </div>
      </a>
    );
  }
}

export default SchoolProgramCard;
