import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Slider from 'react-slick';
import FBpixel from 'globals/utils/FBpixel';
import Footer from 'globals/components/Footer';
import LeadForm from 'globals/components/LeadForm';
import HeaderWithoutMenu from 'globals/components/HeaderWithoutMenu';
import AZCompanyList from 'globals/components/AZCompanyList';
import StepPracticeLearning from 'globals/components/StepPracticeLearning';
import GlobalService from 'services/GlobalService';
import { resturls } from 'globals/utils/apiurls';
import { Helmet } from 'react-helmet';
import LearnerTestimonial from 'globals/components/LearnerTestimonial';
import HighlightsProgram from 'screens/EngineeringTuitions/EnggTuitionHighlightProgram';
import DefaultLoader from 'globals/components/loader/DefaultLoader';
import PlacementBanner from './PlacementBanner';
import PlacementTraining from './PlacementTraining';
import PlacementTrainingCarsouel from './PlacementTrainingCarsouel';
import PlacementLearnersTestimonial from './PlacementLearnersTestimonial';
import PlacementFAQ from './PlacementFAQ';
import './scss/placement.scss';
import './scss/PlacementResponsive.scss';
// import PlacementGoldPack from './Packs/PlacementGoldPack';
// import PlacementDiamondPack from './Packs/PlacementDiamondPack';
// import PlacementPlatinumPack from './Packs/PlacementPlatinumPack';

class PlacementMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // subjectData: '',
      respdata: '',
      batchModalShow: false,
      formModalShow: false,
      syllabusModal: false,
      syllabusList: [],
      syllabusTitle: '',
      batchLoading: true,
      goldBatches: {},
      diamondBatches: {},
      platinumBatches: {},
      timeIncrement: 30000,
      delayMultipleCount: 30000,
    };
    this.enablePixel();
  }

  componentDidMount() {
    const { delayMultipleCount } = this.state;
    this.fetchBatches();
    sessionStorage.modalOpened = false;
    if (sessionStorage.getItem('modalOpened') === 'false') this.showFormModal(delayMultipleCount);
  }

  showFormModal = (delayMultipleCount) => {
    setTimeout(() => {
      if (sessionStorage.getItem('modalOpened') === 'false') {
        this.fetchFormModal();
        this.focusTextBox();
      }
    }, delayMultipleCount);
  }

  fetchBatches = () => {
    this.selectGoldBatch(61);
  }

  focusTextBox = () => {
    const focusName = document.getElementsByName('name')[0];
    focusName.focus();
  };

  onBrowserTabFocus = () => {
    this.setState({ formModalShow: true });
    this.focusTextBox();
  };

  enablePixel = () => {
    FBpixel.trackCustom('ViewContent', {
      content_ids: [{ 'Page Name': 'Corporate ready program' }],
    });
  };

  selectBatch = (batchId) => {
    GlobalService.generalSelect(
      (respdata) => {
        this.setState({
          batchModalShow: true,
          formModalShow: false,
          respdata,
        });
      },
      `${resturls.tutoringPlanDetailsUrl}?id=${batchId}`,
      '',
      'GET',
      'app',
    );
    sessionStorage.setItem('modalOpened', true);
  };

  selectGoldBatch = (batchId) => {
    GlobalService.generalSelect(
      (respdata) => {
        this.selectDiamondBatch(respdata, 64);
      },
      `${resturls.tutoringPlanDetailsUrl}?id=${batchId}`,
      '',
      'GET',
      'app',
    );
  };

  selectDiamondBatch = (goldBatches, batchId) => {
    GlobalService.generalSelect(
      (respdata) => {
        this.selectPlatinumBatch(goldBatches, respdata, 65);
      },
      `${resturls.tutoringPlanDetailsUrl}?id=${batchId}`,
      '',
      'GET',
      'app',
    );
  };

  selectPlatinumBatch = (goldBatches, diamondBatches, batchId) => {
    GlobalService.generalSelect(
      (respdata) => {
        this.setState({
          batchLoading: false,
          goldBatches,
          diamondBatches,
          platinumBatches: respdata,
        });
      },
      `${resturls.tutoringPlanDetailsUrl}?id=${batchId}`,
      '',
      'GET',
      'app',
    );
  };

  selectSyllabus = (syllabusType) => {
    if (syllabusType === 'gold') {
      this.setState({
        syllabusModal: true,
        formModalShow: false,
        syllabusTitle: 'Gold',
        syllabusList: [
          'Quantitative Aptitude',
          'Problem Solving and Programming',
          'Data Structures and its applications',
          'Design and Analysis of Algorithms',
          'TCS Ninja Practice Course',
        ],
      });
    }

    if (syllabusType === 'diamond') {
      this.setState({
        syllabusModal: true,
        formModalShow: false,
        syllabusTitle: 'Diamond',
        syllabusList: [
          'Quantitative Aptitude',
          'Problem Solving and Programming',
          'Data Structures and its applications',
          'Design and Analysis of Algorithms',
          'TCS Ninja Practice Course',
          'Complex Problem Solving and Algorithmic Thinking',
          'Object Oriented Programming using Java',
          'MySQL Developer Course',
          'InfyTQ Practice Course',
          'TCS Code Vita Practice Course',
        ],
      });
    }

    if (syllabusType === 'platinum') {
      this.setState({
        syllabusModal: true,
        formModalShow: false,
        syllabusTitle: 'Platinum',
        syllabusList: [
          'Quantitative Aptitude',
          'Problem Solving and Programming',
          'Data Structures and its applications',
          'Design and Analysis of Algorithms',
          'TCS Ninja Practice Course',
          'Complex Problem Solving and Algorithmic Thinking',
          'Object Oriented Programming using Java',
          'MySQL Developer Course',
          'InfyTQ Practice Course',
          'TCS Code Vita Practice Course',
          'UI Development',
          'Dynamic Web Application Development',
        ],
      });
    }
    sessionStorage.setItem('modalOpened', true);
  };

  handleBatchModalClose = () => {
    const { delayMultipleCount, timeIncrement } = this.state;
    this.showFormModal(delayMultipleCount + timeIncrement);
    sessionStorage.setItem('modalOpened', false);
    this.setState({ batchModalShow: false });
  };

  fetchFormModal = () => {
    this.setState({
      formModalShow: true,
    });
  };

  handleFormClose = () => {
    const { delayMultipleCount, timeIncrement } = this.state;
    this.showFormModal(delayMultipleCount + timeIncrement);
    sessionStorage.setItem('modalOpened', false);
    this.setState({
      formModalShow: false,
      delayMultipleCount: delayMultipleCount + timeIncrement,
    });
  }

  handleSyllabusClose = () => {
    const { delayMultipleCount, timeIncrement } = this.state;
    this.showFormModal(delayMultipleCount + timeIncrement);
    sessionStorage.setItem('modalOpened', false);
    this.setState({
      syllabusModal: false,
    });
  }

  // componentWilUnmount() {
  //   window.removeEventListener('focus', this.onBrowserTabFocus);
  // }
  onBrowserTabFocus = () => {
    if (localStorage.getItem('subjectModal') === 'close') {
      this.setState({ formModalShow: true });
      this.focusTextBox();
    }
  };

  enrollnow = () => {
    const enrollment = document.querySelector('#enrollPacks');
    window.scrollTo({
      top: enrollment.scrollIntoView(),
      behavior: 'smooth',
    });
  };

  render = () => {
    const {
      // subjectData,
      batchModalShow,
      respdata,
      formModalShow,
      syllabusModal,
      syllabusList,
      syllabusTitle,
      goldBatches,
      diamondBatches,
      platinumBatches,
      batchLoading,
    } = this.state;
    // let placementPacks = '';
    // if (subjectData !== '') {
    //   if (subjectData.packs === 1) {
    //     placementPacks = <PlacementPlatinumPack platinumRespData={platinumBatches}
    // batchLoading={batchLoading} />;
    //   } else if (subjectData.packs === 2) {
    //     placementPacks = (
    //       <>
    //         <PlacementDiamondPack diamondRespData={diamondBatches} batchLoading={batchLoading} />
    //         <PlacementPlatinumPack platinumRespData={platinumBatches}
    // batchLoading={batchLoading} />
    //       </>
    //     );
    //   } else if (subjectData.packs === 3) {
    //     placementPacks = (
    //       <>
    //         <PlacementGoldPack goldRespData={goldBatches} batchLoading={batchLoading} />
    //         <PlacementDiamondPack diamondRespData={diamondBatches} batchLoading={batchLoading} />
    //         <PlacementPlatinumPack platinumRespData={platinumBatches}
    //  batchLoading={batchLoading} />
    //       </>
    //     );
    //   }
    // }

    return (
      <main className="placementPage">
        <Helmet>
          <title>Corporate ready program</title>
          <link
            rel="canonical"
            href="https://e-box.co.in/corporate-ready/"
          />
          <meta
            name="description"
            content="Enroll in our comprehensive Corporate ready program and crack Placement Tests with confidence."
          />

          <meta name="keywords" content="Corporate ready program" />
          <meta
            property="og:title"
            content="E-Box | Corporate ready program | Programming | Data Structures | Algorithm"
          />
          <meta
            property="og:url"
            content="https://e-box.co.in/corporate-ready/"
          />

          <meta
            itemProp="image"
            content="https://e-box.co.in/sharelogos/placementsharelogo.png"
          />
          <meta
            property="og:image"
            content="https://e-box.co.in/sharelogos/placementsharelogo.png"
          />
          <meta
            name="twitter:image"
            content="https://e-box.co.in/sharelogos/placementsharelogo.png"
          />
          <meta
            name="twitter:image:src"
            content="https://e-box.co.in/sharelogos/placementsharelogo.png"
          />
          <meta
            name="facebook:image"
            content="https://e-box.co.in/sharelogos/placementsharelogo.png"
          />
          <meta
            name="facebook:image:src"
            content="https://e-box.co.in/sharelogos/placementsharelogo.png"
          />
          <meta
            name="linkedin:image"
            content="https://e-box.co.in/sharelogos/placementsharelogo.png"
          />
          <meta
            name="linkedin:image:src"
            content="https://e-box.co.in/sharelogos/placementsharelogo.png"
          />
          <meta
            name="whatsapp:image"
            content="https://e-box.co.in/sharelogos/placementsharelogo.png"
          />
          <meta
            name="whatsapp:image:src"
            content="https://e-box.co.in/sharelogos/placementsharelogo.png"
          />
          <link
            itemProp="thumbnailUrl"
            href="https://e-box.co.in/sharelogos/placementsharelogo.png"
          />
        </Helmet>

        <HeaderWithoutMenu />
        <PlacementBanner batchLoading={batchLoading} />

        {window.innerWidth >= 991 ? <PlacementLearnersTestimonial /> : ''}

        <div className="highlightsSection">
          <HighlightsProgram />
        </div>

        {
          !batchLoading
            ? (
              <div className="placementPacks" id="enrollPacks">
                {window.innerWidth >= 991 ? (
                  <PlacementTraining
                    batchLoading={batchLoading}
                    batchSelect={this.selectBatch}
                    goldRespData={goldBatches}
                    diamondRespData={diamondBatches}
                    platinumRespData={platinumBatches}
                  />
                ) : (
                  <PlacementTrainingCarsouel
                    batchSelect={this.selectBatch}
                    syllabus={this.selectSyllabus}
                    batchLoading={batchLoading}
                    goldRespData={goldBatches}
                    diamondRespData={diamondBatches}
                    platinumRespData={platinumBatches}
                  />
                )}
              </div>
            )
            : ''
        }

        {/* <CorporateTeam /> */}

        <AZCompanyList />
        <LearnerTestimonial />

        <div className="bgWhite">
          <StepPracticeLearning />
        </div>

        <PlacementFAQ />
        <Footer />
        <div>
          <div className="talkbtn">
            <PlacementLearnersTestimonial />
          </div>
          {window.innerWidth >= 991
            ? (
              <div className="callbackbtn button_load">
                <div
                  aria-hidden
                  className="btn btn_primary call"
                  onClick={this.fetchFormModal}
                >
            Request a call back
                </div>
                <div
                  aria-hidden
                  className="btn btn_primary courseshow"
                  onClick={this.enrollnow}
                >
            Enroll Now
                </div>
              </div>
            )
            : (
              <div className="callbackbtn button_load">
                <div
                  aria-hidden
                  className="btn btn_primary courseshow"
                  onClick={this.fetchFormModal}
                >
              Request a call back
                </div>
                <div
                  aria-hidden
                  className="btn btn_primary call"
                  onClick={this.enrollnow}
                >
              Enroll Now
                </div>
              </div>
            )}

        </div>

        {/* BATCH MODAL */}
        {batchModalShow ? (
          <Modal
            show={batchModalShow}
            onHide={this.handleBatchModalClose}
            backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            className="batch-modal"
          >
            <div className="modal-body">
              <div
                aria-hidden
                className="iconsprite closeIcon"
                onClick={this.handleBatchModalClose}
              />
              <div className="sectionTitle2 text-center">{respdata.plan}</div>

              <div className="row">
                <div className="col-sm-12">
                  {
                    !batchLoading ? (
                      <>
                        {
                          respdata.batches.length === 0
                            ? <div className="emiText text-center">Batches Coming Soon.</div>
                            : (
                              <Slider
                                className="batchCarsouel custom-arrows bottom-arrows"
                                infinite
                                slidesToShow={2}
                                slidesToScroll={1}
                                adaptiveHeight
                                focusOnSelect
                                centerMode
                                centerPadding="100px"
                                responsive={[
                                  {
                                    breakpoint: 1024,
                                    settings: {
                                      slidesToShow: 1,
                                      slidesToScroll: 1,
                                      centerMode: false,
                                      centerPadding: '0px',
                                    },
                                  },
                                ]}
                              >
                                {respdata.batches.map((element) => (
                                  <div className="modal-batch-card" key={element.id}>
                                    <div className="batch-title">
                                      Period - {element.period}
                                    </div>
                                    <ul>
                                      <li className="calendar">
                                        <div className="label">Batch Starts on</div>
                                        <p>{element.batchDate}</p>
                                      </li>
                                      <li className="class">
                                        <div className="label">Live Classes</div>
                                        <p>
                                          {element.startDate.split(' ')[3]}&nbsp;
                                          {element.startDate.split(' ')[4]}&nbsp;
                                &nbsp;-&nbsp;
                                          {element.endDate.split(' ')[3]}&nbsp;
                                          {element.endDate.split(' ')[4]}
                                        </p>
                                      </li>
                                      <li className="clock">
                                        <div className="label">Days</div>
                                        <p>{element.sessionDays.toString()}</p>
                                      </li>
                                    </ul>
                                    <a
                                      className="btn btn_primary"
                                      href={element.paymentLink}
                                    >
                                      Enroll Now
                                    </a>
                                  </div>
                                ))}
                              </Slider>
                            )
                        }
                      </>
                    ) : <DefaultLoader />
                  }
                </div>
              </div>
            </div>
          </Modal>
        ) : (
          ''
        )}

        {/* {modalShow ? (
          <Modal
            show={modalShow}
            onHide={this.handleClose}
            className="courseModal"
          >
            <div className="modal-top">
              <div
                aria-hidden
                className="iconsprite closeIcon courseclose"
                onClick={this.handleClose}
              />
              <div className="course-top">
                <div className="label">{subjectData.title}</div>
                <p>{subjectData.description}</p>
              </div>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-sm-8">
                  <div className="mod-tit">About this course</div>
                  <p className="mod-p">{subjectData.about}</p>
                </div>
                <div className="col-sm-4">
                  <div className="grey-tit">Includes</div>
                  <ul className="include-ul">{parse(subjectData.includes)}</ul>
                </div>
              </div>
              <div className="clr20" />

              <div className="row">
                <div className="col-sm-12">
                  <div className="mod-tit">Topics Covered</div>
                  <ul className="topicslist">{parse(subjectData.topics)}</ul>
                </div>
              </div>

              <hr />
              <div className="clr20" />
              <div className="row included">
                <div className="col-sm-12 big-tit">Included In</div>
                <div className="clr20" />

                <div className="placement-flex">{placementPacks}</div>
              </div>
            </div>
          </Modal>
        ) : (
          ''
        )} */}

        {/* Form Modal */}
        {formModalShow ? (
          <Modal
            show={formModalShow}
            onHide={this.handleFormClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            className="form-modal engineerForm-modal"
          >
            <Modal.Body>
              <div
                aria-hidden
                className="iconsprite closeIcon"
                onClick={this.handleFormClose}
              />
              <LeadForm
                registrationPurpose="Corporate Ready"
                gaCategory="Corporate Ready"
                gaLabel="Enquiry"
              />
            </Modal.Body>
          </Modal>
        ) : (
          ''
        )}

        {/* Syllabus Modal */}
        {syllabusModal ? (
          <Modal
            show={syllabusModal}
            onHide={this.handleSyllabusClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            className="topics-modal"
          >
            <Modal.Body>
              <div
                aria-hidden
                className="iconsprite closeIcon"
                onClick={this.handleSyllabusClose}
              />
              <div className="sectionTitle2 text-center">
                {syllabusTitle} Pack
              </div>
              <ul className="topicsUl">
                {syllabusList.map((element) => (
                  <li key={element}>{element}</li>
                ))}
              </ul>
            </Modal.Body>
          </Modal>
        ) : (
          ''
        )}
      </main>
    );
  };
}

export default PlacementMain;
