import React, { Component } from 'react';

class IntegratedPack extends Component {
  render = () => (
    <div className="placement-item platinum-block">
      <div className="packName">Integrated</div>
      <ul className="packUl">
        <li>
          <div className="packIcon calendar" />
          <span>5</span>
          <p>Months</p>
        </li>
      </ul>
      <div className="placement-middle"><span>&#8377;</span> 25,000</div>
    </div>
  )
}

export default IntegratedPack;
