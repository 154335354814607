import React, { Component } from 'react';
import { appurl } from 'globals/utils/constants';
import Footer from 'globals/components/Footer';
import UserInfo from 'models/UserInfo';
import {
  Container, Navbar, Nav,
} from 'react-bootstrap';
import WhyAssessment from './WhyAssessments';
import './scss/AssessmentsMainBanner.scss';
import './scss/AssessmentsMainResponsive.scss';
import AboutAssessments from './AboutAssessments';
import FeaturesEbox from './FeaturesEbox';
import CourseSlider from './CourseSlider';
import AssessmentOther from './AssessmentOther';
import AssessmentTypes from './AssessmentTypes';
import AssessmentContests from './assessmentContests';
import WhyEbox from './WhyEbox';

class AssessmetsHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navBtns: '',
    };
  }

    componentDidMount = () => {
      setTimeout(() => {
        const navBtns = (UserInfo.isAuth() === true) ? (
          <Nav.Link className="btn btn_primary" href={`${appurl}/`}>Dashboard</Nav.Link>
        )
          : (
            <>
              <Nav.Link className="btn" href={appurl}>Login</Nav.Link>
              <Nav.Link className="btn btn_primary" href={`${appurl}/loginSSO/signUp/`}>
                            Register
              </Nav.Link>
            </>
          );

        this.setState({ navBtns });
      }, 500);
    }

    render() {
      const { navBtns } = this.state;
      return (
        <>
          <div style={{ backgroundColor: 'white' }}>
            <div className="main-header">
              <Container>
                <Navbar expand="lg">
                  <Navbar.Brand href="/">
                    <img src="/assets/images/eboxlogo.svg" alt="E-Box" />
                  </Navbar.Brand>
                  <Navbar.Toggle />
                  <Navbar.Collapse>
                    <div className="mobileHeader">
                      <Navbar.Brand href="/">
                        <img src="/assets/images/eboxlogo.svg" alt="E-Box" />
                      </Navbar.Brand>
                      <Navbar.Toggle className="closemenu">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                          <g transform="translate(0.214 0.214)">
                            <path
                              className="b"
                              d="M7.208,101.947l3.812-3.812.786-.786a.3.3,0,0,0,0-.42l-.841-.841a.3.3,0,0,0-.42,0l-4.6,4.6-4.6-4.6a.3.3,0,0,0-.42,0l-.841.841a.3.3,0,0,0,0,.42l4.6,4.6-4.6,4.6a.3.3,0,0,0,0,.42l.841.841a.3.3,0,0,0,.42,0l4.6-4.6,3.812,3.812.786.786a.3.3,0,0,0,.42,0l.841-.841a.3.3,0,0,0,0-.42Z"
                              transform="translate(5.84 -90.15)"
                            />
                          </g>
                        </svg>
                      </Navbar.Toggle>
                    </div>
                    <Nav className="ml-auto">
                      <Nav.Link href="/#program" onClick={this.sideMenuClose}>Why E-Box</Nav.Link>
                      <Nav.Link href="/mentor">Mentors</Nav.Link>
                      <Nav.Link href="/powered-campuses">Universities</Nav.Link>
                      <Nav.Link href="/#skill-course" onClick={this.sideMenuClose}>Courses</Nav.Link>
                      <Nav.Link href="/contact">Contact Us</Nav.Link>
                    </Nav>
                    <Nav>
                      {navBtns}
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </Container>
            </div>
            <div>
              <section className="commonsection bannerSection1">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12 col-md-5 col-lg-5 bannerText">
                      <div className="sectionTitle respSectionTitle bannerTitle">WHY E-BOX ASSESSMENTS ?</div>
                      <br /><br />
                      <div>
                        <WhyAssessment />
                      </div>

                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="clr20" />
            <div style={{ backgroundColor: '#F5F8FF' }}>
              <AboutAssessments />
            </div>
            <div className="clr20" />
            <div>
              <WhyEbox />
            </div>

            <div>
              <FeaturesEbox />
            </div>
            <div>
              <AssessmentTypes />
            </div>
            <div>
              <CourseSlider />
            </div>
            <div>
              <AssessmentContests />
            </div>
            <div style={{ backgroundColor: '#F5F8FF' }}>
              <AssessmentOther />
            </div>
          </div>

          <div>

            <section className="learnsection commonsection padTopReduce">
              <div className="clr20" />
              <div className="clr20" />
              <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="row">
                      <div className="col-sm-5 firstHalf">
                        <span className="contactText">Contact Us for a Detailed Demo of E-Box</span>
                      </div>
                      <div className="col-sm-7">
                        <div className="contactWrapper">
                          <div className="contactUs">
                            <div className="contactName">
                                Deena Devaraj
                            </div>
                            <div className="step-para step-para-clr">
                                87787 51825
                            </div>
                          </div>
                          <div className="contactUs">
                            <div className="contactName">
                                Kayal Vizhi
                            </div>
                            <div className="step-para step-para-clr">
                                93459 52658
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clr20" />
              <div className="clr20" />
            </section>
          </div>

          <Footer />
        </>
      );
    }
}

export default AssessmetsHome;
