import React, { Component } from 'react';

class GetPlacementGoldPack extends Component {
  render = () => (
    <div className="placement-item1 gold-block">
      <div className="packName1">Service Companies</div>
      <ul className="packUl1">
        <li>
          <div className="packIcon arrow" />
          <span>30</span>
          <p>Practice Assessments</p>
        </li>
        <li>
          <div className="packIcon home" />
          <span>6</span>
          <p>Diff. Company Assessments</p>
        </li>
      </ul>
      <div className="placement-middle"><span>&#8377;</span> 1,500</div>
    </div>
  )
}

export default GetPlacementGoldPack;
