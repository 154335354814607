import React, { Component, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

class PlacementRedirection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ppLocationSearch: window.location.search,
    };
  }

  render = () => {
    const { ppLocationSearch } = this.state;
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route path="/placement-preparation">
            <Redirect to={`/corporate-ready${ppLocationSearch}`} />
          </Route>
        </Switch>
      </Suspense>
    );
  }
}

export default PlacementRedirection;
