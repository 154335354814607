import React, { Component } from 'react';

class NeetNationalEligibility extends Component {
  render = () => {
    const { handleEligibilityModal } = this.props;
    return (
      <div className="neetKnowMoreWrapper">
        <div className="leftKnowMore">
          <p className="eligiblityHeadText">National Eligibility Cum Entrance test (UG)</p>
          <p className="eligibleDescText">NATIONAL ELIGIBILITY CUM ENTRANCE TEST (UG) is conducted by National Testing Agency (NTA) for admission to MBBS/BDS Courses and other undergraduate medical courses in approved/recognized Medical/Dental & other Colleges/ Institutes in India.</p>
        </div>
        <button
          type="button"
          className="btn btn_primary neetEnrollBtn"
          onClick={handleEligibilityModal}
        >
          Know More
        </button>
      </div>
    );
  };
}

export default NeetNationalEligibility;
