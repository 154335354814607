import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import Slider from 'react-slick';
import placementTestimonialURL from './succesStories.json';

class PlacementLearnersTestimonial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      testimonialData: '',
    };
  }

  componentDidMount = () => {
    this.fetchTestimonial();
  }

  fetchTestimonial = () => {
    this.setState({
      loading: false, testimonialData: placementTestimonialURL,
    });
  }

  render = () => {
    const { loading, testimonialData } = this.state;

    const settings = {
      center: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      adaptiveHeight: true,
      autoplay: true,
      autoplaySpeed: 10000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            autoplaySpeed: 5000,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplaySpeed: 5000,
          },
        },
      ],
    };

    return (
      <section className="testimonialsection">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 testimonial-block center-arrows custom-arrows">
              {
                (!loading)
                  ? (
                    <Slider {...settings}>
                      {
                        testimonialData.map((element) => (
                          <div key={element.id} className="testimonial-card">
                            <div className="testimonial-top">
                              <div className="owner-cmp">
                                <Image alt={element.Company} src={`/assets/images/placement/success-stories/${element.Company}.webp`} />
                              </div>
                              {
                                (element.ctc)
                                  ? <div className="ctc">{element.ctc}L CTC</div>
                                  : ''
                              }
                            </div>

                            <div className="testimonial-details">
                              <div className="testimonial-image">
                                <Image alt={element.Person} src={`/assets/images/placement/success-stories/${element.Photo}.webp`} />
                              </div>
                              <h3>{element.Person}</h3>
                              <div className="testi-desc">
                                <p>{element.testimonial}</p>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </Slider>
                  )
                  : ''
              }
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default PlacementLearnersTestimonial;
