import React, { Component } from 'react';
import { Nav, Card } from 'react-bootstrap';
import jsondata from './neetsubject.json';

class NeetSubjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'Physics',
    };
  }

  subjectFilterHandle = (eventKey) => {
    this.setState({ activeTab: eventKey });
  };

  render = () => {
    const { activeTab } = this.state;
    return (
      <section className="commonsection">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="sectionTitle text-center">Syllabus</div>
              <div className="neetprogram">
                <div className="row">
                  <div className="center-navs">
                    <Nav
                      variant="pills"
                      defaultActiveKey={activeTab}
                      onSelect={this.subjectFilterHandle}
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="Physics">Physics</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="Chemistry">Chemistry</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="Biology">Biology</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="subjectcard row">
            {jsondata.map((data) => (
              <React.Fragment key="data1">
                {data[activeTab].map((value) => {
                  const valueId = value.id;
                  return (
                    <div className="col-sm-6" key={value.id}>
                      <Card className="cardneet">
                        <Card.Body>
                          <div className="headingcontentcard">
                            {value.class}
                          </div>
                          {value.subject.map((subData, index) => {
                            const indexVal = index;
                            return (
                              <div className="icondisplay" key={valueId + indexVal}>
                                <span className="icon" />
                                <p>{subData}</p>
                              </div>
                            );
                          })}
                        </Card.Body>
                      </Card>
                    </div>
                  );
                })}
              </React.Fragment>
            ))}
          </div>
        </div>
      </section>
    );
  };
}

export default NeetSubjects;
