import React, { Component } from "react";
import Slider from "react-slick";

export default class WhyAssessments extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 1000,
    //   autoplaySpeed: 2000,
      cssEase: "linear"
    };
    return (
      <div>
        <Slider {...settings}>
        <div className="sectionpara">
        <p>
           Its a platform for conducting foolproof assessments and to derive intelligent and insightful metrics from the results.
           </p></div>
          <div className="sectionpara">
            E-Box Assessments is an auto-evaluation platform specifically tailored for the needs of Educational Institutions.
          </div>
          <div className="sectionpara">
            E-Box Assessments is not another run of the mill auto-evaluation platform or a plain Examination Management System.
          </div>
          <div className="sectionpara">
            Its a platform built by a team of ardent folks and has multitude of features that will help you to conduct examinations in a systematic, efficient and effective way.
          </div>
        </Slider>
      </div>
    );
  }
}