import React, { Component } from 'react';
import Slider from 'react-slick';
import { Card } from 'react-bootstrap';

class HighlightsProgramGetPlaced extends Component {
  render = () => {
    const settings = {
      center: true,
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      adaptiveHeight: true,
      autoplay: true,
      autoplaySpeed: 10000,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <>
        {window.innerWidth >= 768 ? (
          <section className="commonsection">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="sectionTitleMedium text-center">
                    Why Technology Enabled Learning through E-Box ?
                  </div>
                </div>
              </div>

              <div className="row cardRow">
                {/* CARD-1 */}
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <div className="highlightCard">
                    <div className="h_icon">
                      <div className="learnSprite highlight22" />
                    </div>
                    <div className="h_title1">
                      Learn the Practice Oriented Way
                    </div>
                    <div className="h_para1">
                      Learn by practicing rather by watching videos. Get your
                      hands on various problems and crack actual placement
                      tests.
                    </div>
                  </div>
                </div>

                {/* CARD-2 */}
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <div className="highlightCard">
                    <div className="h_icon">
                      <div className="learnSprite highlight28" />
                    </div>
                    <div className="h_title1">
                      Problems & Solutions Curated by Experts
                    </div>
                    <div className="h_para1">
                      Get access to 100+ company specific mock tests and become
                      confident and prepared to crack the actual placement
                      drives.
                    </div>
                  </div>
                </div>

                {/* CARD-3 */}
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <div className="highlightCard">
                    <div className="h_icon">
                      <div className="learnSprite highlight29" />
                    </div>
                    <div className="h_title1">
                      Top Product & Service Company Tests
                    </div>
                    <div className="h_para1">
                      Get mentored by teachers from top engineering colleges &
                      universities in and around India
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <>
            <div className="row highlightabcdsection">
              <div className="col-sm-12">
                <div className="sectionTitleMedium text-center">
                  Why Technology Enabled Learning through E-Box ?
                </div>
              </div>
            </div>
            <Slider {...settings} className="sliderabcdhighlights">
              <Card className="highlightCardmobileneethightlights">
                <Card.Body>
                  <div className="highlightCardneethighlights">
                    <div className="h_icon">
                      <div className="learnSprite highlightgetme1" />
                    </div>
                    <div className="h_title">
                      Learn the Practice Oriented Way
                    </div>
                    <div className="h_para">
                      Learn by practicing rather by watching videos. Get your
                      hands on various problems and crack actual placement
                      tests.
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <Card className="highlightCardmobileneethightlights">
                <Card.Body>
                  <div className="highlightCardneethighlights">
                    <div className="h_icon">
                      <div className="learnSprite highlightgetme2" />
                    </div>
                    <div className="h_title">
                      Problems & Solutions Curated by Experts
                    </div>
                    <div className="h_para">
                      Get access to 100+ company specific mock tests and become
                      confident and prepared to crack the actual placement
                      drives.
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <Card className="highlightCardmobileneethightlights">
                <Card.Body>
                  <div className="highlightCardneethighlights">
                    <div className="h_icon">
                      <div className="learnSprite highlightgetme3" />
                    </div>
                    <div className="h_title">
                      Top Product & Service Company Tests
                    </div>
                    <div className="h_para">
                      Get mentored by teachers from top engineering colleges &
                      universities in and around India
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Slider>
          </>
        )}
      </>
    );
  };
}
export default HighlightsProgramGetPlaced;
