import React, { Component } from 'react';
import Slider from 'react-slick';
import { reacturl } from 'globals/utils/constants';
import enrollCompanies from './Enroll.json';

class EnrollCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      companiesData: '',
    };
  }

  componentDidMount = () => {
    this.fetchTestimonial();
  };

  fetchTestimonial = () => {
    this.setState({
      loading: false,
      companiesData: enrollCompanies,
    });
  };

  render = () => {
    const { loading, companiesData } = this.state;
    return (
      <section className="testimonialsection">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 custom-arrows bottom-arrows enroll">
              <div className="sectionTitle text-center">
                Enroll in Company Specific mock Assessments
              </div>
              <div className="sectionpara1 text-center">
                Use our ready to use Company Specific Mock Assessments and
                garner a higher placement this academic year
              </div>
              {!loading ? (
                <Slider
                  center
                  infinite
                  slidesToShow={4}
                  slidesToScroll={2}
                  adaptiveHeight
                  autoplay
                  autoplaySpeed={10000}
                  responsive={[
                    {
                      breakpoint: 1024,
                      settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        autoplaySpeed: 5000,
                      },
                    },
                    {
                      breakpoint: 768,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        autoplaySpeed: 5000,
                      },
                    },
                  ]}
                >
                  {companiesData.map((element) => (
                    <div key={element.id} className="testimonial-card">
                      <div className="testimonial-details container">
                        <h4>{element.Company}</h4>
                        <div className="testi">
                          <div className="packUl">
                            <div className="packIcon edit" />
                          </div>
                          <div className="questions">{element.questions}</div>
                        </div>
                        <div className="testi">
                          <div className="packUl">
                            <div className="packIcon notes" />
                          </div>
                          <div className="questions">{element.knowledge}</div>
                        </div>
                        <div className="testi1">
                          <p>&#8377; {element.amount}</p>
                        </div>
                        <div className="enroll_button text-center">
                          <a
                            href={`${reacturl}/course/${element.courseId}/${element.catalogId}`}
                            aria-hidden="true"
                            className="btn btn_primary enrollbuttongetme"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {element.content}
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </section>
    );
  };
}

export default EnrollCompany;
