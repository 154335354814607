/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import parse from 'html-react-parser';
import Slider from 'react-slick';
import FBpixel from 'globals/utils/FBpixel';
import Footer from 'globals/components/Footer';
import LeadForm from 'globals/components/LeadForm';
import HeaderWithoutMenu from 'globals/components/HeaderWithoutMenu';
import AZCompanyList from 'globals/components/AZCompanyList';
import GlobalService from 'services/GlobalService';
import { resturls } from 'globals/utils/apiurls';
import { Helmet } from 'react-helmet';
import LearnerTestimonial from 'globals/components/LearnerTestimonial';
import GetmeplacedQueriesCall from './GetmeplacedQueriesCall';
import HighlightsProgramGetPlaced from './HighlightsProgramGetPlaced';
import PlacementBanner from './GetPlacementBanner';
import PlacementTraining from './GetPlacementTraining';
import PlacementTrainingCarsouel from './GetPlacementTrainingCarsouel';
import './scss/Getplacement.scss';
import './scss/GetPlacementResponsive.scss';
import PlacementGoldPack from './Packs/GetPlacementGoldPack';
import PlacementDiamondPack from './Packs/GetPlacementDiamondPack';
import PlacementPlatinumPack from './Packs/GetPlacementPlatinumPack';
import placementSubjectsURL from './PlacementSubjects.json';
import PlacementLearnersTestimonial from '../corporate-ready/PlacementLearnersTestimonial';
import EnrollCompany from './EnrollCompany';

class GetPlacementMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subjectData: '',
      respdata: '',
      modalShow: false,
      batchModalShow: false,
      formModalShow: false,
      syllabusModal: false,
      syllabusList: [],
      syllabusTitle: '',
      timeIncrement: 30000,
      delayMultipleCount: 30000,
    };
    this.enablePixel();
  }

  componentDidMount() {
    const { delayMultipleCount } = this.state;
    sessionStorage.modalOpened = false;
    if (sessionStorage.getItem('modalOpened') === 'false') this.showFormModal(delayMultipleCount);
  }

  showFormModal = (delayMultipleCount) => {
    setTimeout(() => {
      if (sessionStorage.getItem('modalOpened') === 'false') {
        this.fetchFormModal();
        this.focusTextBox();
      }
    }, delayMultipleCount);
  }

  focusTextBox = () => {
    const focusName = document.getElementsByName('name')[0];
    focusName.focus();
  };

  enablePixel = () => {
    FBpixel.trackCustom('ViewContent', {
      content_ids: [{ 'Page Name': 'Corporate ready program' }],
    });
  };

  selectBatch = (batchId) => {
    GlobalService.generalSelect(
      (respdata) => {
        this.setState({
          batchModalShow: true,
          formModalShow: false,
          respdata,
        });
      },
      `${resturls.tutoringPlanDetailsUrl}?id=${batchId}`,
      '',
      'GET',
      'app',
    );
  };

  selectSyllabus = (syllabusType) => {
    if (syllabusType === 'gold') {
      this.setState({
        syllabusModal: true,
        syllabusTitle: 'Gold',
        formModalShow: false,
        syllabusList: [
          'Quantitative Aptitude',
          'Problem Solving and Programming',
          'Data Structures and its applications',
          'Design and Analysis of Algorithms',
          'TCS Ninja Practice Course',
        ],
      });
    }

    if (syllabusType === 'diamond') {
      this.setState({
        syllabusModal: true,
        formModalShow: false,
        syllabusTitle: 'Diamond',
        syllabusList: [
          'Quantitative Aptitude',
          'Problem Solving and Programming',
          'Data Structures and its applications',
          'Design and Analysis of Algorithms',
          'TCS Ninja Practice Course',
          'Complex Problem Solving and Algorithmic Thinking',
          'Object Oriented Programming using Java',
          'MySQL Developer Course',
          'InfyTQ Practice Course',
          'TCS Code Vita Practice Course',
        ],
      });
    }

    if (syllabusType === 'platinum') {
      this.setState({
        syllabusModal: true,
        formModalShow: false,
        syllabusTitle: 'Platinum',
        syllabusList: [
          'Quantitative Aptitude',
          'Problem Solving and Programming',
          'Data Structures and its applications',
          'Design and Analysis of Algorithms',
          'TCS Ninja Practice Course',
          'Complex Problem Solving and Algorithmic Thinking',
          'Object Oriented Programming using Java',
          'MySQL Developer Course',
          'InfyTQ Practice Course',
          'TCS Code Vita Practice Course',
          'UI Development',
          'Dynamic Web Application Development',
        ],
      });
    }
  };

  fetchTrainingData = (subjectName) => {
    this.setState({
      subjectData: placementSubjectsURL[subjectName],
      modalShow: true,
    });
    sessionStorage.setItem('modalOpened', true);
  };

  handleClose = () => {
    const { delayMultipleCount, timeIncrement } = this.state;
    this.showFormModal(delayMultipleCount + timeIncrement);
    sessionStorage.setItem('modalOpened', false);
    this.setState({
      modalShow: false,
    });
  }

  handleBatchModalClose = () => {
    this.setState({ batchModalShow: false });
  };

  fetchFormModal = () => {
    this.setState({
      formModalShow: true,
    });
  };

  handleFormClose = () => {
    const { delayMultipleCount, timeIncrement } = this.state;
    this.showFormModal(delayMultipleCount + timeIncrement);
    sessionStorage.setItem('modalOpened', false);
    this.setState({
      formModalShow: false,
      delayMultipleCount: delayMultipleCount + timeIncrement,
    });
  }

  handleSyllabusClose = () => {
    const { delayMultipleCount, timeIncrement } = this.state;
    this.showFormModal(delayMultipleCount + timeIncrement);
    sessionStorage.setItem('modalOpened', false);
    this.setState({
      syllabusModal: false,
    });
  }

  onBrowserTabFocus = () => {
    if (localStorage.getItem('subjectModal') === 'close') {
      const { formModalShow, batchModalShow, syllabusModal } = this.state;
      if (batchModalShow || syllabusModal) this.setState({ formModalShow: false });
      else if (!formModalShow) {
        this.setState({ formModalShow: true });
        this.focusTextBox();
      }
    }
  };

  enrollnow = () => {
    const enrollment = document.querySelector('#enrollPacks');
    window.scrollTo({
      top: enrollment.scrollIntoView(),
      behavior: 'smooth',
    });
  }

  render = () => {
    const {
      subjectData,
      modalShow,
      batchModalShow,
      respdata,
      formModalShow,
      syllabusModal,
      syllabusList,
      syllabusTitle,
    } = this.state;

    let placementPacks = '';

    if (subjectData !== '') {
      if (subjectData.packs === 1) {
        placementPacks = <PlacementPlatinumPack />;
      } else if (subjectData.packs === 2) {
        placementPacks = (
          <>
            <PlacementDiamondPack />
            <PlacementPlatinumPack />
          </>
        );
      } else if (subjectData.packs === 3) {
        placementPacks = (
          <>
            <PlacementGoldPack />
            <PlacementDiamondPack />
            <PlacementPlatinumPack />
          </>
        );
      }
    }

    const settings = {
      className: 'batchCarsouel custom-arrows bottom-arrows',
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      adaptiveHeight: true,
      focusOnSelect: true,
      centerMode: true,
      centerPadding: 100,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            centerPadding: 0,
          },
        },
      ],
    };

    return (
      <main className="getmeplaced">
        <Helmet>
          <title>E-Box | Get Me Placed</title>
          <link rel="canonical" href="https://e-box.co.in/getmePlaced/" />
          <meta name="description" content="Stay ahead of Competition in the Job Hunt. Take up our Company Specific Mock Assessments and bag the right job" />

          <meta name="keywords" content="Get Me Placed Practice" />
          <meta property="og:title" content="E-Box | Get Me Placed Practice | Zoho | TCS | CTS | Amazon" />
          <meta property="og:url" content="https://e-box.co.in/placement-practice/" />

          <meta
            itemProp="image"
            content="https://e-box.co.in/sharelogos/placementsharelogo.png"
          />
          <meta
            property="og:image"
            content="https://e-box.co.in/sharelogos/placementsharelogo.png"
          />
          <meta
            name="twitter:image"
            content="https://e-box.co.in/sharelogos/placementsharelogo.png"
          />
          <meta
            name="twitter:image:src"
            content="https://e-box.co.in/sharelogos/placementsharelogo.png"
          />
          <meta
            name="facebook:image"
            content="https://e-box.co.in/sharelogos/placementsharelogo.png"
          />
          <meta
            name="facebook:image:src"
            content="https://e-box.co.in/sharelogos/placementsharelogo.png"
          />
          <meta
            name="linkedin:image"
            content="https://e-box.co.in/sharelogos/placementsharelogo.png"
          />
          <meta
            name="linkedin:image:src"
            content="https://e-box.co.in/sharelogos/placementsharelogo.png"
          />
          <meta
            name="whatsapp:image"
            content="https://e-box.co.in/sharelogos/placementsharelogo.png"
          />
          <meta
            name="whatsapp:image:src"
            content="https://e-box.co.in/sharelogos/placementsharelogo.png"
          />
          <link
            itemProp="thumbnailUrl"
            href="https://e-box.co.in/sharelogos/placementsharelogo.png"
          />
        </Helmet>

        <HeaderWithoutMenu />
        <PlacementBanner />

        {window.innerWidth >= 991 ? <PlacementLearnersTestimonial /> : ''}

        <div className="highlightsSection bgBody">
          <HighlightsProgramGetPlaced />
        </div>
        <div className="enrollCompany" id="enrollPacks">
          <EnrollCompany />
        </div>

        <div className="placementPacks">
          {window.innerWidth >= 991 ? (
            <PlacementTraining batchSelect={this.selectBatch} />
          ) : (
            <PlacementTrainingCarsouel
              batchSelect={this.selectBatch}
              syllabus={this.selectSyllabus}
            />
          )}
        </div>

        {/* <CorporateTeam /> */}

        <AZCompanyList />
        <LearnerTestimonial />

        {/* <div className="callbackbtn">
          <div
            aria-hidden
            className="btn btn_primary"
            onClick={this.fetchFormModal}
          >
            Request a call back
          </div>
        </div> */}
        <div className="bgWhite">
          <GetmeplacedQueriesCall isEnggTuition fetchFormModal={this.fetchFormModal} />
        </div>

        <Footer />

        {/* <div className="talkbtn">
          <PlacementLearnersTestimonial />
          <div
            aria-hidden="true"
            className="btn btn_primary"
            onClick={this.fetchFormModal}
          >
            Need expert guidance?
          </div>
        </div> */}
        {window.innerWidth >= 991
          ? (
            <div className="callbackbtn button_load">
              <div
                aria-hidden
                className="btn btn_primary  call"
                onClick={this.fetchFormModal}
              >
            Request a call back
              </div>
              <div
                aria-hidden
                className="btn btn_primary courseshow"
                onClick={this.enrollnow}
              >
            View Packs
              </div>
            </div>
          ) : (
            <div className="callbackbtn button_load">
              <div
                aria-hidden
                className="btn btn_primary courseshow"
                onClick={this.fetchFormModal}
              >
            Request a call back
              </div>
              <div
                aria-hidden
                className="btn btn_primary call"
                onClick={this.enrollnow}
              >
            View Packs
              </div>
            </div>
          )}

        {/* BATCH MODAL */}
        {batchModalShow ? (
          <Modal
            show={batchModalShow}
            onHide={this.handleBatchModalClose}
            backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            className="batch-modal"
          >
            <div className="modal-body">
              <div
                aria-hidden
                className="iconsprite closeIcon"
                onClick={this.handleBatchModalClose}
              />
              <div className="sectionTitle2 text-center">{respdata.plan}</div>

              <div className="row">
                <div className="col-sm-12">
                  <Slider {...settings}>
                    {respdata.batches.map((element) => (
                      <div className="modal-batch-card" key={element.id}>
                        <div className="batch-title">
                          {element.batchType} ({element.period})
                        </div>
                        <ul>
                          <li className="calendar">
                            <div className="label">Batch Starts on</div>
                            <p>{element.batchDate}</p>
                          </li>
                          <li className="class">
                            <div className="label">Live Classes</div>
                            <p>
                              {element.startDate.split(' ')[3]}&nbsp;
                              {element.startDate.split(' ')[4]}&nbsp;
                              &nbsp;-&nbsp;
                              {element.endDate.split(' ')[3]}&nbsp;
                              {element.endDate.split(' ')[4]}
                            </p>
                          </li>
                          <li className="clock">
                            <div className="label">Days</div>
                            <p>{element.sessionDays.toString()}</p>
                          </li>
                        </ul>
                        <a
                          className="btn btn_primary"
                          href={element.paymentLink}
                        >
                          Enroll Now
                        </a>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </Modal>
        ) : (
          ''
        )}

        {modalShow ? (
          <Modal
            show={modalShow}
            onHide={this.handleClose}
            className="courseModal"
          >
            <div className="modal-top">
              <div
                aria-hidden
                className="iconsprite closeIcon courseclose"
                onClick={this.handleClose}
              />
              <div className="course-top">
                <div className="label">{subjectData.title}</div>
                <p>{subjectData.description}</p>
              </div>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-sm-8">
                  <div className="mod-tit">About this course</div>
                  <p className="mod-p">{subjectData.about}</p>
                </div>
                <div className="col-sm-4">
                  <div className="grey-tit">Includes</div>
                  <ul className="include-ul">{parse(subjectData.includes)}</ul>
                </div>
              </div>
              <div className="clr20" />

              <div className="row">
                <div className="col-sm-12">
                  <div className="mod-tit">Topics Covered</div>
                  <ul className="topicslist">{parse(subjectData.topics)}</ul>
                </div>
              </div>

              <hr />
              <div className="clr20" />
              <div className="row included">
                <div className="col-sm-12 big-tit">Included In</div>
                <div className="clr20" />

                <div className="placement-flex">{placementPacks}</div>
              </div>
            </div>
          </Modal>
        ) : (
          ''
        )}

        {/* Form Modal */}
        {(formModalShow && !batchModalShow && !syllabusModal) ? (
          <Modal
            show={formModalShow}
            onHide={this.handleFormClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            className="form-modal getmeplaced"
          >
            <Modal.Body>
              <div
                aria-hidden
                className="iconsprite closeIcon"
                onClick={this.handleFormClose}
              />
              <LeadForm
                registrationPurpose="Get Me Placed"
                gaCategory="Get Me Placed"
                gaLabel="Demo Class"
              />
            </Modal.Body>
          </Modal>
        ) : (
          ''
        )}

        {/* Syllabus Modal */}
        {syllabusModal ? (
          <Modal
            show={syllabusModal}
            onHide={this.handleSyllabusClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            className="topics-modal"
          >
            <Modal.Body>
              <div
                aria-hidden
                className="iconsprite closeIcon"
                onClick={this.handleSyllabusClose}
              />
              <div className="sectionTitle2 text-center">
                {syllabusTitle} Pack
              </div>
              <ul className="topicsUl">
                {syllabusList.map((element) => (
                  <li key={element}>{element}</li>
                ))}
              </ul>
            </Modal.Body>
          </Modal>
        ) : (
          ''
        )}
      </main>
    );
  };
}

export default GetPlacementMain;
