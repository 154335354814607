/* eslint-disable max-len */
import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import { contactNumber } from 'globals/utils/constants';

class SchoolBanner extends Component {
  enrollnow = () => {
    const enrollment = document.querySelector('#pgmsection');
    window.scrollTo({
      top: enrollment.scrollIntoView(),
      behavior: 'smooth',
    });
  };

  render = () => (
    <>
      {window.innerWidth >= 991 ? (
        <section className="pgmlistsection commonsection bgWhite">
          <div className="container">
            <div className="row">
              <div className="col-md-12 bannerText col-lg-5">
                <div className="sectionTitle">Test Preparation, Jr. Skills</div>
                <div className="clr20" />
                <p>
                School Coding Program , School Tuitions , AnyBody Can Draw , NEET , Creative Engineering Designers Jr ,
                JEE , Aerial Machine Designer Jr , PolySkill Robotic Designers Jr.
                </p>
                <div className="clr20" />
                <div
                  aria-hidden="true"
                  className="btn btn_primary"
                  onClick={this.enrollnow}
                >
                  Enroll Now
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="pgmlistsection commonsection">
          <div className="container">
            <div className="row">
              <div className="col-md-12 bannerText col-lg-5">
                <Card className="cardEngineering schoolbanner">
                  <Card.Body>
                    <div className="sectionTitlemobile">
                    Test Preparation, Jr. Skills
                    </div>
                    <div className="sectionparamobile">
                    School Coding Program, School Tuitions, AnyBody Can Draw, NEET, Creative Engineering Designers Jr,
                    JEE, Aerial Machine Designer Jr, PolySkill Robotic Designers Jr.
                    </div>
                    <div className="button_load">
                      <a
                        href={`tel:${contactNumber}`}
                        aria-hidden="true"
                        className="btn btn_primary call"
                      >
                        <span className="spiriteicon phonecallicon" />
                        {contactNumber}
                      </a>
                      <div
                        aria-hidden="true"
                        className="btn btn_primary courseshow"
                        onClick={this.enrollnow}
                      >
                          Enroll Now
                      </div>
                    </div>
                  </Card.Body>
                </Card>
                <div className="height50" />
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default SchoolBanner;
