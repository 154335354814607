import React, { Component } from 'react';

class HomeMainBanner extends Component {
  programsScroll = () => {
    const scrollSet = document.getElementById('pgmsection');
    window.scrollTo({
      top: scrollSet.offsetTop,
      behavior: 'smooth',
    });
  }

  render = () => (
    <section className="commonsection bannerSection banner1">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-5 col-lg-5 bannerText">
            <div className="sectionTitle bannerTitle">Power-Up Your Institution With Practice Oriented Learning</div>
            <div className="sectionpara bannerSection">
              The Powerful AI Driven EduTech Ecosystem built through
              20 Years of Research in EduTech and Learning Sciences
            </div>
            {/* <div className="btn-section">
              <a href="https://wapp.e-box.co.in/loginSSO/signUp" className="btn btn_primary_outer">Independant Learners</a>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  )
}
export default HomeMainBanner;
