export const protocol = 'https';
export const reactprotocol = 'https';
export const domain = 'website.e-box.co.in';
export const reactdomain = 'e-box.co.in';
export const port = '8443';
export const reactport = '';
export const reactcontext = '';
export const apicontext = '';
export const restbaseurl = `${protocol}://${domain}:${port}/${apicontext}`;
export const appurl = 'https://wapp.e-box.co.in';
export const payumoneyurl = 'https://secure.payu.in/_payment';
export const reacturl = `${reactprotocol}://${reactdomain}`;
export const uploadsContext = 'https://wapp.e-box.co.in';
export const prourl = 'https://pro.e-box.co.in';
export const probackendurl = 'https://apipro.e-box.co.in';
export const contactNumber = '+91 93609 54237';

// export const protocol = 'http';
// export const reactprotocol = 'http';
// export const domain = '192.168.4.18';
// export const reactdomain = '192.168.3.23';
// export const port = '3000';
// export const reactport = '3000';
// export const uploadsport = '8443';
// export const reactcontext = '';

// export const apicontext = 'scodhvest/api';
// export const restbaseurl = `${protocol}://${domain}:${port}/${apicontext}`;
// export const appurl = 'http://192.168.4.18:8080/ebox';
// export const cdnurl = `${protocol}://${domain}:${port}/${apicontext}`;
// export const payumoneyurl = 'https://secure.payu.in/_payment';
// export const reacturl = `${protocol}://${domain}:${reactport}${reactcontext}`;
// export const uploadsContext = `${protocol}://${domain}:${uploadsport}`;
