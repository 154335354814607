import React, { Component } from 'react';
import './scss/Queries.scss';

class QueriesContact extends Component {
  render = () => (
    <section className="commonsection querysection">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="callFlex">
              <div className="sectionTitle2">Get in Touch with us Now</div>
              <a className="btn btn_primary querycontact" href="/contact">Contact us Now</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default QueriesContact;
