import React, { Component } from 'react';
import {
  Container, Row, Col,
} from 'react-bootstrap';
import Slider from 'react-slick';

class AssessmentOther extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

    render = () => (
      <section className="commonsection learnerTestimonial">
        <Container>
          <Row>
            <Col sm={12} md={6}>
              <div className="sectionTitleMedium learner_txt">
                                E-Box Offerings
              </div>
            </Col>
            <Col sm={12} md={6}>
              <div className="custom-arrows bottom-arrows">
                <Slider
                  className=""
                  infinite
                  slidesToShow={1}
                  slidesToScroll={1}
                  adaptiveHeight
                  autoplay
                  autoplaySpeed={3000}
                >

                  <div className="learnerCard">
                    <div className="learnerDesc">
                      <div className="name-cmp">
                        <p>Technical Symposiums</p>
                      </div>
                      <div className="clr20" />
                      <div className="label">
                          Make a difference in the way technical symposiums are conducted.
                          Conduct hard core technical symposiums.
                      </div>
                    </div>
                  </div>

                  <div className="learnerCard">
                    <div className="learnerDesc">
                      <div className="name-cmp">
                        <p> Student Technical Club / ACM /ISTE / IEEE Chapter Activities</p>
                      </div>
                      <div className="clr20" />
                      <div className="label">
                            Regular student chapter activities can be conducted through E-Box
                      </div>
                    </div>
                  </div>

                  <div className="learnerCard">
                    <div className="learnerDesc">
                      <div className="name-cmp">
                        <p>Skill Analysis Assessments for students</p>
                      </div>
                      <div className="clr20" />
                      <div className="label">
                            Students Skill Assessment can be conducted at the beginning of
                            every semester and appropriate skill enablement programs can be
                            driven through e-box, one of our learning
                            services.
                      </div>
                    </div>
                  </div>

                  <div className="learnerCard">
                    <div className="learnerDesc">
                      <div className="name-cmp">
                        <p>Faculty Recruitment</p>
                      </div>
                      <div className="clr20" />
                      <div className="label">
                          Faculty Recruitment Drives can be conducted through AutoTick.
                          Technical Skills, Verbal Skills, Analysis Skills and Design Skills
                          of faculty can be assessed.
                      </div>
                    </div>
                  </div>

                  <div className="learnerCard">
                    <div className="learnerDesc">
                      <div className="name-cmp">
                        <p>Faculty Assessments</p>
                      </div>
                      <div className="clr20" />
                      <div className="label">
                          Regular assessments for faculty can be conducted to assess
                          their skill level and the skill gap can be bridged by conducting
                           enrichment programs in e-box
                      </div>
                    </div>
                  </div>

                  <div className="learnerCard">
                    <div className="learnerDesc">
                      <div className="name-cmp">
                        <p> Mock Placement Drives</p>
                      </div>
                      <div className="clr20" />
                      <div className="label">
                          Mock Placement Drives can be conducted through Chuz
                          comprising of various round like Aptitude Roung, Logical
                          Reasoning Round, Verbal Ability Round, Technical Round,
                          Group Discussions, Technical Interviews and HR Interviews.
                          Technical Interviews and HR Interviews will be
                          conducted by an Expert Panel. Students will get a
                          first hand experience of a real recruitment drive.
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    )
}

export default AssessmentOther;
