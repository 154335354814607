/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import FBpixel from 'globals/utils/FBpixel';
import Slider from 'react-slick';
import LeadForm from 'globals/components/LeadForm';
import Footer from 'globals/components/Footer';
import HeaderWithoutMenu from 'globals/components/HeaderWithoutMenu';
import UserInfo from 'models/UserInfo';
import { resturls } from 'globals/utils/apiurls';
import { appurl } from 'globals/utils/constants';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import HighlightsProgram from './HighlightsProgram';
import NeetBanner from './NeetBanner';
import NeetSubjects from './NeetSubjects';
import NeetNationalEligibility from './NeetNationalEligibility';
import NeetEligiblityContent from './NeetEligiblityContent';
import './scss/neet.scss';
import './scss/neetResponsive.scss';
import NeetModes from './NeetModes';
import NeetLearn from './NeetLearn';
import NeetPractice from './NeetPractice';
import NeetLearnerTestimonial from './NeetLearnerTestimonial';
import NeetStepByPractice from './NeetStepByPractice';

class NeetMain extends Component {
  constructor(props) {
    super(props);
    this.enablePixel();
    this.state = {
      respdata: '',
      batchModalShow: false,
      formModalShow: false,
      syllabusModal: false,
      syllabusList: [],
      syllabusTitle: '',
      enrollLoginURL: '',
      timeIncrement: 30000,
      delayMultipleCount: 30000,
      showEligibilityModal: false,
    };
    this.enrollDetails = [
      { batchName: 'NEET2021', purchaseCode: 'm7hFGQ' },
      { batchName: 'NEET2022', purchaseCode: 'Ml2phF' },
      { batchName: 'NEET2023', purchaseCode: 'hMA1KD' },
      { batchName: 'NEET2024', purchaseCode: 'JnrRos' },
    ];
  }

  componentDidMount = () => {
    const { delayMultipleCount } = this.state;
    // setTimeout(() => {
    //   let enroll = '';
    //   if (UserInfo.isAuth()) {
    //     batchName = `${appurl}/${resturls.enrollAfterLogin}/m7hFGQ`;
    //   } else {
    //     enroll = `${appurl}/${resturls.enrollBeforeLogin}?purchaseCode=m7hFGQ`;
    //   }
    //   this.setState({ enrollLogin: enroll });
    // }, 500);
    sessionStorage.modalOpened = false;
    if (sessionStorage.getItem('modalOpened') === 'false') this.showFormModal(delayMultipleCount);
  };

  enrollLogin = (batchData) => {
    const purchaseCode = this.enrollDetails.filter((data) => data.batchName === batchData);
    const newPurchaseCode = purchaseCode[0].purchaseCode;
    let enroll = '';
    if (UserInfo.isAuth()) {
      // enroll = `${appurl}/${resturls.enrollAfterLogin}/m7hFGQ`;
      enroll = `${appurl}/${resturls.enrollAfterLogin}/${newPurchaseCode}`;
    } else {
      enroll = `${appurl}/${resturls.enrollBeforeLogin}?purchaseCode=${newPurchaseCode}`;
    }
    this.setState({ enrollLoginURL: enroll });
  }

  showFormModal = (delayMultipleCount) => {
    setTimeout(() => {
      if (sessionStorage.getItem('modalOpened') === 'false') {
        this.fetchFormModal();
        this.focusTextBox();
      }
    }, delayMultipleCount);
  }

  onBrowserTabFocus = () => {
    this.setState({ formModalShow: true });
    this.focusTextBox();
  };

  handleEligibilityModal = () => {
    const { showEligibilityModal } = this.state;
    this.setState({
      showEligibilityModal: !showEligibilityModal,
    });
    // console.log('showEligibilityModal', this.state.showEligibilityModal);
  }

  focusTextBox = () => {
    const focusName = document.getElementsByName('name')[0];
    focusName.focus();
  };

  handleBatchModalClose = () => {
    const { delayMultipleCount, timeIncrement } = this.state;
    this.showFormModal(delayMultipleCount + timeIncrement);
    sessionStorage.setItem('modalOpened', false);
    this.setState({ batchModalShow: false });
  };

  fetchFormModal = () => {
    this.setState({
      formModalShow: true,
    });
  };

  handleFormClose = () => {
    const { delayMultipleCount, timeIncrement } = this.state;
    this.showFormModal(delayMultipleCount + timeIncrement);
    sessionStorage.setItem('modalOpened', false);
    this.setState({
      formModalShow: false,
      delayMultipleCount: delayMultipleCount + timeIncrement,
    });
  }

  handleSyllabusClose = () => {
    const { delayMultipleCount, timeIncrement } = this.state;
    this.showFormModal(delayMultipleCount + timeIncrement);
    sessionStorage.setItem('modalOpened', false);
    this.setState({
      syllabusModal: false,
    });
  }

  enablePixel = () => {
    FBpixel.trackCustom('ViewContent', {
      content_ids: [{ 'Page Name': 'NEET' }],
    });
  };

  showEnrollElement = () => {
    const enrollment = document.querySelector('#neet_enroll');
    window.scrollTo({
      top: enrollment.scrollIntoView(),
      behavior: 'smooth',
    });
  }

  render = () => {
    const {
      batchModalShow,
      respdata,
      formModalShow,
      showEligibilityModal,
      syllabusModal,
      syllabusList,
      syllabusTitle,
      enrollLoginURL,
    } = this.state;

    // let purchasecode = '';
    // let batchCost = 0;

    // let symbol = '';
    // let currencyCode = '';
    // if (batchdata.batches) {
    //   purchasecode = batchdata.batches[0].purchaseCode;
    //   batchCost = batchdata.batches[0].batchCost;
    //   symbol = batchdata.symbol;
    //   currencyCode = batchdata.currencyCode;
    // }

    return (
      <>
        <Helmet>
          <title>E-Box | Neet</title>
          <link rel="canonical" href="https://e-box.co.in/neet/" />
          <meta
            name="description"
            content="The NEET Course provides a comprehensive training by covering all the concepts in Physics, Chemistry, Botany, Zoology and includes the following activities: Video Lectures, Live Doubt Clearing Sessions, Chapterwise Practice Tests, Formative Assessments, Cummulative Assessments, Unit Tests, Half Portion Tests, Full Portion Tests and Mock Assessments."
          />

          <meta name="keywords" content="Neet, MBBS, ebox neet, BDS, Neet" />
          <meta
            property="og:title"
            content="E-Box | Neet"
          />
          <meta property="og:url" content="https://e-box.co.in/neet/" />

          <meta
            itemProp="image"
            content="https://e-box.co.in/sharelogos/neetsharelogo.png"
          />
          <meta
            property="og:image"
            content="https://e-box.co.in/sharelogos/neetsharelogo.png"
          />
          <meta
            name="twitter:image"
            content="https://e-box.co.in/sharelogos/neetsharelogo.png"
          />
          <meta
            name="twitter:image:src"
            content="https://e-box.co.in/sharelogos/neetsharelogo.png"
          />
          <meta
            name="facebook:image"
            content="https://e-box.co.in/sharelogos/neetsharelogo.png"
          />
          <meta
            name="facebook:image:src"
            content="https://e-box.co.in/sharelogos/neetsharelogo.png"
          />
          <meta
            name="linkedin:image"
            content="https://e-box.co.in/sharelogos/neetsharelogo.png"
          />
          <meta
            name="linkedin:image:src"
            content="https://e-box.co.in/sharelogos/neetsharelogo.png"
          />
          <meta
            name="whatsapp:image"
            content="https://e-box.co.in/sharelogos/neetsharelogo.png"
          />
          <meta
            name="whatsapp:image:src"
            content="https://e-box.co.in/sharelogos/neetsharelogo.png"
          />
          <link
            itemProp="thumbnailUrl"
            href="https://e-box.co.in/sharelogos/neetsharelogo.png"
          />
        </Helmet>
        <main className="NeetPage">
          <HeaderWithoutMenu />
          <NeetBanner
            enrollLogin={this.enrollLogin}
            enrollLoginURL={enrollLoginURL}
          />
          <section className="commonsection bgWhite">
            <div className="container">
              <NeetNationalEligibility handleEligibilityModal={this.handleEligibilityModal} />
            </div>
          </section>
          <NeetModes />
          <NeetLearn />
          <NeetPractice />
          <div className="highlightsSection">
            <HighlightsProgram />
          </div>

          <NeetSubjects />

          <div className="bgWhite">
            <NeetLearnerTestimonial />
          </div>

          <NeetStepByPractice />
          {window.innerWidth >= 991
            ? (
              <div className="callbackbtn button_load">
                <div
                  aria-hidden
                  className="btn btn_primary call"
                  onClick={this.fetchFormModal}
                >
                  Request a call back
                </div>
                <a
                  type="button"
                  aria-hidden="true"
                  className="btn btn_primary courseshow"
                  href={enrollLoginURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Enroll Now
                </a>
              </div>
            )
            : (
              <div className="callbackbtn button_load">
                <div
                  aria-hidden
                  className="btn btn_primary courseshow"
                  onClick={this.fetchFormModal}
                >
                  Request a call back
                </div>
                <Link
                  type="button"
                  aria-hidden="true"
                  className="btn btn_primary call"
                  onClick={this.showEnrollElement}
                >
                  Enroll Now
                </Link>
              </div>
            )}

          <Footer />

          {showEligibilityModal && (
            <Modal
              show={showEligibilityModal}
              onHide={this.handleEligibilityModal}
              size="xl"
              className="modalSize"
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Body className="eligibilityModal">
                <NeetEligiblityContent handleEligibilityModal={this.handleEligibilityModal} />
              </Modal.Body>
            </Modal>
          )}

          {/* BATCH MODAL */}
          {batchModalShow ? (
            <Modal
              show={batchModalShow}
              onHide={this.handleBatchModalClose}
              backdrop="static"
              keyboard={false}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              className="batch-modal"
            >
              <div className="modal-body">
                <div
                  aria-hidden
                  className="iconsprite closeIcon"
                  onClick={this.handleBatchModalClose}
                />
                <div className="sectionTitle2 text-center">{respdata.plan}</div>

                <div className="row">
                  <div className="col-sm-12">
                    <Slider
                      className="batchCarsouel custom-arrows bottom-arrows"
                      infinite
                      slidesToShow={2}
                      slidesToScroll={1}
                      adaptiveHeight
                      focusOnSelect
                      centerMode
                      centerPadding={100}
                      responsive={[
                        {
                          breakpoint: 1024,
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            centerMode: false,
                            centerPadding: 0,
                          },
                        },
                      ]}
                    >
                      {respdata.batches.map((element) => (
                        <div className="modal-batch-card" key={element.id}>
                          <div className="batch-title">
                            {element.batchType} ({element.period})
                          </div>
                          <ul>
                            <li className="calendar">
                              <div className="label">Batch Starts on</div>
                              <p>{element.batchDate}</p>
                            </li>
                            <li className="class">
                              <div className="label">Live Classes</div>
                              <p>
                                {element.startDate.split(' ')[3]}&nbsp;
                                {element.startDate.split(' ')[4]}&nbsp;
                              &nbsp;-&nbsp;
                                {element.endDate.split(' ')[3]}&nbsp;
                                {element.endDate.split(' ')[4]}
                              </p>
                            </li>
                            <li className="clock">
                              <div className="label">Days</div>
                              <p>{element.sessionDays.toString()}</p>
                            </li>
                          </ul>
                          <a
                            className="btn btn_primary"
                            href={element.paymentLink}
                          >
                            Enroll Now
                          </a>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </Modal>
          )
            : (
              ''
            )}

          {/* Form Modal */}
          {formModalShow ? (
            <Modal
              show={formModalShow}
              onHide={this.handleFormClose}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              className="form-modal engineerForm-modal"
            >
              <Modal.Body>
                <div
                  aria-hidden
                  className="iconsprite closeIcon"
                  onClick={this.handleFormClose}
                />
                <LeadForm
                  registrationPurpose="Neet preparation"
                  gaCategory="NEET"
                  gaLabel="Demo Class"
                />
              </Modal.Body>
            </Modal>
          )
            : (
              ''
            )}

          {/* Syllabus Modal */}
          {syllabusModal ? (
            <Modal
              show={syllabusModal}
              onHide={this.handleSyllabusClose}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              className="topics-modal"
            >
              <Modal.Body>
                <div
                  aria-hidden
                  className="iconsprite closeIcon"
                  onClick={this.handleSyllabusClose}
                />
                <div className="sectionTitle2 text-center">
                  {syllabusTitle} Pack
                </div>
                <ul className="topicsUl">
                  {syllabusList.map((element) => (
                    <li key={element}>{element}</li>
                  ))}
                </ul>
              </Modal.Body>
            </Modal>
          )
            : (
              ''
            )}
        </main>
      </>
    );
  };
}

export default NeetMain;
