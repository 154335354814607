import React, { Component } from 'react';
import {
  Container, Row, Col, Image,
} from 'react-bootstrap';

class Accolades extends Component {
  render = () => (
    <section className="accoladesSection commonsection bgWhite">
      <Container>
        <Row>
          <Col sm={12}>
            <div className="sectionTitleMedium text-center">E-Box Accolades</div>
            <div className="sectionpara centerPara accoladePara">
              With Over 10+ years of Experience in Leaning &amp; Skilling we have bagged Multiple Awards and have been
              Associated with a large Number of Colleges, Universities, MNC’s and Government Initiatives
            </div>
          </Col>
        </Row>
        <div className="clr20" />

        <Row className="accoladeRow">
          <Col sm={12} md={4}>
            <div className="programCard">
              <div className="programImage">
                <Image alt="Accolade-1" src="/assets/images/accolade/accolade-1.webp" />
              </div>
              <div className="pgmSet">
                <div className="pgmTitle">TN Govt. NEET Exam Training Partner</div>
                <p>
                  We have signed an agreement with the Tamilnadu School Education Board to be the Technology
                  Partner to train the Government School students to succeed in the NEET Examination through
                  E-Box, our flagship product. We have been training around 7500+ NEET aspirants from
                  Government and Government Aided Schools towards NEET Exam 2020.
                </p>
              </div>
            </div>
          </Col>
          <Col sm={12} md={8}>
            <Row>
              <Col sm={12} md={6}>
                <div className="programCard">
                  <a href="https://twitter.com/aicte_india/status/1243892761381621760">
                    <div className="programImage">
                      <Image alt="Accolade-2" src="/assets/images/accolade/accolade-2.webp" />
                    </div>
                    <div className="pgmSet">
                      <div className="pgmTitle">AICTE - NEAT Partner</div>
                      <p>
                        We have been recognised as an EdTech Partner in MHRD-AICTE initiative named as NEAT
                        (National Educational Alliance for Technology). We were selected after multiple rounds
                        of evaluation from around 500+ applicants
                      </p>
                    </div>
                  </a>
                </div>

                <div className="programCard">
                  <div className="programImage">
                    <Image alt="Accolade-4" src="/assets/images/accolade/accolade-4.webp" />
                  </div>
                  <div className="pgmSet">
                    <div className="pgmTitle">Free Maths Bootcamp</div>
                    <p>
                      A free 10 day bootcamp for Govt. and Govt. Aided School Teachers was conducted during
                      June 2020 through E-Box. The Program was launched by Shri. Edappadi K Palanisami,
                      Hon’ble Chief Minister of Tamil Nadu
                    </p>
                  </div>
                </div>
              </Col>

              <Col sm={12} md={6}>
                <div className="programCard">
                  <div className="programImage">
                    <Image alt="Accolade-3" src="/assets/images/accolade/accolade-3.webp" />
                  </div>
                  <div className="pgmSet">
                    <div className="pgmTitle">Free Python Bootcamp</div>
                    <p>
                      A free 10 day bootcamp for Govt. and Govt. Aided School Teachers was conducted during
                      June 2020 through E-Box. The Program was launched by Shri. Edappadi K Palanisami,
                      Hon’ble Chief Minister of Tamil Nadu
                    </p>
                  </div>
                </div>

                <div className="programCard">
                  <div className="programImage">
                    <Image alt="Accolade-5" src="/assets/images/accolade/accolade-5.webp" />
                  </div>
                  <div className="pgmSet">
                    <div className="pgmTitle">Brandon Hall Awards</div>
                    <p>
                      We have Bagged many Internationally Acclaimed Brandon Hall Awards for Learning &amp;
                      Skilling for our clients every Year
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Accolades;
