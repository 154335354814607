import React, { Component } from 'react';
import { contactNumber } from 'globals/utils/constants';
import PlacementGoldPack from './Packs/GetPlacementGoldPack';
import PlacementDiamondPack from './Packs/GetPlacementDiamondPack';
import PlacementPlatinumPack from './Packs/GetPlacementPlatinumPack';

class GetPlacementTraining extends Component {
  selectBatch = (batchId) => {
    const { batchSelect } = this.props;
    batchSelect(batchId);
  }

  render = () => (
    <section className="commonsection placementsection">
      <div className="container">
        <div id="courseOffered" className="row">
          <div className="col-sm-12">
            <div className="sectionTitleMedium text-center">Bundled Practice Assessments</div>
            <div className="clr" />

            <div className="placements-card">
              <div className="placement-head">
                <div className="placement-row">
                  <div className="placement-th">
                    <div className="placementTextBlock">
                      <div className="placementText">Not Sure, Which plan to choose ?</div>
                      <a href={`tel:${contactNumber}`} className="btn btn_primary modalbutton"><span>Call us on {contactNumber}</span></a>
                    </div>

                  </div>
                  <div className="placement-th">
                    <PlacementGoldPack />
                    <div className="btn-pack">
                      <a aria-hidden href="tel:91 90034 69198" className="btn btn_primary_outer contactbutton"><span>CONTACT US</span></a>
                    </div>
                  </div>
                  <div className="placement-th">
                    <PlacementDiamondPack />
                    <div className="btn-pack">
                      <a aria-hidden href="tel:91 90034 69198" className="btn btn_primary_outer contactbutton"><span>CONTACT US</span></a>
                    </div>
                  </div>
                  <div className="placement-th">
                    <PlacementPlatinumPack />
                    <div className="btn-pack">
                      <a aria-hidden href="tel:91 90034 69198" className="btn btn_primary_outer contactbutton"><span>CONTACT US</span></a>
                    </div>
                  </div>
                </div>
              </div>

              <div className="placement-body panel-group">
                <div className="courseOffer">ASSESSMENtS OFFERED</div>
                <div className="placement-row">
                  <div className="placement-td">
                    <span>Accenture Practice Assessment</span>
                  </div>
                  <div className="placement-td">
                    <div className="tickRound" />
                  </div>
                  <div className="placement-td diamond-column">
                    {/* <div className="tickRound" /> */}
                  </div>
                  <div className="placement-td">
                    <div className="tickRound" />
                  </div>
                </div>
                <div className="placement-row">
                  <div className="placement-td">
                    <span>HCL practice assessment</span>
                  </div>
                  <div className="placement-td ">
                    <div className="tickRound" />
                  </div>
                  <div className="placement-td diamond-column">
                    {/* <div className="tickRound" /> */}
                  </div>
                  <div className="placement-td">
                    <div className="tickRound" />
                  </div>
                </div>
                <div className="placement-row">
                  <div className="placement-td">
                    <span>Infosys Practice Assessment</span>
                  </div>
                  <div className="placement-td">
                    <div className="tickRound" />
                  </div>
                  <div className="placement-td diamond-column">
                    {/* <div className="tickRound" /> */}
                  </div>
                  <div className="placement-td">
                    <div className="tickRound" />
                  </div>
                </div>
                <div className="placement-row">
                  <div className="placement-td">
                    <span>TCS Ninja Practice Course</span>
                  </div>
                  <div className="placement-td">
                    <div className="tickRound" />
                  </div>
                  <div className="placement-td diamond-column">
                    {/* <div className="tickRound" /> */}
                  </div>
                  <div className="placement-td">
                    <div className="tickRound" />
                  </div>
                </div>
                <div className="placement-row">
                  <div className="placement-td">
                    <span>CTS Practise Assessment</span>
                  </div>
                  <div className="placement-td">
                    <div className="tickRound" />
                  </div>
                  <div className="placement-td diamond-column">
                    {/* <div className="tickRound" /> */}
                  </div>
                  <div className="placement-td">
                    <div className="tickRound" />
                  </div>
                </div>
                <div className="placement-row">
                  <div className="placement-td">
                    <span>L&T Practice Assessment</span>
                  </div>
                  <div className="placement-td">
                    <div className="tickRound" />
                  </div>
                  <div className="placement-td diamond-column">
                    {/* <div className="tickRound" /> */}
                  </div>
                  <div className="placement-td">
                    <div className="tickRound" />
                  </div>
                </div>
                <div className="placement-row">
                  <div className="placement-td">
                    <span>Thoughtworks Practice Assessment</span>
                  </div>
                  <div className="placement-td" />
                  <div className="placement-td diamond-column">
                    <div className="tickRound" />
                  </div>
                  <div className="placement-td">
                    <div className="tickRound" />
                  </div>
                </div>
                <div className="placement-row">
                  <div className="placement-td">
                    <span>Google practice Assessment</span>
                  </div>
                  <div className="placement-td" />
                  <div className="placement-td diamond-column">
                    <div className="tickRound" />
                  </div>
                  <div className="placement-td">
                    <div className="tickRound" />
                  </div>
                </div>
                <div className="placement-row">
                  <div className="placement-td">
                    <span>Zoho Mock Assessment</span>
                  </div>
                  <div className="placement-td" />
                  <div className="placement-td diamond-column">
                    <div className="tickRound" />
                  </div>
                  <div className="placement-td">
                    <div className="tickRound" />
                  </div>
                </div>
                <div className="placement-row">
                  <div className="placement-td">
                    <span>Payoda Mock Assessment</span>
                  </div>
                  <div className="placement-td">
                    {/* <div className="tickRound" /> */}
                  </div>
                  <div className="placement-td diamond-column">
                    <div className="tickRound" />
                  </div>
                  <div className="placement-td">
                    <div className="tickRound" />
                  </div>
                </div>
                {/* <div className="placement-row">
                  <div className="placement-td">
                    <span>InfyTQ Practice Assessment</span>
                  </div>
                  <div className="placement-td">
                    <div className="tickRound" />
                  </div>
                  <div className="placement-td diamond-column">
                    <div className="tickRound" />
                  </div>
                  <div className="placement-td">
                    <div className="tickRound" />
                  </div>
                </div> */}
                {/* <div className="placement-row">
                  <div className="placement-td">
                    <span>Uber Practise Assessment</span>
                  </div>
                  <div className="placement-td" />
                  <div className="placement-td diamond-column" />
                  <div className="placement-td">
                    <div className="tickRound" />
                  </div>
                </div> */}
                <div className="placement-row">
                  <div className="placement-td">
                    <span>Amazon practice Assessment</span>
                  </div>
                  <div className="placement-td" />
                  <div className="placement-td diamond-column">
                    <div className="tickRound" />
                  </div>
                  <div className="placement-td">
                    <div className="tickRound" />
                  </div>
                </div>
                <div className="placement-row">
                  <div className="placement-td">
                    <span>Mu Sigma Mock Assessment</span>
                  </div>
                  <div className="placement-td" />
                  <div className="placement-td diamond-column">
                    <div className="tickRound" />
                  </div>
                  <div className="placement-td">
                    <div className="tickRound" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clr" />

        </div>
      </div>
    </section>
  )
}

export default GetPlacementTraining;
