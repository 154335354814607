import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
// import PlacementForm from './PlacementForm';

class AbcdBanner extends Component {
  enrollnow = () => {
    const enrollment = document.querySelector('#enrollPacks');
    window.scrollTo({
      top: enrollment.scrollIntoView(),
      behavior: 'smooth',
    });
  };

  render = () => (
    <>
      {window.innerWidth >= 991 ? (
        <section className="pgmlistsection commonsection bgWhite">
          <div className="container">
            <div className="row">
              <div className="col-md-12 bannerText col-lg-5">
                <div className="sectionTitle">ABCD (AnyBody Can Draw)</div>
                <div className="clr20" />
                <p>
                  Is your Child a Budding Picasso? Enroll your child in E-Box’s
                  exclusive ABCD program and lay the foundation for your Child’s
                  Colorful Future
                </p>
                <div className="clr20" />
                <div
                  aria-hidden="true"
                  className="btn btn_primary"
                  onClick={this.enrollnow}
                >
                  Enroll Now
                </div>
              </div>
              {/* <div className="col-md-12 col-lg-7" id="placement-form">
            {
              (window.innerWidth >= 991) ? <PlacementForm /> : ''
            }
          </div> */}
            </div>
          </div>
        </section>
      ) : (
        <section className="pgmlistsection commonsection">
          <div className="container">
            <div className="row">
              <div className="col-md-12 bannerText col-lg-5">
                <Card className="cardEngineering abcdbanner">
                  <Card.Body>
                    <div className="sectionTitlemobile">
                    ABCD (AnyBody Can Draw)
                    </div>
                    <div className="sectionparamobile">
                    Is your Child a Budding Picasso? Enroll your child in E-Box’s
                  exclusive ABCD program and lay the foundation for your Child’s
                  Colorful Future
                    </div>
                    <div className="button_load">
                      <a
                        href="tel:91 90427 40463"
                        aria-hidden="true"
                        className="btn btn_primary call"
                      >
                        <span className="spiriteicon phonecallicon" />
                        9042740463
                      </a>
                      <div
                        aria-hidden="true"
                        className="btn btn_primary courseshow"
                        onClick={this.enrollnow}
                      >
                          Enroll Now
                      </div>
                    </div>
                  </Card.Body>
                </Card>
                <div className="height50" />
              </div>
              <div className="col-md-12 col-lg-7" id="requestForm">
                {/* <EngineeringForm /> */}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export default AbcdBanner;
