import React, { Component } from 'react';
import { resturls } from 'globals/utils/apiurls';
import GlobalService from 'services/GlobalService';
import { Row, Col } from 'react-bootstrap';
import {
  Formik, Field, Form, ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import ReactGA from 'react-ga';

class MentorsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalAlert: {
        show: false,
        msg: '',
        classType: '',
        disabledBtn: false,
      },
    };
  }

  formSubmit = (fields, formkIns) => {
    const urlParams = new URLSearchParams(window.location.search);
    const formData = {
      name: fields.name,
      email: fields.email,
      mobilenumber: fields.mobilenumber,
      usertype: fields.usertype,
      registrationPurpose: 'Niche IT Skill Training Programs',
      track: 'Niche IT Skill Training Programs Blog',
      utm_source: urlParams.has('utm_source')
        ? urlParams.get('utm_source')
        : '',
      utm_campaign: urlParams.has('utm_campaign')
        ? urlParams.get('utm_campaign')
        : '',
    };

    if (Object.keys(formData).length !== 0) {
      this.setState({ disabledBtn: true });
      GlobalService.generalSelect(
        (respdata) => {
          ReactGA.event({
            category: 'Niche IT Skill Training Programs',
            action: 'Submit',
            label: 'Enquiry',
          });
          if (respdata) {
            formkIns.resetForm();
            this.setState({
              disabledBtn: false,
              modalAlert: {
                show: true,
                msg: 'Thank you for your interest...! We will reach you soon.',
                classType: 'alert-success',
              },
            });
          } else {
            this.setState({
              disabledBtn: false,
              modalAlert: {
                show: true,
                msg: 'Oops..! Something went wrong. Please try again.',
                classType: 'alert-danger',
              },
            });
          }
          setTimeout(() => {
            this.setState({
              disabledBtn: false,
              modalAlert: {
                show: false,
                msg: '',
                classType: '',
              },
            });
          }, 4000);
        },
        `${resturls.registerWebsiteUser}`,
        formData,
        'POST',
        'app',
      );
    }
  };

  handleClose = () => {
    this.setState({
      modalAlert: {
        show: false,
        msg: '',
        classType: '',
      },
    });
    const { closeModal } = this.props;
    closeModal();
  };

  render = () => {
    const { modalAlert, disabledBtn } = this.state;
    const { show, msg, classType } = modalAlert;

    return (
      <>
        <div className="request-formData">
          <div className="formtexts">
            <div className="formTitle">Interact with our Expert Mentors</div>
            <div className="formPara">Get personalised advice and responses to your questions</div>
            {show ? (
              <div className="alert-block">
                <div id="main-alert" className={`alert ${classType}`}>
                  {msg}
                </div>
              </div>
            ) : (
              ''
            )}

            <Formik
              initialValues={{
                name: '',
                email: '',
                mobilenumber: '',
                usertype: '',
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string().required('Name is required'),
                email: Yup.string()
                  .email('Email is invalid')
                  .required('Email is required'),
                mobilenumber: Yup.number()
                  .typeError('Invalid Mobile Number')
                  .required('Mobilenumber is required')
                  .test('mobileValidate', 'Invalid Mobile Number', (value) => {
                    if (value && value.toString().length === 10) {
                      return true;
                    }
                    return false;
                  }),
                usertype: Yup.string().required('Please select any one'),
              })}
              onSubmit={this.formSubmit}
              render={({ errors, touched }) => (
                <Form>
                  <Row>
                    <Col sm={12}>
                      <div className="form-group">
                        <div className="label">Name *</div>
                        <Field
                          name="name"
                          type="text"
                          className={`form-control${errors.name && touched.name ? ' is-invalid' : ''
                          }`}
                          placeholder="Name"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </Col>
                    <Col sm={12}>
                      <div className="form-group">
                        <div className="label">Email *</div>
                        <Field
                          name="email"
                          type="text"
                          className={`form-control${errors.email && touched.email ? ' is-invalid' : ''
                          }`}
                          placeholder="Email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </Col>
                    <Col sm={12}>
                      <div className="form-group">
                        <div className="label">Mobile *</div>
                        <Field
                          name="mobilenumber"
                          type="text"
                          className={`form-control${errors.mobilenumber && touched.mobilenumber
                            ? ' is-invalid'
                            : ''
                          }`}
                          placeholder="Mobile"
                        />
                        <ErrorMessage
                          name="mobilenumber"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </Col>
                    <Col sm={12}>
                      <div className="form-group">
                        <div className="label">I am a *</div>
                        <Field
                          as="select"
                          name="usertype"
                          className={`form-control${errors.usertype && touched.usertype
                            ? ' is-invalid'
                            : ''
                          }`}
                        >
                          <option value="">I am a</option>
                          <option value="college_student">
                            College Student
                          </option>
                          <option value="job_seeker">Job Seeker</option>
                          <option value="corporate_employee">
                            Corporate Employee
                          </option>
                        </Field>
                        <ErrorMessage
                          name="usertype"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </Col>
                    <Col sm={12}>
                      <div className="modalFooter">
                        <div className="form-group text-center">
                          <button
                            type="submit"
                            className={`btn btn_primary ${disabledBtn ? 'btn_disabled' : ''
                            }`}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            />
          </div>
        </div>
      </>
    );
  };
}
export default MentorsModal;
