import React, { Component } from 'react';
import './scss/HomeMainBanner.scss';
import './scss/HomeMainResponsive.scss';
import Header from 'globals/components/Header';
import FBpixel from 'globals/utils/FBpixel';
import Footer from 'globals/components/Footer';
import WhyLearnEbox from 'globals/components/WhyLearnEbox';
import AZCompanyList from 'globals/components/AZCompanyList';
import LearnerTestimonial from 'globals/components/LearnerTestimonial';
import LimeLight from 'globals/components/LimeLight';
import { Helmet } from 'react-helmet';
import RoleScreen from 'screens/role/RoleScreen';
import AmphiSignatureBlend from 'screens/SignatureBlend/AmphiSignatureBlend';
import BlogSliderScreen from 'screens/blogs-casestudies/BlogSliderScreen';
import HomeMainBanner from './HomeMainBanner';
import ProgramsOffered from './ProgramsOffered';
import SkillCourses from './SkillCourses';
import NumberSection from './NumberSection';
import Accolades from './Accolades';

class HomePage extends Component {
  componentDidMount = () => {
    FBpixel.trackCustom('ViewContent', {
      content_ids: [{ 'Page Name': 'Home Page' }],
    });
  }

  sideMenuClose = () => {
    const menuClass = document.querySelector('.closemenu');
    menuClass.click();
  }

  render = () => {
    const { match, history } = this.props;
    return (
      <>
        <Helmet>
          <title>
            E-Box | Master your Skills with Practice Oriented Learning |
            Online Bootcamps | Online Class
          </title>
          <link rel="canonical" href="https://e-box.co.in/" />
          <meta name="description" content="E-Box platform is built with a proprietary LMS base which makes both assessment and learning management integrated. E-Box platform has numerous activity components pertaining to technology and engineering concepts that could be used for design and analysis oriented learning." />

          <meta name="keywords" content="online courses, online learning, theme based learning, game based learning, movie based learning, code challenges, Hackathons, Learnathons, technology enabled learning, Data driven learning, practice oriented learning, social learning, skill based courses, role based courses, learning platform, assessment platform,  industry readiness programs, on-the-job trainings, employee re-skilling, employee Up-skilling, recruitment services, finishing school, academy services,  e-box school" />

          <meta property="og:title" content="E-Box | Master your Skills with Practice Oriented Learning | Online Bootcamps | Online Class" />
          <meta property="og:url" content="https://e-box.co.in/" />

          <meta itemProp="image" content="https://e-box.co.in/eboxsharelogo.png" />
          <meta property="og:image" content="https://e-box.co.in/eboxsharelogo.png" />
          <meta name="twitter:image" content="https://e-box.co.in/eboxsharelogo.png" />
          <meta name="twitter:image:src" content="https://e-box.co.in/eboxsharelogo.png" />
          <meta name="facebook:image" content="https://e-box.co.in/eboxsharelogo.png" />
          <meta name="facebook:image:src" content="https://e-box.co.in/eboxsharelogo.png" />
          <meta name="linkedin:image" content="https://e-box.co.in/eboxsharelogo.png" />
          <meta name="linkedin:image:src" content="https://e-box.co.in/eboxsharelogo.png" />
          <meta name="whatsapp:image" content="https://e-box.co.in/eboxsharelogo.png" />
          <meta name="whatsapp:image:src" content="https://e-box.co.in/eboxsharelogo.png" />
          <link itemProp="thumbnailUrl" href="https://e-box.co.in/eboxsharelogo.png" />
        </Helmet>

        <Header />
        <HomeMainBanner />
        {/* <div className="buyCourseBtn">
          <span className="sectionTitleMedium text-center">Direct Learners</span>
          <a className="btn btn_primary" href="/#trainerDrivenPrograms">View Courses</a>
        </div> */}
        <div>
          <AmphiSignatureBlend />
        </div>
        <div id="program" className="commonsection">
          <ProgramsOffered />
        </div>
        <div className="blogsCasestudies bgWhite">
          <BlogSliderScreen />
        </div>
        <div id="trainerDrivenPrograms" />
        <div id="selfPacedPrograms" className="skillCourse bgWhite">
          <RoleScreen
            match={match}
            history={history}
            activeCatelogue={window.location.hash === '' ? '#trainerDrivenPrograms' : window.location.hash}
          />
          <SkillCourses />
        </div>
        <div>
          <NumberSection />
        </div>
        <div className="bgWhite">
          <WhyLearnEbox />
        </div>
        <AZCompanyList />
        <LearnerTestimonial />
        <Accolades />
        <LimeLight />
        <Footer />
      </>
    );
  }
}
export default HomePage;
