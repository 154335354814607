import React, { useEffect, useState } from 'react';
import {
  Image, Nav,
} from 'react-bootstrap';
import Slider from 'react-slick';
import prevArrow from 'assets/images/previousArrow.webp';
import NextArrow from 'assets/images/nextArrow.webp';
import arrowIcon from 'assets/images/roundArrow.webp';
import { useHistory } from 'react-router-dom';
import GlobalService from 'services/GlobalService';
import { resturls } from 'globals/utils/apiurls';
import styles from './scss/BlogsCasestudy.module.scss';
import { probackendurl } from 'globals/utils/constants';

const BlogSliderScreen = (props) => {
  const { blogRef } = props;
  const [activeTab, setActive] = useState('Blogs');
  const [blogs, setBlogs] = useState([]);
  const navList = ['Blogs', 'E-Box Casestudies'];
  const history = useHistory();

  const fetchBlogData = () => {
    GlobalService.generalSelect((respData) => {
      const { data, estatus, emessage } = respData;
      if (estatus === true && emessage === 'success') {
        setBlogs(data);
      }
    },`${probackendurl}/${resturls.obtainBlogList}`, '', 'GET');
  };

  useEffect(() => {
    fetchBlogData();
  }, []);

  const handleclick = (item) => {
    setActive(item);
  };
  const NextArrowBlog = ({ onClick }) => (
    <div className={styles.nextArrowWrap} onClickCapture={onClick}>
      <img src={NextArrow} alt="Next" className={styles.arrowImg} />
    </div>
  );

  const PrevArrowBlog = ({ onClick }) => (
    <div className={styles.prevArrowWrap} onClickCapture={onClick}>
      <img src={prevArrow} alt="Previous" className={styles.arrowImg} />
    </div>
  );

  const handleNavigate = (item) => {
    history.push(`blogs/${item.id}`);
    window.scrollTo(0, 0);
  };
  return (
    <section ref={blogRef} className={styles.blogSegment}>
      <div className={styles.catalogueWrapper}>
        {
          navList.map((ele) => (
            <>
              <Nav.Link onClick={() => handleclick(ele)}>
                <div
                  id={ele === 'Blogs' ? '#Blogs' : '#casestudies'}
                  className={activeTab === ele ? styles.activeCatlog : styles.catalogueBtn}
                >
                  {ele}
                </div>
              </Nav.Link>
            </>
          ))
        }
      </div>
      <div className={styles.cardWrapper}>
        <Slider
          infinite
          slidesToShow={4}
          slidesToScroll={1}
          adaptiveHeight
          autoplay={false}
          autoplaySpeed={5000}
          speed={500}
          cssEase="ease"
          arrows
          nextArrow={<NextArrowBlog />}
          prevArrow={<PrevArrowBlog />}
        >
          {
            blogs?.map((item, index) => (
              <>
                <div
                  className={`${styles.blogsCard} ${index === 0 ? styles.activeblogsCard : ''}`}
                  onClickCapture={() => handleNavigate(item)}
                >
                  <div className={styles.headerText}>{item.name}</div>
                  <div className={styles.blogImageWrap}>
                    <Image className={styles.blogImage} src={item.bannerImage} alt="banner-image" />
                  </div>
                  <div className={styles.arrowIconwrap}>
                    {index === 0 ? (
                      <div className={styles.latestBlog}>Latest Blog</div>) : <div />}
                    <Image
                      src={arrowIcon}
                      className={styles.arrowIcon}
                    />
                  </div>
                </div>
              </>
            ))
          }
        </Slider>
      </div>

      <div className={styles.cardWrappermobile}>
        <Slider
          className=""
          infinite
          slidesToShow={3}
          slidesToScroll={1}
          adaptiveHeight
          autoplay={false}
          autoplaySpeed={5000}
          cssEase="ease"
          speed={500}
          arrows
          nextArrow={<NextArrowBlog />}
          prevArrow={<PrevArrowBlog />}
        >
          {
            blogs?.map((item, index) => (
              <>
                <div
                  className={`${styles.blogsCard} ${index === 0 ? styles.activeblogsCard : ''}`}
                  onClickCapture={() => handleNavigate(item)}
                >
                  <div className={styles.headerText}>{item.name}</div>
                  <div className={styles.blogImageWrap}>
                    <Image className={styles.blogImage} src={item.bannerImage} alt="banner-image" />
                  </div>
                  <div className={styles.arrowIconwrap}>
                    {index === 0 ? (
                      <div className={styles.latestBlog}>Latest Blog</div>) : <div />}
                    <Image
                      src={arrowIcon}
                      className={styles.arrowIcon}
                    />
                  </div>
                </div>
              </>
            ))
          }
        </Slider>
      </div>
      <div className={styles.exploreMoreBtn}><a href="/blogs" className="btn btn_primary">Explore More</a></div>
    </section>
  );
};

export default BlogSliderScreen;
