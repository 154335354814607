import React, { Component } from 'react';
import { Card, Image } from 'react-bootstrap';

class NeetPractice extends Component {
  render = () => (
    <>
      <section className="commonsection">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="sectionTitleMedium text-center">
                How will you practice through E-Box
              </div>
            </div>
          </div>
          <div className="row cardRowlearn">
            {/* CARD-1 */}
            <div className="rowLearncontent">
              <Card className="neetlearnCard">
                <Card.Body>
                  <div className="highlightCardlearn">
                    <div className="h_icon">
                      <Image src="/assets/images/neet/icons/icon7.webp" />
                    </div>
                    <div className="h_title">Multiple Choice Questions</div>
                  </div>
                </Card.Body>
              </Card>
            </div>

            {/* CARD-2 */}
            <div className="rowLearncontent">
              <Card className="neetlearnCard">
                <Card.Body>
                  <div className="highlightCardlearn">
                    <div className="h_icon">
                      <Image src="/assets/images/neet/icons/icon8.webp" />
                    </div>
                    <div className="h_title">Multiple Answer Questions</div>
                  </div>
                </Card.Body>
              </Card>
            </div>

            {/* CARD-3 */}
            <div className="rowLearncontent">
              <Card className="neetlearnCard">
                <Card.Body>
                  <div className="highlightCardlearn">
                    <div className="h_icon">
                      <Image src="/assets/images/neet/icons/icon9.webp" />
                    </div>
                    <div className="h_title">Comprehensions</div>
                  </div>
                </Card.Body>
              </Card>
            </div>

            {/* CARD-4 */}
            <div className="rowLearncontent">
              <Card className="neetlearnCard">
                <Card.Body>
                  <div className="highlightCardlearn">
                    <div className="h_icon">
                      <Image src="/assets/images/neet/icons/icon10.webp" />
                    </div>
                    <div className="h_title">Fill ups</div>
                  </div>
                </Card.Body>
              </Card>
            </div>

            {/* CARD-5 */}
            <div className="rowLearncontent">
              <Card className="neetlearnCard">
                <Card.Body>
                  <div className="highlightCardlearn">
                    <div className="h_icon">
                      <Image src="/assets/images/neet/icons/icon11.webp" />
                    </div>
                    <div className="h_title">Match the following</div>
                  </div>
                </Card.Body>
              </Card>
            </div>

            {/* CARD-6 */}
            <div className="rowLearncontent">
              <Card className="neetlearnCard">
                <Card.Body>
                  <div className="highlightCardlearn">
                    <div className="h_icon">
                      <Image src="/assets/images/neet/icons/icon12.webp" />
                    </div>
                    <div className="h_title">Short/Long Answer</div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default NeetPractice;
