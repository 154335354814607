import React, { Component } from 'react';
import './scss/CommonComponent.scss';
import {
  Container, Row, Col, Image,
} from 'react-bootstrap';
import Slider from 'react-slick';
import placementTestimonialURL from './LearnerTestimonial.json';
import DefaultLoader from './loader/DefaultLoader';

class LearnerTestimonial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      testimonialData: '',
    };
  }

  componentDidMount = () => {
    this.fetchTestimonial();
  }

  fetchTestimonial = () => {
    this.setState({
      loading: false, testimonialData: placementTestimonialURL,
    });
  }

  render = () => {
    const { loading, testimonialData } = this.state;
    const { isEnggTuition } = this.props;
    const settings = {
      className: '',
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      autoplay: true,
      autoplaySpeed: 5000,
    };
    return (
      <section className="commonsection learnerTestimonial">
        <Container>
          <Row>
            <Col sm={12} md={6}>
              <div className="sectionTitleMedium learner_txt">
                {isEnggTuition ? 'What Learners Say' : 'What do our Tutors and Learners Say about E-Box ?'}
              </div>
            </Col>
            <Col sm={12} md={6}>
              <div className="custom-arrows bottom-arrows">
                <Slider {...settings}>
                  {
                    (!loading)
                      ? testimonialData.map((element) => (
                        <div className="learnerCard" key={element.Person}>
                          <div className="learnerDesc">
                            <div className="label">
                              {element.testimonial}
                            </div>
                            <div className="name-cmp">
                              <p>{element.Person}</p>
                              {
                                (element.Company !== '')
                                && (
                                  <div className="cmp">
                                    <Image src={`/assets/images/placement/success-stories/${element.Company}.webp`} alt={element.Person} />
                                  </div>
                                )
                              }
                            </div>
                          </div>
                          <div className="learnerImage">
                            <Image src={`/assets/images/placement/success-stories/${element.Photo}.webp`} alt={element.Person} />
                          </div>
                        </div>
                      ))
                      : <DefaultLoader />
                  }
                </Slider>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default LearnerTestimonial;
